<template>
  <div class="pl-5 is-flex is-flex-direction-column is-align-items-center" style="height: 90vh; width:100%;">
    <div class="pl-3 pt-3 pr-3 is-flex is-flex-direction-column is-align-items-center" style="height: 90vh; width:100%;">
      <div style="width:80%;">
        <input type="text">
        <div class="mb-5 is-flex is-flex-direction-row" style="width:100%;">
          <h1 class="title is-4 mr-4">Control Files</h1>
          <div class="select is-one-fifth" style="width: 20%;">
            <v-select
              label="Select Week"
              v-model="chosenWeek"
              :items="weeksWithData"
            ></v-select>
          </div>
          
        </div>
        <v-card class="table-container " >
          <v-data-table
            id="table-main"
            :headers="headers"
            :items="filesList"
            fixed-header
            sortable: false
            items-per-page="50"
            height="60vh"
            
          >
            <template  #item="{ item }">
              <tr  >
                <td class="note-ids is-size-4" sortable=false @click="downloadColsIns"> {{ item.file }} 
                <input type="number" class="has-background-warning pl-2" v-model="custIdSelected" v-bind:class="[(item.file==='Single_Cust_Transactions.xlsx')? '':'is-hidden',]">
                <i class="fa-solid fa-download pl-2 is-size-4" 
                  v-bind:class="[
                    ((item.group==='Planning')&&(weeksWithDataOrder.includes(chosenWeek))) ? 'is-active':'',
                    ((item.group==='Payment')&&(weeksWithDataTrans.includes(chosenWeek))&&(item.file!=='Single_Cust_Transactions.xlsx')&&(isCashAccountant)) ? 'is-active':'',
                    ((item.group==='Planning vs Payment')&&(weeksWithDataTrans.includes(chosenWeek))&&(weeksWithDataOrder.includes(chosenWeek))&&(isCashAccountant)) ? 'is-active':'',
                    ((item.file==='Single_Cust_Transactions.xlsx')&&(custIdSelected>0)&&(isCashAccountant)) ? 'is-active':'',
                    ]" 
                  v-bind:id="[item.url]"
                  @click="getFile"
                  ></i>
                </td>
                <td class="is-size-4" sortable=false > {{ item.group }}</td>
              </tr>
            </template >
          </v-data-table>

        </v-card>
      </div>
    
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { VDataTable,VCard,VTextField,VDataTableVirtual,VFooter,VAutocomplete,VSelect} from 'vuetify/components'
export default {
  name: 'Control Page',
  components: {
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
    VAutocomplete,
    VSelect
  },

  data() {
    return {
      chosenWeek: '',
      weeksWithData: [],
      weeksWithDataOrder: [],
      weeksWithDataTrans: [],
      custIdSelected: 0,
      currentuser: localStorage.getItem('user'),
      // localIsCashAccountant: false,
      // localIsPlanning: false,
      headers : [
          {
            align: 'start',
            key: 'file',
            title: 'Report Name',
            sortable: false,
          },
          {
            align: 'start',
            key: 'group',
            title: 'Group',
            sortable: false,
          },
      ],

      filesList: [
        { file: 'Report_Meals_Format_1.xlsx', 
          url: '/api/v1/report-meals-format-1/',
          group: 'Planning',
        },
        { file: 'Report_Meals_Format_2.xlsx', 
          url: '/api/v1/report-meals-format-2/',
          group: 'Planning',
        },
        { file: 'OrderPackage_Export.xlsx', 
          url: '/api/v1/order-package-export/',
          group: 'Planning',
        },
        { file: 'OrderPackage_Detail_Export.xlsx', 
          url: '/api/v1/order-package-detail-export/',
          group: 'Planning',  
        },
        { file: 'Check_NoteDB.xlsx', 
          url: '/api/v1/check-notes-db/',
          group: 'Planning',  
        },
        { file: 'PlanningRev_vs_CustTransactionRev.xlsx', 
          url: '/api/v1/planning-vs-payment-check/',
          group: 'Planning vs Payment',  
        },
        { file: 'Sys_Balance_vs_File_by_Week.xlsx', 
          url: '/api/v1/sys-balance-vs-file-by-week/',  
          group: 'Payment',  
        },
        { file: 'Sys_Balance_vs_File_by_Cust.xlsx', 
          url: '/api/v1/sys-balance-vs-file-by-cust/',
          group: 'Payment',  
        },
        { file: 'Export_Week_Transactions.xlsx', 
          url: '/api/v1/export-customer-transaction-week/',
          group: 'Payment',  
        },
        { file: 'Single_Cust_Transactions.xlsx', 
          url: '/api/v1/export-single-customer-transaction/',
          group: 'Payment',  
        }
      ],
    }
  },
  async beforeMount() {
    
    try {  if ( !((this.isPlaning || this.isCashAccountant)) ) { 
      this.$router.push('/') } } catch (error) {  1 == 1 }

    
    await axios
      .get('/api/v1/user/?username=' + localStorage.getItem('user'))
      .then(response => {
        localStorage.setItem('userGroups', response.data['groups'])
        response.data['groups'].includes(1) ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
        response.data['groups'].includes(3) ? this.$store.commit('setIsCashAccountant', true) : this.$store.commit('setIsCashAccountant', false)
        
        if ( !((response.data['groups'].includes(1) || response.data['groups'].includes(3))) 
          ) { 
            this.$router.push('/')
            // console.log('redirect')
            window.location.reload()
            // console.log('reload')
        }
      })
      .catch(error => {        
        if (error.response.status == 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }  
          this.$router.push('/')
          // console.log('redirect')
          window.location.reload()
          // console.log('reload')
      })  
    
    
    await axios
      .get('/api/v1/weeks-data-info/'+'?username='+localStorage.getItem('user'))
      .then((response) => {
        this.weeksWithData = response.data.all
        this.weeksWithDataOrder = response.data.order
        this.weeksWithDataTrans = response.data.transaction
      })
      .catch((error) => {
        // console.log(error)
      })
  },

  async mounted() {
    document.title = 'Control Page - FitFoodCS'
  },

  methods: {
    async getFile (event) {
      
      if (event.target.classList.contains('is-active')) {

        let endPoint = event.target.id+'?week_id='+this.chosenWeek
        let item = this.filesList.find(item => item.url === event.target.id)
        if (item.file==='Single_Cust_Transactions.xlsx') {
          endPoint = event.target.id+'?week_id='+this.chosenWeek+'&customer_id='+this.custIdSelected
        }
        this.$store.commit('setIsLoading', true)
        await axios
          .get(endPoint, 
            {responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.classList.add('is-hidden')
            // link.setAttribute('download', item.file.slice(-4)+'_'+week_id+'_'+Date.now().toString()+'.xlsx')
            link.setAttribute('download', item.file.slice(0,-5)+'_'+this.chosenWeek+'_'+Date.now().toString()+'.xlsx')
            document.body.appendChild(link)
            this.$store.commit('setIsLoading', false)
            link.click()
            
          })
          .catch((error) => {
            // console.log(error)
            this.$store.commit('setIsLoading', false)
          })
      }
    }
  },

  computed: {
    isPlaning : function(){ 
       
       return this.$store.getters.isPlaning
     },

     isCashAccountant : function(){ 
      
      return this.$store.getters.isCashAccountant
    },
  },




}



</script>
<style scoped>
.fa-download {
  color: #9e9e9e;
  cursor: not-allowed;
}

.fa-download.is-active {
  color: #160272;
  cursor: pointer;
}
</style>