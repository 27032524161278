<template>
  <div class="thank-you-message is-flex is-flex-direction-column is-flex-grow-1 is-justify-content-center is-align-content-center is-align-items-center p-5" v-bind:class="[(isPlaningCS||isCashAccountant) ? '' : 'is-hidden']">
      <div class="has-text-centered is-size-1">
        <i class="" v-bind:innerHTML="thankYou">  </i> 
      </div>
      <div>
        <i class="note-no-payment is-size-3"  v-bind:innerHTML="thankYouBy">  </i>
      </div>
  </div>
  
  <div class="absolute-button is-flex is-flex-direction-column is-justify-content-center"  @click="showInternal=!showInternal" v-bind:class="[(isPlaningCS||isCashAccountant) ? 'is-hidden' : '']">
    
    <div class="">
      <b>{{ showInternal ? 'Hide' : 'Show' }}</b>
    </div>
    
  </div>
  <div class="absolute-button-2 is-flex is-flex-direction-column is-justify-content-center" v-bind:class="[(isPlaningCS||isCashAccountant) ? 'is-hidden' : '']" @click="gotoOrderHistNull">
    
    <div class="">
      OrderHist
    </div>
    
  </div>
  <div class="is-flex is-flex-direction-row is-flex-grow-1 no-flow-y" v-bind:class="[(isPlaningCS||isCashAccountant) ? 'is-hidden' : '']">
    <div class="home is-four-fifths is-flex-grow-1 is-flex is-flex-direction-column" id="wrap-iframe">
      <iframe class="is-flex-grow-1" frameborder="0" id="scaled-frame" src="https://app.harasocial.com/inbox/" @click="iframeClick" allow="clipboard-write" ></iframe>
    </div>
    <div class="pl-2 pr-0 pt-2 column is-one-fifth home-side-holder" v-show="showInternal">
      <div class="is-flex is-flex-direction-column">
        <div class="control input-holder mr-2">
          <i class="fa-solid fa-xmark custinfo_cust_phone-remove autocomplete" v-bind:class="[{'is-hidden':!hasValueCustInfo}]" @click="removeCurrentCustInfoValue"></i>
          <input 
            class="input autocomplete pl-9" type="text" placeholder="customer phone" id='custinfo_cust_phone' autocomplete="off" 
            @change="formPhoneChange" 
            v-model="autocompleteValue"
            @input="custInfoInput"
            @focusin="custInfoInput"
            v-on:keydown="custInfoKeyDown"
            >
            <!-- @focusout="custInfoFocusOut" -->
          <ul
            v-show="isOpenAutocomplete"
            class="autocomplete-results-holder autocomplete"
          >
          <!-- v-show="isOpenAutocomplete" -->
            <li
              v-for="(result, i) in autocompleteSuggestionsShow"
              class="autocomplete-result pl-2 pt-2 is-size-5 autocomplete"
              @click="clickAutocomplete"
              :class="[(i === arrowCounter) ? 'is-active' : '' , i+'-autocomplete-stt']"
            >
            {{ result }}
            </li>
          </ul>
            <!-- @update:search="(modelValue) => testChange(modelValue)" -->
        </div>
        
        <div class="detail-holder-info-controller is-flex is-flex-direction-row pt-2">
          <div class="pr-3 pl-2 control-item is-clickable is-active" @click="showControl" id="note" > Note  </div>
          <div class="pr-3 pl-2 control-item is-clickable " @click="showControl" id="cust_info" > Cust Info </div>
          <div class=" pr-2 control-item is-clickable pl-2" @click="showControl" id="orders" > Orders  </div>
        </div>

        <div class="mr-2 is-one-fifth is-flex-grow-1 has-background-light is-flex is-flex-direction-column is-justify-content-start note-holder note-control-class is-active" style="max-width: 100%; height: 84vh !important;">
          <div class="is-flex is-flex-direction-column p-0 pb-2 pt-1" >
            <div class="is-flex-grow-1 is-flex is-flex-direction-column" >
              <div class="top-addon pl-2 pr-2 pb-1" >
                <div class=" is-flex is-flex-direction-row pt-1 form-icon-line is-align-items-center">
                  <div class="pl-2 pb-0" style="width: 17%;">
                    Week
                  </div>
                  <div class="pl-2 is-flex is-flex-direction-row is-size-5 form-input-type-holder is-align-items-center" style="width: 50%;">
                    
                    <i class="fa-solid fa-basket-shopping has-text-centered is-active form-input form-input-type is-clickable order" @click="formInputIcon" style="width: 31%;"></i>
                    <i class="fa-solid fa-credit-card  has-text-centered  form-input form-input-type is-clickable payment" @click="formInputIcon" style="width: 31%;"></i>
                    <i class=" has-text-centered " style="width: 31%;"></i>
                    
                  </div>
                  <div class="is-flex is-flex-direction-row pl-2 form-input-channel-holder is-size-5 is-align-items-center" style="width: 32%;">
                    <i class="fa-solid fa-square-phone-flip  has-text-centered form-input  form-input-channel is-clickable phone" @click="formInputIcon" style="width: 50%;"></i>
                  </div>
                </div>
                <div class="is-flex is-flex-direction-row pb-0 pt-1 form-icon-line is-align-items-center" >
                  <div class="pl-2 pt-0 pb-0" style="width: 17%;">
                    <strong class="week_id-note-week_str pb-0 pt-0" v-bind:innerHTML="chosenWeek"> </strong>
                  </div>
                  <div  class="is-flex is-flex-direction-row pl-2  is-size-5 form-input-type-holder is-align-items-center" style="width: 50%;">
                    <i class="fa-solid fa-motorcycle  has-text-centered  form-input form-input-type is-clickable ship" @click="formInputIcon" style="width: 31%;"></i>
                    <i class="fa-solid fa-ban  has-text-centered  form-input form-input-type is-clickable cancel" @click="formInputIcon" style="width: 31%;"></i>
                    <i class="fa-solid fa-circle-question  has-text-centered  form-input form-input-type is-clickable other" @click="formInputIcon" style="width: 31%;"></i>
                    
                  </div>
                  <div style="width: 32%;" class="is-flex is-flex-direction-row pl-2 form-input-channel-holder is-size-5 is-align-items-center">
                    
                    <i class="fa-brands fa-square-facebook  has-text-centered form-input form-input-channel is-clickable facebook is-active" @click="formInputIcon" style="width: 50%;"></i>
                    <i class="fa-solid  fa-square-minus  has-text-centered form-input form-input-channel is-clickable other" @click="formInputIcon" style="width: 50%;"></i>
                  </div>
                </div>
              </div>
              <div class="is-flex is-flex-direction-row is-justify-content-space-evenly cust-name-form" style="max-height: 3vh" v-bind:innerHTML="custName"></div>
              <textarea  class="textarea has-fixed-size textarea-note" placeholder="note description" style="height: 15vh; min-height: 0 !important; width: 100%;" @input="evaluateCreateNote" ></textarea>
              <div class="bottom-addon is-flex is-flex-direction-row is-justify-content-center p-2 is-clickable disabled" id="button-create-note" @click="submitCreateNote">
                CREATE <i class="week-chonsen-check-create-note pl-2"> </i>
              </div>
              <div class="is-flex is-flex-direction-row is-justify-content-space-evenly">
                <div v-bind:innerHTML="note5weeks[0]" class="weeks-5-items is-clickable" @click="xuLyChosenWeek"> </div>
                <div v-bind:innerHTML="note5weeks[1]" class="weeks-5-items is-clickable" @click="xuLyChosenWeek"> </div>
                <div v-bind:innerHTML="note5weeks[2]" class="weeks-5-items is-clickable is-active" @click="xuLyChosenWeek"> </div>
                <div v-bind:innerHTML="note5weeks[3]" class="weeks-5-items is-clickable" @click="xuLyChosenWeek"> </div>
                <div v-bind:innerHTML="note5weeks[4]" class="weeks-5-items is-clickable" @click="xuLyChosenWeek"> </div>
              </div>
            </div>
          </div>
          <div class="pl-2 is-flex is-align-items-stretch" style="height: 4vh;">
            <div class="field has-addons is-justify-content-end pr-2" style="width:100%">
              <div class="control " style="box-sizing: border-box;">
                <a class="button is-static" style="box-sizing: border-box;">
                  <i class="fa-solid fa-reply-all is-clickable" aria-hidden="true" @click="deFilterNotes"></i>
                </a>
              </div> 
              <div class="control">
                <input class="input" type="text" id="note-search-input" placeholder="Search Note" v-model="searchNote">
              </div>
              <div class="control">
                <a class="button is-static" style="box-sizing: border-box;">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </a>
              </div> 
            
            </div>  
          </div>
          
          <div class="mt-2 is-flex-grow-1 note-holder-scroll p-2" style="height: 45vh;">
            <v-card class="table-container" >
              <v-data-table-virtual
                id="table-main-note-home"
                :headers="headersNote"
                :items="notes"
                :search="searchNote"
                :custom-filter="customSearchNote"
                fixed-header
                sortable: true
        
                items-per-page="1000"
                height="45vh"
              >
              <!-- :search="searchNote"
              :custom-filter="customSearchNote" -->
              <template  #item="{ item }">
                <tr >
                  <td v-bind:class="[(item.is_completed==1) ? 'completed-note' : 'pending-note',
                    (this.weeks[2]['id'] === item.week_id) ? 'current-week-notes' : '',
                    item.week_id+'-note-week_id',
                  ]">  
                    <i v-bind:id="[item.id]" class="fa-solid fa-external-link is-size-7" @click="gotoOrderHistNote" style="color:blue; cursor: pointer;"></i>
                    <strong class="pl-2">{{ item.weekStr +' '}} </strong>
                    {{ item.description }}
                    <i> ({{ item.created_by_id}} | {{ item.created.slice(5,10).replace('-','') }}:{{ item.created.slice(11,16).replace(':','') }})</i>
                    <strong> ({{  item.is_completed_string}})</strong>
                  </td>
                </tr>
              </template >

              </v-data-table-virtual>

            </v-card>
          </div>
        </div>
      
        <div class="mr-2 is-one-fifth is-flex-grow-1 has-background-light is-flex is-flex-direction-column is-justify-content-start cust_info-control-class" style="max-width: 100%; height: 84vh !important;">
          <div class="column is-flex pl-0 pr-0 pt-1 pb-1 cust_focus_info_title is-flex-grow-0 has-background-warning" style="max-width: 100%;">
            <div class="column has-text-left p-0 pl-2">
              <p> <strong>CUSTOMER_INFO</strong></p>
            </div>
            <div class="column has-text-right pt-0 pb-0">
            </div>
          </div>
          <div class="column pl-2 pr-0 is-flex-grow-1 group_main_info" style="border-bottom: 3px solid gray; max-height: 25vh !important;">
            <div class="column p-0">
              <span> <strong> Name: </strong>  </span> 
              <span class="group_main_info_name" v-bind:innerHTML="this.custName"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Main Address: </strong>  </span> 
              <span class="group_main_info_address" v-bind:innerHTML="this.custMainAddress"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Main Phone: </strong>  </span>
              <span class="group_main_info_phone" v-bind:innerHTML="this.custMainPhone"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Diet: </strong>  </span>
              <span class="group_main_info_phone" v-bind:innerHTML="this.diets"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Traits: </strong>  </span>
              <span class="group_main_info_phone" v-bind:innerHTML="this.traits"> 
              </span>
            </div>
          </div>
          <!-- start of other ADDRESS -->
          <div class="column is-flex p-0 group_address_title has-background-warning is-flex-grow-0" style="max-width: 100%; max-height: 4vh !important;">
            <div class="column has-text-left pt-1 pl-0 pr-0 pb-1 pl-2 is-flex is-flex-direction-row">
              <strong class="is-flex-grow-3">OTHER ADDRESS</strong>
              
              <i class="is-clickable pr-2"
              v-bind:class="[!(this.addressShowAll) ? 'is-hidden' : '']"
              @click="this.addressShowAll = false"
              > showing all</i>

              <i class="is-clickable pr-2"
              v-bind:class="[(this.addressShowAll) ? 'is-hidden' : '']"
              @click="this.addressShowAll = true"
              > showing active</i>

            </div>
          </div>
          <div class="column pl-1 pr-1 pt-1 pb-0 group_address is-flex-grow-0" style="border-bottom: 3px solid gray; height: 48vh;">
            <v-card class="table-container" >
              <v-data-table-virtual
                id="table-group-address-home"
                :headers="headersAddress"
                :items="group_address_filtered"
                fixed-header
                sortable: false
                items-per-page="1000"
                height="48vh"
              >
              <template  #item="{ item }">
                <tr >
                  <td class="hide-add-item" v-bind:class="[
                    (item.is_main) ? 'is-hidden' : '',
                    (item.is_active) ? 'ia-active' : '',
                    (this.addressShowAll) ? 'show-active' : ''
                  ]">
                    <span class=""> {{item.name}}  </span>   
                    <i class="fa-solid pl-2 fa-eject custAdd-control makemain pt-1" 
                      v-bind:id="[item.id+'-cust-address-item']" 
                      v-bind:class="[(!(item.is_main) && isPlaningCS && item.is_active) ? 'is-clickable' :'is-disabled' ]" 
                      @click="custAddressEdit"></i>

                    <i class="fa-regular fa-rectangle-xmark pl-2 custAdd-control deactive pt-1" 
                      v-bind:class="[!(item.is_active) ? 'is-disabled' : 'is-clickable']" 
                      v-bind:id="[item.id+'-cust-address-item']" 
                      @click="custAddressEdit"></i>

                    <i class="fa-regular fa-square-check pl-2 custAdd-control activate pt-1" 
                      v-bind:class="[(item.is_active) ? 'is-disabled' : 'is-clickable']" 
                      v-bind:id="[item.id+'-cust-address-item']" 
                      @click="custAddressEdit"></i> 
                    
                    
                    <br>
                    <span class=""> {{item.phone_number_readable}}  </span> <br>
                      {{ item.address_detail }} 
                  
                  </td>
                </tr>
              </template >

              </v-data-table-virtual>

            </v-card>
          </div>
          <!-- end of customer address -->
        
        </div>

        <div class="mr-2 is-one-fifth is-flex-grow-1 has-background-light is-flex is-flex-direction-column is-justify-content-start orders-control-class " style="max-width: 100%; height: 84vh !important;">
          <div class="pr-2 detail-holder-order-list-part-1 pl-2 pt-2 is-flex-grow-1 is-flex is-flex-direction-column pb-3" style="max-width: 100%; max-height: 81vh;">
            <div class="is-flex is-flex-direction-row column is-justify-content-space-between is-flex-grow-1 " style="width:100%">
              
              <div class="field has-addons">
                <div class="control ">
                  <a class="button is-static" style="box-sizing: border-box;">
                    <i class="fa-solid fa-reply-all is-clickable" aria-hidden="true" style="box-sizing: border-box;" @click="deFilterOrderList"></i>
                  </a>
                </div> 
                <div class="control" >
                  <input class="input de-filter-order-list" type="text" placeholder="Search complete input" v-model="search">
                </div>
                <div class="control" >
                  <a class="button is-static" style="box-sizing: border-box;">
                    <i class="fa fa-search" aria-hidden="true" style="box-sizing: border-box;"></i>
                  </a>
                </div>
                
              </div>
            </div>
            <v-card class="table-container" style="height: 80vh;">
              <v-data-table-virtual
                id="table-main-order-list-home"
                :headers="headers"
                :items="orders"
                :search="search"
                :custom-filter="customSearch"
                fixed-header
                sortable: false
                items-per-page="1000"
                height="80vh"
              >
                <template   #item="{ item }" >
                  <tr >
                    <td  > 
                      <i v-bind:id="[item.id]" class="fa-solid fa-external-link is-size-7" @click="gotoOrderHist" style="color:blue; cursor: pointer;"></i>
                      
                      {{ item.week_str }} : {{ (item.ycdb2 === 'Thêm phần ăn sáng dành cho gói MEAT LOVER') ? item.packAlias.replace('MEAT','MEAT-S') : item.packAlias
                      
                      }} -  {{ item.totalMeals }} meals - {{ this.formatPriceRound (item.finalAmt) }}đ - {{ item.shipLoc }} 
                    </td>
                    
                  </tr>
                </template >

              </v-data-table-virtual>

            </v-card>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

import { VDataTable,VCard,VTextField,VDataTableVirtual,VFooter,VAutocomplete,VSelect} from 'vuetify/components'
export default {


  name: 'Home',
  components: {
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
    VAutocomplete,
    VSelect
  },

  data() {
    return {
      min_week_id: 0,
      thankYou : '',
      thankYouBy : '',
      showInternal: true,
      // vì lười sửa code nên để ở đây cho khỏi lỗi
      selectedPackId: '',
      // vì lười sửa code nên để ở đây cho khỏi lỗi
      sortBy: 'id',
      sortDesc: true,
      chosenWeek: '',
      selectedWeek_id: 0,
      autocompleteValue: '',
      isOpenAutocomplete: false,
      arrowCounter: -1,
      autocompleteSuggestions: [],
      autocompleteSuggestionsShow: [],
      custName: '',
      custMainAddress: '',
      custMainPhone: '',
      custId: '',
      transactions: [],
      traits: '',
      diets: '',
      group_address: [],
      notes: [],
      orders: [],
      weeks: [],
      weeks7: [],
      note5weeks:[],
      note5weeksMap:[],
      urlPassedWeek: 0,
      addressShowAll : false,
      currentUser: '',
      headersNote : [
        {
          align: 'start',
          key: 'description',
          title: 'description',
          sortable: false,
        },
      ],
      headersAddress : [
          {
            align: 'start',
            key: 'address_detail',
            title: 'adddddress_detail',
            sortable: false,
          },
      ],
      searchNote: '',
      search: '',
      headers : [
          {
            align: 'start',
            key: 'week_str',
            title: 'Pack',
            sortable: false,
          }
          
        ],
    }
  },
  
  async beforeMount() {
    await this.getWeeks()
    await this.get7weeks()
    try {
      if (this.isCashAccountant||this.isPlaningCS) {
        document.getElementById('wrap-iframe').remove()
      }} catch (error) {
        1==1
      }
    

  },
  async mounted() {
    // clicked iframe
    // console.log('beforeMount ahiihi')
    // console.log(window.devicePixelRatio)
    // console.log(this.isCashAccountant)
    // console.log(this.isPlaningCS)
    // console.log((this.isCashAccountant||this.isPlaningCS))
    // console.log(!(this.isCashAccountant||this.isPlaningCS))

    if ((window.devicePixelRatio >= 1.24) && !(this.isCashAccountant||this.isPlaningCS)) {
      document.getElementsByTagName('section')[0].classList.remove('main-layout')
      document.getElementsByClassName('container')[0].classList.remove('main-layout')
      document.getElementsByTagName('nav')[0].classList.add('is-hidden')
    }

    window.focus()
    window.addEventListener("blur", () => {
      this.isOpenAutocomplete = false
    }, { once: false });
    
    window.addEventListener('message',function(message){
      if(message.data.type=="preview.compiled"){
        // console.log(message)
      }
    });

    document.getElementById('scaled-frame').addEventListener('keydown', this.copyevent)

    this.currentUser = localStorage.getItem('user')

    document.title = 'Home'
    await axios
      .get('/api/v1/cache-cust/')
      .then(response => {
        this.autocompleteSuggestions = response.data
        // console.log('this.autocompleteSuggestions')
        // console.log(this.autocompleteSuggestions)
    })
    .catch(error => {
      // console.log(error)
    });
    
    this.setAutoComplete('init')

    document.addEventListener('click', this.handleClick)

    this.quotesDay()
  },

  render () {
    // console.log('render HOME')
  },
  
  updated () {
    // console.log('updated HOME')
  },

  methods : {

    copyevent (event) {
      // console.log('copyevent')
      // console.log(event)
    },
    clickIframe(event) {
      // console.log('clickIframe')
      // console.log(event)
    },

    resetPackInfo () {
      1==1

    },
    resetCustInfo() {
      this.custName = ''
      this.custMainAddress = ''
      this.custMainPhone = ''
      this.traits = ''
      this.diets = ''
      this.custId = null
      this.notes = []
      this.orders = []
      this.group_address = []

    },

    async formPhoneChange (event,byPass=false) {
      // console.log('formPhoneChange')
      if (event.srcElement.value === null) {
        this.resetCustInfo()
        this.resetPackInfo()
        // console.log('formPhoneChange null out')
        return
      }
      if (!byPass) {
        if (this.autocompleteValue === '')  {
          this.resetCustInfo()
          this.resetPackInfo()
          // console.log('formPhoneChange no string out')
          return
        }
      }
      

      let myString = event.srcElement.value
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      this.autocompleteValue = myString
      document.getElementById('custinfo_cust_phone').value = myString

      if (myString.length < 10) {
        this.resetCustInfo()
        this.resetPackInfo()
        return
      }
      
      this.phoneChanged(event)
    },

    async phoneChanged (event) {
      this.$store.commit('setIsLoading', true)
      // console.log('phoneChanged')
      let myString = event.srcElement.value
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      
      this.autocompleteValue = myString
      event.srcElement.value = myString

      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
      .get('/api/v1/customerHist/?main_phone='+myString + '&min_week_id=' + this.min_week_id)
      .then(response => {
        // console.log(0)
        this.resetCustInfo()
        // console.log(1)
        let result = response.data
        this.setAutoComplete(result.phone_number_readable + ':' + result.name)
        this.custName = result.name
        this.custMainAddress = result.main_address
        this.custMainPhone = result.phone_number_readable
        this.custId = result.id
        this.transactions = result.transactions
        let traitCount = 0
        Object.keys(result.traits).forEach(key => {
          if (traitCount >10) {
            1==1          
            }
          else {
            this.traits = this.traits + '(<strong>' + Math.min(result.traits[key],99) + 'x</strong>_' + key.toLowerCase() +'); '
          }  
          
            traitCount = traitCount + 1
            
        });
        Object.keys(result.diets).forEach(key => {
            this.diets = this.diets + '(<strong>' + Math.min(result.diets[key],99) + 'x</strong>_' + key.toLowerCase() +'); '
        });
        
        // console.log(2)
        this.group_address = result.customer_addresses
        // console.log(2.1)
        this.notes = result.notes
        for (let i = 0; i < this.notes.length; i++) {
            // console.log(this.notes[i])
            this.notes[i]['is_completed_string'] = this.notes[i].is_completed == 1 ? 'done' : 'pending'
            // console.log(this.notes[i].week_id)
            this.notes[i]['weekStr'] = this.getWeekStr(this.notes[i].week_id)
          }
        // console.log('API SUCCESS END')
        // console.log(3)
        // console.log(this.notes)
        this.notes.sort((a, b) => (a.id > b.id) ? -1 : 1)
        // console.log(this.notes)
        let ordersNotNull = result.orders.filter(order => (order.fullStr !== null)||(order.finalAmt !== 0))
        this.orders = ordersNotNull
        // console.log(this.orders)
        

        this.resetPackInfo()
        document.querySelector('.control-item.is-active').click()
        this.$store.commit('setIsLoading', false)
        this.evaluateCreateNote(1000)
      })
      .catch(error => {
        
        this.resetCustInfo()
        this.resetPackInfo()
        document.querySelector('.control-item.is-active').click()
        this.custName = '<strong class="non-exist"> Không tồn tại </strong>'
        this.$store.commit('setIsLoading', false)
      })

    },

    getWeekStr (week_id) {
      let start_date_id = this.weeks.find(week => week.id === week_id)
      if (start_date_id === undefined) {
        return week_id.toString()
      } else {
        start_date_id = start_date_id.start_date_id.toString()
        return start_date_id.slice(4,6) + '.' + start_date_id.slice(6,8)
      }
   
    },

    showControl (event) {

      ['note','cust_info','orders'].forEach(target => {
        // console.log(event.srcElement.id+'-control-class')
        if (event.srcElement.id === target) {
          event.srcElement.classList.add('is-active')
          // console.log('-control-class')
          // console.log(document.getElementsByClassName(target+'-control-class'))
          document.getElementsByClassName(target+'-control-class')[0].classList.add('is-active')

        } else {
          
          document.getElementById(target).classList.remove('is-active')
          // console.log(document.getElementsByClassName(target+'-control-class'))
          document.getElementsByClassName(target+'-control-class')[0].classList.remove('is-active')
        }
      });

      },
    async custAddressEdit (event) {
      // console.log('custAddressEdit')
      if (event.target.classList.contains('is-disabled')) {
        return
      }
      let custAddId = event.target.id.replace('-cust-address-item','')
      // console.log(event.target.id)

      // ['makemain','deactive','activate']
      let is_main = 0
      if (event.target.classList.contains('makemain')) {
        is_main = 1
      }
      let active = 1
      if (event.target.classList.contains('deactive')) {
        active = 0
      }

      if (event.target.classList.contains('activate')) {
        active = 1
      }
      
      let formData = {
        id: custAddId,
        is_main: is_main,
        is_active: active
      }
      await axios
        .post('/api/v1/customer-address-edit/', formData)      
        .then(response => {
          // console.log(response.data)
          // this.formPhoneChange({srcElement: {value: this.custMainPhone}},true)
          this.group_address = response.data
          let main_address = this.group_address.find(address => address.is_main === 1)
          this.custMainAddress = main_address.address_detail
          this.custName = main_address.name
          this.custMainPhone = main_address.phone_number_readable

        })
    },
    evaluateCreateNote (event) {
      let noteButton = document.getElementById('button-create-note')
      
      let filledNote = !(document.getElementsByClassName('textarea-note')[0].value==='')
      let selectedWeek_id = this.selectedWeek_id === 0 ? false : true
      let custId = this.custId === null ? false : true
      let name = this.custName === '' ? false : true
      let main_phone = this.custMainPhone === '' ? false : true

      // console.log('filledWeekStr')
      // console.log(filledWeekStr)
      // console.log('filledNote')
      // console.log(filledNote)
      // console.log('selectedWeek_id')
      // console.log(selectedWeek_id)

      if ( filledNote && selectedWeek_id && custId && name && main_phone) {
        noteButton.classList.remove('disabled')
      } else {
        noteButton.classList.add('disabled')
      }
    },
    removeCurrentCustInfoValue (event) {
      this.autocompleteValue = ''
      this.filterAutocompleteResults();
      this.isOpenAutocomplete = true;
      // console.log('event.target.parentElement.childNodes[2]')
      // console.log(document.getElementById('custinfo_cust_phone'))
      // console.log(this.autocompleteSuggestionsShow)
      // console.log(this.isOpenAutocomplete)
      this.resetCustInfo()
      this.evaluateCreateNote(100)
      document.getElementById('custinfo_cust_phone').focus()

      
      // document.getElementById('custinfo_cust_phone').dispatchEvent(new Event('input', { bubbles: true }))
    },
    custInfoInput (event) {
      this.filterAutocompleteResults();
      this.isOpenAutocomplete = true;
      this.arrowCounter = -1;
      // document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    },
    filterAutocompleteResults() {
      // this.autocompleteSuggestionsShow = this.autocompleteSuggestions.slice(0,10)
      this.autocompleteSuggestionsShow = this.autocompleteSuggestions.filter(item => 
        item.replaceAll('-','').toLowerCase().indexOf(this.autocompleteValue.replaceAll('-','').toLowerCase()) > -1
        );
    },
    setAutoComplete (stringPassed) {
      
      if (stringPassed === 'init') {
        try {
          // console.log('init')
          // console.log(localStorage.getItem('searchHist'))
          // console.log(localStorage.getItem('searchHist').split('|||'))
          // console.log(this.autocompleteSuggestions.concat(localStorage.getItem('searchHist').split('|||')))
          this.autocompleteSuggestions = localStorage.getItem('searchHist').split('|||').concat(this.autocompleteSuggestions)
          this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
        } catch (error) {
          1==1
        }
        return
      }
      // localStorage.removeItem('searchHist')
      // console.log(stringPassed)
      let localSearchHist = ''
      try {
        localSearchHist = localStorage.getItem('searchHist')
        // console.log('localSearchHist')
        // console.log(localStorage)
        // console.log(localSearchHist)
        
      } catch (error) {
        localSearchHist = ''
      }
      
      // first time set
      if ((localSearchHist === '') || (localSearchHist === null)){
        // console.log('localSearchHist is empty')
        localStorage.setItem('searchHist', stringPassed)
        
        this.autocompleteSuggestions = [stringPassed].concat(this.autocompleteSuggestions)

        this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
        return
      } 


      let curResult = stringPassed.split(':')
      // console.log('curResult')
      // console.log(curResult)
      // existed return
      if (localSearchHist.indexOf(stringPassed)>-1) {
        localSearchHist = localSearchHist.replaceAll(stringPassed+'|||', '')
        let newSearchHist = stringPassed + '|||' + localSearchHist 
        localStorage.setItem('searchHist', newSearchHist)
        this.autocompleteSuggestions = localStorage.getItem('searchHist').split('|||').concat(this.autocompleteSuggestions)
        this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
        return
      } 

      let newSearchHist = stringPassed + '|||' + localSearchHist 
      localStorage.setItem('searchHist', newSearchHist)
      let arraySuggest = newSearchHist.split('|||')

      

      if (arraySuggest.length > 100) {
        arraySuggest = arraySuggest.slice(0,99)
      }
      localStorage.setItem('searchHist', arraySuggest.join('|||'))

      this.autocompleteSuggestions = newSearchHist.split('|||').concat(this.autocompleteSuggestions)
      this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
      // console.log('arraySuggest')
      
      
      // console.log('arraySuggestions')
      // console.log(arraySuggest)
      // console.log(arraySuggest[0])
      // console.log(arraySuggest[0].indexOf('|'))
      // console.log(arraySuggest.join('|||').replaceAll('|||', ''))

      if (arraySuggest.join('|||').replaceAll('|||', '').indexOf('|')>-1) {

        localStorage.removeItem('searchHist')
        // this.autocompleteSuggestions = []

      }
    },
    custInfoKeyDown (event) {
      // console.log('custInfoFocusOut')
      // console.log(event)
      if ((event.key === 'Tab') && (this.isOpenAutocomplete)) {

        // document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({block: "nearest"});
        this.isOpenAutocomplete = false
        this.arrowCounter = -1;
      }

      if ((event.key === 'Tab')) {
        // document.getElementsByClassName('de-filter-order-list')[0].focus()
        let a =1
      }


      if (event.key === 'ArrowDown') {
        this.isOpenAutocomplete = true;
        this.filterAutocompleteResults();
        // document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
        this.arrowCounter = this.arrowCounter + 1
        
        if (this.arrowCounter >= this.autocompleteSuggestionsShow.length) {
          this.arrowCounter = 0
          
        }
        document.getElementsByClassName(this.arrowCounter+'-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
      }

      if (event.key === 'ArrowUp') {
        this.arrowCounter = this.arrowCounter - 1
        if (this.arrowCounter < 0) {
          this.arrowCounter = this.autocompleteSuggestionsShow.length - 1
        }
        document.getElementsByClassName(this.arrowCounter+'-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
      }

      if (event.key === 'Enter') {
        if (this.arrowCounter > -1) {
          this.autocompleteValue = this.autocompleteSuggestionsShow[this.arrowCounter]
          this.formPhoneChange( {srcElement: {value: this.autocompleteValue}}, true)
          
        }
      }
      
      
    },
    clickAutocomplete (event) {
      // console.log('clickAutocomplete')
      // console.log(event.target)

      this.formPhoneChange( {srcElement: {value: event.target.innerText}} , true)
      
      
    },
    handleClick (event) {
      // console.log(event.target.id)
      try {
        if (!(event.target.classList.contains('autocomplete'))) {
          
          if (event.target.id === 'custinfo_cust_phone') {
            1==1
          } else {
            this.arrowCounter = -1
            document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({block: "nearest"});
            this.isOpenAutocomplete = false
                
            
          }
        } 


      } catch (error) {
       1==1
      }
      
    },
    formInputIcon (event) {
      if (event.srcElement.classList.contains('form-input-type')) {
        let allActive = document.getElementsByClassName('form-input-type')
        Array.from(allActive).forEach(element => {
          element.classList.remove('is-active')
        });
        event.srcElement.classList.add('is-active')
      } else if (event.srcElement.classList.contains('form-input-channel')) {
        let allActive = document.getElementsByClassName('form-input-channel')
        Array.from(allActive).forEach(element => {
          element.classList.remove('is-active')
        });
        event.srcElement.classList.add('is-active')
      }
    },
    async submitCreateNote () {
      
      if (document.getElementById('button-create-note').classList.contains('disabled')) {
        return
      }

      let channel = ''
      let channelActive = document.querySelector('.form-input-channel.is-active')
      Array.from(['phone','facebook','other']).forEach(element => {
        if (channelActive.classList.contains(element)) {
          channel = element
        }
      });
      // console.log(channel)

      let typeStr = ''
      let typeActive = document.querySelector('.form-input-type.is-active')
      Array.from(['order','payment','ship','cancel','other']).forEach(element => {
        if (typeActive.classList.contains(element)) {
          typeStr = element
        }
      });
      
      const formData = {
        customer_phone: this.custMainPhone.replaceAll('-',''),
        customer_id: this.custId,
        name: this.custName,
        channel_id: channel,
        description: document.getElementsByClassName('textarea-note')[0].value,
        type: typeStr,
        is_month_order: 0,
        last_edited_by_id: this.currentUser,
        week_id: this.selectedWeek_id,
        orderpackage_id : 'note từ home view',
      }
      // console.log(formData)
      this.$store.commit('setIsLoading', true)
      await axios
        .post('/api/v1/create-note-and-get-by-cust/', formData)
        .then(response => {
          // console.log(response.data)
          this.notes = response.data
          // console.log(response.data)
          for (let i = 0; i < this.notes.length; i++) {
            this.notes[i]['is_completed_string'] = this.notes[i].is_completed == 1 ? 'done' : 'pending'
            this.notes[i]['weekStr'] = this.getWeekStr(this.notes[i].week_id)
          }
          this.$store.commit('setIsLoading', false)
          document.getElementsByClassName('textarea-note')[0].value = ''
          document.querySelector('.form-input-channel.facebook').click()
          document.querySelector('.form-input-type.order').click()
          this.evaluateCreateNote(1000)
          this.notes.sort((a, b) => (a.id > b.id) ? -1 : 1)
          // console.log('this.selectedPackIdLocal')
          // console.log(this.notes)
        })
        .catch(error => {
          // console.log(error)
          this.$store.commit('setIsLoading', false)
          this.evaluateCreateNote(1000)
        })
    },
    deFilterNotes (event) {
      document.getElementById('note-search-input').value = ''
      this.searchNote = ''
    },
    customSearchNote (value, search, item) {
      for (const [key, value] of Object.entries(item['raw'])) {
        try { if (value.toString().toLowerCase().includes(search.toString().toLowerCase())) { return true} } catch (error) {1==1}
      }
      return false
    },
    deFilterOrderList (event) {
      this.search = ''
    },
    customSearch (value, search, item) {
      for (const [key, value] of Object.entries(item['raw'])) {
        try { if (value.toString().toLowerCase().includes(search.toString().toLowerCase())) { return true} } catch (error) {1==1}
      }
      return false
    },
    formatPriceRound (price) {
      return Math.round(price).toLocaleString('en-US')
    },
    customSearch (value, search, item) {
      for (const [key, value] of Object.entries(item['raw'])) {
        try { if (value.toLowerCase().includes(search.toLowerCase())) { return true} } catch (error) {1==1}
      }
      return false
    },
    async getWeeks () {
      await axios
      .get('/api/v1/last-year-till-now/')
      .then(response => {
        let result = response.data
        this.weeks = result  
        var d = new Date();
        d.setMonth(d.getMonth() - 3);
        this.min_week_id = d.getUTCFullYear()*100+ (d.getUTCMonth())*4
        
        // console.log(this.note5weeks)
        // this.note5weeks.reverse()
        // console.log(this.note5weeksMap)
// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        // this.formPhoneChange({srcElement: {value: '0703618167'}});
        })
      .catch(error => {
        if (error.response.status === 401) {
            // console.log('error.response.status')
            // console.log(error.response.status)
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
      })

      // xxxxxxxxxxxxxxx
      
    },

    xuLyChosenWeek (event) {
      let weekStr = event.target.innerText
      let week_id = this.note5weeksMap.find(item => item[weekStr])
      this.selectedWeek_id = week_id[weekStr]
      this.chosenWeek = weekStr

      let all5week = document.getElementsByClassName('weeks-5-items')
      Array.from(all5week).forEach(element => {
        element.classList.remove('is-active')
      });
      event.target.classList.add('is-active')

      this.evaluateCreateNote(1000)

    },

    iframeClick (event) {
      // console.log('iframeClick')
    },

    gotoOrderHistNull (event) {
      const routeData = this.$router.resolve( {name: 'OrderHistView'})
      window.open(routeData.href, '_blank');
      // console.log('gotoOrderHistNull')
      // console.log(event)
    },

    gotoOrderHist (event) {
      
      let c_order = this.orders.find(order => order.id === event.target.id)
      const routeData = this.$router.resolve( {name: 'OrderHistView', query: {
                                                                              q: c_order.customerPhone.replaceAll('-',''),
                                                                              week_id: c_order.week_id,
                                                                              }}
                                                        );
      window.open(routeData.href, '_blank');
    },

    gotoOrderHistNote (event) {
      // console.log('event.target.id')
      // console.log(event.target.id)
      // console.log(this.notes)
      let c_note = this.notes.find(note => note.id === parseInt(event.target.id))
      const routeData = this.$router.resolve( {name: 'OrderHistView', query: {
                                                                              q: c_note.customer_phone,
                                                                              week_id: c_note.week_id,
                                                                              }}
                                                        );
      window.open(routeData.href, '_blank');
    },

    async quotesDay () {
      await axios
        .get('https://api.quotable.io/random?maxLength=300')
        .then(response => {
          // let todayInt = Math.floor(Math.random() * 10)
          this.thankYou = response.data.content
          this.thankYouBy = response.data.author
        })
        .catch(error => {
          1==1
          // console.log('error quote')
        })
    },

    async get7weeks() {
      // console.log('get7weeks')
      // console.log(axios.defaults)
      
      await axios
        .get('/api/v1/last-4-week/')
        .then(response => {
          const result = response.data
          var i = 0
          result.forEach(element => {
            element.stt = 'week' + i.toString(),
            i++
          });
          this.weeks7 = result
          // console.log(this.weeks7)
          let max_iter = 100
          var u = 0
          for (u = 0; u < this.weeks7.length; u++) {
            if (this.note5weeks.length<5) {
              // console.log(this.weeks[i].id)
              let item = {}
              item[this.getWeekStr(this.weeks7[u].id)] = this.weeks7[u].id
              this.note5weeks.push(this.getWeekStr(this.weeks7[u].id))
              this.note5weeksMap.push(item)
            }
          }
          this.chosenWeek = this.note5weeks[2]
          let week_id = this.note5weeksMap.find(item => item[this.chosenWeek])
          this.selectedWeek_id = week_id[this.chosenWeek]
          
        }
        )
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
        })
    },

  }, 

  computed: {
    hasValueCustInfo () {
      let resulte = ((this.autocompleteValue !== '') && (this.autocompleteValue !== null))
      return resulte
    },
    group_address_filtered () {
      if (this.addressShowAll) {
        // return this.group_address.filter(address => address.is_main !== 1)
        return this.group_address
      } else {
        return this.group_address.filter(address => address.is_active === 1)
        // return this.group_address.filter(address => address.is_active === 1).filter(address => address.is_main !== 1)
      }
    }, 
    isPlaningCS : function(){ 
        return this.$store.getters.isPlaning
      }, 

    isCashAccountant : function(){ 
      return this.$store.getters.isCashAccountant
     },
  },
}
</script>

<style scoped>

@media (-webkit-min-device-pixel-ratio: 1.15) {
  #wrap-iframe {
    /* width: 80vw; */
    height: 100vh !important;
    /* height: 100%; */
    padding: 0;
    /* overflow: hidden; */
    border-right: 2px solid black;
  }

  #scaled-frame {
    /* width: 80vw; */
    /* height: 94vh !important; */
    border: 0px;
  }

  .absolute-button {
    opacity: 0.5;
    position: fixed;
    height: 5vh;
    width: 5vw;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    text-align: center  ;
    vertical-align: middle;
    z-index: 1000;
    top: 0px;
    left: 95vw;
    padding: 0em;
    background-color: hsl(44, 24%, 66%);
    cursor: pointer;

  }

  .absolute-button-2 {
    /* background-color: #f18973; */
    opacity: 0.5;
    position: fixed;
    height: 5vh;
    width: 5vw;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    text-align: center  ;
    vertical-align: middle;
    z-index: 1000;
    top: 0px;
    left: 17vw;
    padding: 0em;
    background-color: hsl(44, 24%, 66%);
    cursor: pointer;

  }

  .home-side-holder {
    height: 100vh;
  }
  .cust-name-form{
    font-weight: bold;
    color:  #f18973;
    border-right: 1px solid rgb(138, 138, 138);
    border-left: 1px solid rgb(138, 138, 138);
  }
  .weeks-5-items{
    font-weight: bold;
    color: #a0a0a0;
  }

  .weeks-5-items.is-active{
    font-weight: bold;
    color:  #f18973;
  }

  .textarea-note {
    border-radius: 0;
    border-right: 1px solid rgb(138, 138, 138);
    border-left: 1px solid rgb(138, 138, 138);
  }

  .top-addon {
    padding-bottom: 0.25em;
    border: 1px solid rgb(138, 138, 138);
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    border-bottom: 0px !important;
  }

  .bottom-addon {
    border-top: 0px !important;
    border: 1px solid rgb(138, 138, 138);
    border-bottom-right-radius: 1em;
    border-bottom-left-radius: 1em;
    font-weight: bold;
  }

  .bottom-addon.disabled {
    color: rgb(161, 161, 161);
  }

  .form-input.is-active{
    color: #f18973;
  }

  .input-holder{
    /* height: 5vh; */
  }

  .control-item {
    background-color: hsl(44, 24%, 66%);
    border: 1px solid rgb(134, 134, 134);
    /* border-radius: 0.5em; */
  }

  .control-item.is-active{
    background-color: hsl(44, 100%, 77%) !important;
    border: 1px solid black;
    border-bottom: 0px !important;
    /* border-top-left-radius: 0.5em; */
    /* bold: 1px; */
    font-weight: bold;
    border-top-right-radius: 0.5em;
  }


  .note-control-class , .cust_info-control-class, .orders-control-class {
    display: none !important;
  }
  .note-control-class.is-active, .cust_info-control-class.is-active, .orders-control-class.is-active {
    display: flex !important;
  }

  div {
    box-sizing: inherit!important;
  }

  input, textarea{
    /* box-sizing: border-box !important; */
  }


  .autocomplete-results-holder {
    z-index: 10000;
    position: absolute;
    max-height: 25vh !important;
    background-color: white;
    width: 100%;
    overflow-y: auto;
    border: 1px solid rgb(179, 179, 179);
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    
    cursor: pointer;
    overflow-x: wrap;
    padding-bottom: 0.5em;
  }

  .autocomplete-result:hover {
    background-color: hsl(44, 88%, 91%) ;
  }

  .autocomplete-result.is-active {
    background-color: hsl(44, 88%, 91%) ;
  }

  .custAdd-control.is-clickable:hover {
    color: red;
  }

  .custAdd-control.is-disabled {
    color: rgb(161, 161, 161);
  }

  .custinfo_cust_phone-remove {
    position: absolute;
    z-index: 1000;
    padding-left: 1em;
    padding-top: 0.8em;
    cursor: pointer;
    color: red;
  }


  .group_main_info {
    overflow-y: auto;
  }

  .form-input-type-holder.is-size-5 {
    font-size: 1em !important;
  }

  .form-input-channel-holder.is-size-5 {
    font-size: 1em !important;
  }

  .week_id-note-week_str {
    font-size: 1em !important;
  }

  .autocomplete-result.is-size-5 {
    font-size: 1em !important;
  }

  .current-week-notes {
    background-color: #f7cac9 !important;
  }

}

@media (-webkit-max-device-pixel-ratio: 1.15) {
  .current-week-notes {
    background-color: #f7cac9 !important;
  }
  
  #wrap-iframe {
    /* width: 80vw; */
    height: 95vh;
    padding: 0;
    overflow: hidden;
    border-right: 2px solid black;
  }

  #scaled-frame {
    /* width: 80vw; */
    height: 95vh;
    border: 0px;
  }

  .absolute-button {
    position: fixed;
    height: 5vh;
    width: 5vw;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    text-align: center  ;
    vertical-align: middle;
    z-index: 1000;
    top: 0px;
    left: 80vw;
    padding: 0em;
    background-color: hsl(44, 24%, 66%);
    cursor: pointer;

  }

  .absolute-button-2 {
    display: none !important;
  }
  
  .home-side-holder {
    height: 95vh;
  }
  .cust-name-form{
    font-weight: bold;
    color:  #f18973;
    border-right: 1px solid rgb(138, 138, 138);
    border-left: 1px solid rgb(138, 138, 138);
  }
  .weeks-5-items{
    font-weight: bold;
    color: #a0a0a0;
  }

  .weeks-5-items.is-active{
    font-weight: bold;
    color:  #f18973;
  }



  

  .textarea-note {
    border-radius: 0;
    border-right: 1px solid rgb(138, 138, 138);
    border-left: 1px solid rgb(138, 138, 138);
  }

  .top-addon {
    border: 1px solid rgb(138, 138, 138);
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    border-bottom: 0px !important;
  }

  .bottom-addon {
    border-top: 0px !important;
    border: 1px solid rgb(138, 138, 138);
    border-bottom-right-radius: 1em;
    border-bottom-left-radius: 1em;
    font-weight: bold;
  }

  .bottom-addon.disabled {
    color: rgb(161, 161, 161);
  }

  .form-input.is-active{
    color: #f18973;
  }

  .input-holder{
    height: 5vh;
  }

  .control-item {
    background-color: hsl(44, 24%, 66%);
    border: 1px solid rgb(134, 134, 134);
    /* border-radius: 0.5em; */
  }

  .control-item.is-active{
    background-color: hsl(44, 100%, 77%) !important;
    border: 1px solid black;
    border-bottom: 0px !important;
    /* border-top-left-radius: 0.5em; */
    /* bold: 1px; */
    font-weight: bold;
    border-top-right-radius: 0.5em;
  }


  .note-control-class , .cust_info-control-class, .orders-control-class {
    display: none !important;
  }
  .note-control-class.is-active, .cust_info-control-class.is-active, .orders-control-class.is-active {
    display: flex !important;
  }

  div {
    box-sizing: inherit!important;
  }

  input, textarea{
    /* box-sizing: border-box !important; */
  }


  .autocomplete-results-holder {
    z-index: 10000;
    position: absolute;
    max-height: 25vh !important;
    background-color: white;
    width: 100%;
    overflow-y: auto;
    border: 1px solid rgb(179, 179, 179);
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    
    cursor: pointer;
    overflow-x: wrap;
    padding-bottom: 0.5em;
  }

  .autocomplete-result:hover {
    background-color: hsl(44, 88%, 91%) ;
  }

  .autocomplete-result.is-active {
    background-color: hsl(44, 88%, 91%) ;
  }

  .custAdd-control.is-clickable:hover {
    color: red;
  }

  .custAdd-control.is-disabled {
    color: rgb(161, 161, 161);
  }

  .custinfo_cust_phone-remove {
    position: absolute;
    z-index: 1000;
    padding-left: 1em;
    padding-top: 0.8em;
    cursor: pointer;
    color: red;
  }


  .group_main_info {
    overflow-y: auto;
  }

  
}



tr:nth-child(even) {
  background-color: #f0f0f0;
}
</style>

<style>
#table-group-address-home thead {
  display: none;
}

#table-main-note-home thead{
  display: none !important;
}

#table-main-order-list-home thead{
  display: none !important;
}
</style>
