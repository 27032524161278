<template>
  <div class="pl-5 is-flex is-flex-direction-column is-align-items-center" style="height: 90vh; width:65%;">

    <div class="is-four-fifths pt-6 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong> 01. Load Note Đặc Biệt (PLANNING) </strong>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File Note Đặc Biệt
            </a>
          </p>
          <p class="control is-flex-grow-1">
            <input class="input input-control" id="load-note-db" type="file" @change="fileHandle" >
            <span class="has-text-danger" v-bind:innerHTML="errorLoadNoteDB"></span>
          </p>
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='load-note-db')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info"  id="load-note-db-btn"  v-bind:class="{'is-hidden':!(buttonControl==='load-note-db')}" @click="loadNoteDb">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>

    <div class="is-four-fifths pt-3 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong> 02.Check File Planning (PLANNING) </strong>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File Planning
            </a>
          </p>
          <p class="control">
            
          </p>
          
          <p class="control is-flex-grow-1">
            
            <input class="input input-control" id="check-file-planning" type="file"   @change="fileHandle">
            <span class="has-text-danger" v-bind:innerHTML="errorCheckPlaning"></span>
          </p>
          
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='check-file-planning')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info"  id="check-file-planning-btn" v-bind:class="{'is-hidden':!(buttonControl==='check-file-planning')}" @click="uploadCheckPlanning">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>

    <div class="is-four-fifths pt-3 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong> 03.Xử lý Data Planning (PLANNING)</strong>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File Planning
            </a>
          </p>
          <p class="control is-flex-grow-1">
            <input class="input input-control" id="xu-ly-planning" type="file" @change="fileHandle"  >
            <span class="has-text-danger" v-bind:innerHTML="errorXuLyPlaning"></span>
          </p>
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='xu-ly-planning')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info"  id="load-note-db-btn"  v-bind:class="{'is-hidden':!(buttonControl==='xu-ly-planning')}" @click="uploadPlanning">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>

    <div class="is-four-fifths pt-3 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong> 04. Check Payment (CASH ACCOUNTANT)</strong>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File Planning
            </a>
          </p>
          <p class="control">
            
          </p>
          
          <p class="control is-flex-grow-1">
            
            <input class="input input-control" id="check-file-payment" type="file"   @change="fileHandle">
            <span class="has-text-danger" v-bind:innerHTML="errorCheckPayment"></span>
          </p>
          
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='check-file-payment')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info"  id="check-file-payment-btn" v-bind:class="{'is-hidden':!(buttonControl==='check-file-payment')}" @click="uploadCheckPayment">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>

    <div class="is-four-fifths pt-3 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong class="pr-2"> 05. Xử Lý Payment (CASH ACCOUNTANT) </strong> <i>(Có tác dụng tạo customer trên hệ thống - PLANNING sử dụng để tạo customer) </i>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File Planning
            </a>
          </p>
          <p class="control is-flex-grow-1">
            <input class="input input-control" id="xu-ly-payment" type="file"   @change="fileHandle">
            <span class="has-text-danger" v-bind:innerHTML="errorXuLyPayment"></span>
          </p>
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='xu-ly-payment')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info" id="xu-ly-payment-btn" v-bind:class="{'is-hidden':!((buttonControl==='xu-ly-payment'))}" @click="uploadPayment">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>

    <div class="is-four-fifths pt-3 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong> 06. Chốt File Planing và Payment (CASH ACCOUNTANT)</strong>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File Planning
            </a>
          </p>
          <p class="control">
            
          </p>
          
          <p class="control is-flex-grow-1">
            
            <input class="input input-control" id="chot-file" type="file"   @change="fileHandle">
            <span class="has-text-danger" v-bind:innerHTML="errorChotFile"></span>
          </p>
          
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='chot-file')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info"  id="chot-file-btn" v-bind:class="{'is-hidden':!((buttonControl==='chot-file')&&isCashAccountant)}" @click="uploadChotFile">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>

    

    

    <div class="is-four-fifths pt-3 is-flex is-flex-direction-column" style="width: 80%;">
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <strong> 00. Upload Cols Ins </strong>
        </div>
      </div> 
      <div class="field has-addons pr-2 is-flex is-flex-direction-row is-align-content-stretch is-justify-content-center" style="width: 100%;">
        <div class="is-flex is-flex-direction-row" style="width: 100%;">
          <p class="control">
            <a class="button is-static">
              File ColsIns
            </a>
          </p>
          <p class="control">
            
          </p>
          
          <p class="control is-flex-grow-1">
            
            <input class="input input-control" id="file-cols-ins" type="file"   @change="fileHandle">
            <span class="has-text-danger" v-bind:innerHTML="errorColsIns"></span>
          </p>
          
          <p class="control">
            <a class="button is-danger"  v-bind:class="{'is-hidden':!(buttonControl==='file-cols-ins')}"  @click="removeInput">
              Cancel
            </a>
          </p>
          <p class="control">
            <a class="button is-info"  id="file-cols-ins-btn" v-bind:class="{'is-hidden':!(buttonControl==='file-cols-ins')}" @click="uploadColsIns">
              Submit
            </a>
          </p>
        </div>
      </div> 
    </div>
    
    
  </div>
  <div class="pl-3 pt-3 pr-3 is-flex is-flex-direction-column is-align-items-center" style="height: 95vh; width:35%;">
    <div style="width:80%;">
      <button 
        class="button is-info is-fullwidth mb-2" 
        @click="pingPong"
        v-bind:innerHTML="pingCount + ' ping' "
      ></button>
      <div class="mb-5">
        <h1 class="title is-4">Danh sách file ColsIns</h1>
      </div>
      <v-card class="table-container" >
        <v-data-table
          id="table-main"
          :headers="headers"
          :items="colsIns"
          fixed-header
          sortable: false
          items-per-page="50"
          height="60vh"
          
        >
          <template  #item="{ item }">
            <tr  >
              <td class="note-ids is-clickable" sortable=false @click="downloadColsIns"> {{ item.file }}</td>
            </tr>
          </template >
        </v-data-table>

      </v-card>
    </div>
    
  </div>
</template>
<script>
import axios from 'axios'

import { ref } from 'vue'
import { VDataTable,VCard,VTextField,VDataTableVirtual,VFooter,} from 'vuetify/components'

export default {
  name: 'Data Load',
  components: {
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
  },

  data() {
    return {
      // localIsPlanning: false,
      // localIsCashAccountant: false,
      pingCount: 0,
      currentUser: '',
      buttonControl : '',
      errorLoadNoteDB: '',
      errorXuLyPlaning: '',
      errorXuLyPayment: '',
      errorChotFile: '',
      errorColsIns: '',
      errorCheckPlaning: '',
      errorCheckPayment: '',
      colsIns: [],
      headers : [{
            align: 'start',
            key: 'file',
            title: 'File',
            sortable: false,
          }],
    }
  },

  beforeCreate() {
  },

  async beforeMount() {
    
    // check local IsPlaning and IsCashAccountant 
    try {  if ( !((this.isPlaning || this.isCashAccountant)) ) { 
      this.$router.push('/') } } catch (error) {  1 == 1 }

    // check backend IsPlaning and IsCashAccountant 
    await axios
      .get('/api/v1/user/?username=' + localStorage.getItem('user'))
      .then(response => {
        localStorage.setItem('userGroups', response.data['groups'])
        response.data['groups'].includes(1) ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
        response.data['groups'].includes(3) ? this.$store.commit('setIsCashAccountant', true) : this.$store.commit('setIsCashAccountant', false)
        
        if ( !((response.data['groups'].includes(1) || response.data['groups'].includes(3))) 
          ) { 
        this.$router.push('/')
        // console.log('redirect')
        window.location.reload()
        // console.log('reload')
      }
      })
      .catch(error => {          
        if (error.response.status == 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
          this.$router.push('/')
          // console.log('redirect')
          window.location.reload()
          // console.log('reload')
      })  

    


  },

  async mounted() {
    document.title = 'Data Load'
    
    this.currentUser = localStorage.getItem('user')
    // console.log(this.currentUser)
    this.checkSubmitButtons()
    this.getColsIns()
    
    
    
  },

  unmounted() {
    
  },

  updated() {
    // console.log('updated')
    // console.log(event)
    
  },

  methods: {
    wait (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    async pingPong() {
      while (this.pingCount < 300) {
        await axios
        .get('/api/v1/ping-pong/')
        .then((response) => { 
          this.pingCount += 1 
        })
        .catch((error) => { 1==1 })

        await this.wait(50)
      }
      
    },

    async uploadCheckPlanning (){
      var formData = new FormData();
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      this.$store.commit('setIsLoading', true)
      await axios
        .post(
          '/api/v1/check-file-planning/',
          formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data',}
          }
        )
        .then((response) => {
          window.alert('Không phát hiện lỗi nào')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
          this.getColsIns()
        })
        .catch((error) => {
          try {
              if (error.response.status == 599) {
              this.errorCheckPlaning = error.response.data
            } else if (error.response.status >= 500) {
              this.errorCheckPlaning = 'Lỗi không xác định:' + error.response.data
            }
          } catch {
            this.errorCheckPlaning = 'Lỗi không xác định'
          }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },

    async uploadCheckPayment (){
      var formData = new FormData();
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      this.$store.commit('setIsLoading', true)
      await axios
        .post(
          '/api/v1/check-file-payment/',
          formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data',}
          }
        )
        .then((response) => {
          window.alert('Không phát hiện lỗi nào')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
          this.getColsIns()
        })
        .catch((error) => {
          try {
              if (error.response.status == 599) {
              this.errorCheckPayment = error.response.data
            } else if (error.response.status >= 500) {
              this.errorCheckPayment = 'Lỗi không xác định:' + error.response.data
            }
          } catch {
            this.errorCheckPayment = 'Lỗi không xác định'
          }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },

    async uploadColsIns (){
      var formData = new FormData();
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      formData.append('user', this.currentUser);
      this.$store.commit('setIsLoading', true)
      await axios
        .post(
          '/api/v1/cols-ins/',
          formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data',}
          }
        )
        .then((response) => {
          window.alert('Successful')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
          this.getColsIns()
        })
        .catch((error) => {
          try {
              if (error.response.status == 599) {
              this.errorColsIns = error.response.data
            } else if (error.response.status >= 500) {
              this.errorColsIns = 'Lỗi không xác định:' + error.response.data
            }
          } catch {
            this.errorColsIns = 'Lỗi không xác định'
          }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },
    

    async downloadColsIns(event) {
      let actId = event.target.innerHTML
      // console.log(actId)
      // let actFile = this.colsIns.filter((item) => item.id == actId)[0].file
      // window.open('/api/v1/download-cols-ins/' + actFile)
      axios
        .get('/api/v1/cols-ins-download/?file=' + actId, {
          responseType: 'blob',
        })
        .then((response) => {
          // console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', actId)
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          window.alert('Lỗi không download dc')
        })

    },

    removeInput(event) {
      let actId = this.buttonControl
      document.getElementById(actId).value = ''
      this.checkSubmitButtons()
    },
    
    postSlacks(message) {
      console.log(message)
      let form = new FormData()
      form.append('text', message)
      axios
        .post('https://slacks.fitfood.io.vn/hooks/zjoycrxstt8o7g16mygzj3wnhy', form)
        .then((response) => {
          // console.log(response)
        })
        .catch((error) => {
          // console.log(error)
        })
    },

    async uploadPayment (event) {
      var formData = new FormData();
      
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      formData.append('type', 'file');
      formData.append('user', this.currentUser);
      this.$store.commit('setIsLoading', true)
      let message = 'User ' + localStorage.getItem('user') + ' đã upload file Payment: ' + document.getElementById(this.buttonControl).files[0].name
      await axios
        .post(
          '/api/v1/process-payment/',
          formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data',}
          }
          )
        .then((response) => {
          this.postSlacks(message)
          window.alert('Successful')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
          // location.reload()
          
        })
        .catch((error) => {
          try {
              if (error.response.status == 599) {
              this.errorXuLyPayment = error.response.data
            } else if (error.response.status >= 500) {
              this.errorXuLyPayment = 'Lỗi không xác định:' + error.response.data
            }
          } catch {
            this.errorXuLyPayment = 'Lỗi không xác định'
          }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },

    async loadNoteDb (event) {
      var formData = new FormData();
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      formData.append('type', 'file');
      formData.append('user', this.currentUser);
      this.$store.commit('setIsLoading', true)
      let message = 'User ' + localStorage.getItem('user') + ' đã upload file Note Đặc Biệt: ' + document.getElementById(this.buttonControl).files[0].name
      await axios
        .post(
          '/api/v1/load-note-db/',
          formData ,
          { headers: { 'Content-Type': 'multipart/form-data',} }
          )
        .then((response) => {
          this.postSlacks(message)
          window.alert('Successful')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
          
        })
        .catch((error) => {
          try { 
              if (error.response.status == 599) {
                this.errorLoadNoteDB = error.response.data
              } else if (error.response.status >= 500) {
                this.errorLoadNoteDB = 'Lỗi không xác định:' + error.response.data
              }
            } catch {
              this.errorLoadNoteDB = 'Lỗi không xác định'
            }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },
    
    async uploadPlanning (event) {
      var formData = new FormData();
      
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      formData.append('type', 'file');
      formData.append('user', this.currentUser);
      this.$store.commit('setIsLoading', true)
      let message = 'User ' + localStorage.getItem('user') + ' đã upload file Planning: ' + document.getElementById(this.buttonControl).files[0].name
      await axios
        .post(
          '/api/v1/process-planing/',
          formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data',}
          }
          )
        .then((response) => {
          this.postSlacks(message)
          window.alert('Successful')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
          
          
        })
        .catch((error) => {
          try {
              if (error.response.status == 599) {
              this.errorXuLyPlaning = error.response.data
            } else if (error.response.status >= 500) {
              this.errorXuLyPlaning = 'Lỗi không xác định:' + error.response.data
            }
          } catch {
            this.errorXuLyPlaning = 'Lỗi không xác định'
          }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },

    async uploadChotFile (event) {
      var formData = new FormData();
      
      formData.append('file', document.getElementById(this.buttonControl).files[0]);
      formData.append('type', 'file');
      formData.append('user', this.currentUser);
      this.$store.commit('setIsLoading', true)
      await axios
        .post(
          '/api/v1/process-full/',
          formData ,
          {
            headers: {
              'Content-Type': 'multipart/form-data',}
          }
          )
        .then((response) => {
          window.alert('Successful')
          document.getElementById(this.buttonControl).value = ''
          this.checkSubmitButtons()
          this.$store.commit('setIsLoading', false)
        })
        .catch((error) => {
          try {
              if (error.response.status == 599) {
              this.errorChotFile = error.response.data
            } else if (error.response.status >= 500) {
              this.errorChotFile = 'Lỗi không xác định:' + error.response.data
            }
          } catch {
            this.errorChotFile = 'Lỗi không xác định'
          }
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    },


    async getColsIns() {
      await axios
        .get('/api/v1/cols-ins/')
        .then((response) => {
          this.colsIns = response.data
        })
        .catch((error) => {
          // console.log(error)
        })
    },



    checkSubmitButtons() {

      let arrayId = Array.from( ['load-note-db', 'xu-ly-payment', 'xu-ly-planning', 'chot-file','file-cols-ins','check-file-planning','check-file-payment'])
      let countActive = 0
      arrayId.forEach((element) => {
        // console.log(element)
        if (document.getElementById(element).files.length==1) {
          this.buttonControl = element
          countActive += 1
        } 
      })
      
      // console.log(this.buttonControl)

      if (countActive == 0) {
        arrayId.forEach((element) => {
          document.getElementById(element).removeAttribute('disabled')
        })
        this.buttonControl = ''
        this.errorLoadNoteDB = ''
        this.errorXuLyPlaning = ''
        this.errorXuLyPayment = ''
        this.errorChotFile = ''
        this.errorColsIns = ''
      }  

      if (countActive >= 1) {
        arrayId.forEach((element) => {
          if (this.buttonControl != element) {
            document.getElementById(element).setAttribute('disabled', 'disabled')
          }
        })
      }

      

    },  

    fileHandle (event) {

      const fileInput = event.srcElement
      if (fileInput.files.length > 1) {
        window.alert('Too many files')
      }

      if (fileInput.files.length == 1) {
        let cFile = fileInput.files[0]
        if (cFile.name.slice(-4,-1)!='xls') {
          window.alert('Đuôi file chỉ chấp nhận xlsx và xlsb')
          event.srcElement.value = ''
          return
        }
        // console.log('pass xls')
        try {
          let intDate = cFile.name.slice(-13,-5)
          let year = intDate.slice(0,4)
          let monthD = intDate.slice(4,6)
          let dayD = intDate.slice(6,8)
          
          if ((parseInt(year)>2030) || (parseInt(year)<2000)) {
            window.alert('10 ký tự cuối tên file phải là YYYYMMDD yy')  
            event.srcElement.value = ''
            this.checkSubmitButtons()
            return
          }

          if (parseInt(monthD)>12) {
            window.alert('10 ký tự cuối tên file phải là YYYYMMDD mm')  
            event.srcElement.value = ''
            this.checkSubmitButtons()
            return
          }

          if (parseInt(dayD)>31) {
            window.alert('10 ký tự cuối tên file phải là YYYYMMDD dd')  
            event.srcElement.value = ''
            this.checkSubmitButtons()
            return
          }

          if (isNaN(parseInt(intDate))) {
            window.alert('10 ký tự cuối tên file phải là YYYYMMDD')  
            event.srcElement.value = ''
            this.checkSubmitButtons()
            return
          }

          if (parseInt(intDate)<20100000) {
            window.alert('10 ký tự cuối tên file phải là YYYYMMDD')  
            event.srcElement.value = ''
            this.checkSubmitButtons()
            return
          }

          if (parseInt(intDate)>20300000) {
            window.alert('10 ký tự cuối tên file phải là YYYYMMDD')  
            event.srcElement.value = ''
            this.checkSubmitButtons()
            return
          }

          // console.log(parseInt(intDate))

        } catch {
          window.alert('10 ký tự cuối tên file phải là YYYYMMDD')
          event.srcElement.value = ''
          this.checkSubmitButtons()
          return
        }
      }

      this.checkSubmitButtons()
    }
  },

  computed: {
    isPlaning : function(){ 
       
       return this.$store.getters.isPlaning
     },

     isCashAccountant : function(){ 
      
      return this.$store.getters.isCashAccountant
    },
  },
  

}

</script>

<style scoped>
input[type=file]::file-selector-button{
  display: none;
  
}

input {
  /* border-top-right-radius: 0.5em !important;
  border-bottom-right-radius: 0.5em !important; */
}


[disabled].input{
  /* background-color: #d80e0e !important;
  color: #b3b3b3;
  cursor: not-allowed; */
  border: 1px solid #d4d4d4;
}



</style>