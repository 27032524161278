<template>
  <div class="column is-fullheight is-flex is-flex-direction-column is-align-items-stretch p-0 no-flow-y" style="max-width: 100vw; max-height: 100%; height: 95vh!important;">
    <div class="is-flex is-flex-direction-row is-flex-grow-1 no-flow-y" style="max-width: 100%; max-height: 95vh; height: 95vh!important">
      <div class="is-one-fifth column has-background-light is-flex is-flex-direction-column pb-0" style="max-height: 93vh;height: 93vh!important;">
        <div class="cust-controll is-flex is-flex-drirection-row is-justify-content-space-around pb-2">
          <div>
            <a class="button mr-2" id="custinfo" @click="custInfoControl"> Cust Info</a>
          </div>
          <div>
            <a class="button" id="newcust" @click="custInfoControl"> New Cust</a>
          </div>
          <div class="pl-2">
            
            <input type="checkbox"  v-model="load3month" @click="setLocalLoad3month"> 
            <i> P </i>
            
          </div>
        </div>
        <div class="column cust_create_wrapper p-0" v-bind:class="{'is-hidden':!showCreateCust}">
          <div class="column is-flex pl-0 pr-0 pt-1 pb-1 cust_focus_info_title is-flex-grow-0 has-background-warning" style="max-width: 100%;">
            <div class="column has-text-left p-0 pl-2" style="width:100%">
              <p> <strong>CUSTOMER_CREATE</strong></p>
            </div>
            <div class="column has-text-right pt-0 pb-0">
            </div>
          </div>
          <form @submit.prevent="submitForm" class="mt-2">
            <div class="field">
              <label class="label">Customer Phone</label>
              <div class="control">
                <input class="input" type="text" placeholder="customer_phone" id='createCust_cust_phone' autocomplete="nofill" @change="formPhoneChangeCreateForm">
              </div>
            </div>
            <div class="field">
              <label class="label">Customer Name</label>
              <div class="control">
                <textarea class="input" type="text" placeholder="customer_name" id='createCust_cust_name' autocomplete="nofill" style="resize: none;"></textarea>
              </div>
            </div>
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <textarea class="input" type="text"  placeholder="email" id="createCust_cust_email" autocomplete="nofill"  style="resize: none;"></textarea>
              </div>
            </div>
            <div class="field">
              <label class="label">Address</label>
              <div class="control">
                <textarea class="input" style="height: 4rem;" type="text" id='createCust_cust_address' placeholder="customer_address"  > </textarea>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <div class="column" v-if="createCustFormErrors.length">
                  <div class="notification is-danger"  >
                    <p v-for="error in createCustFormErrors" v-bind:key="createCustFormErrors"> {{ error }}</p>
                  </div>
                </div>
                <!-- <button class="button is-primary" @click="submitFormLogin">Login</button> -->
                <div class="is-flex is-justify-content-end">
                  <button class="button is-warning" @click="submitFormCreateCust"> CREATE CUSTOMER </button>
                </div>
                
              </div>
            </div>
          </form>
        </div>
        <div class="column cust_focus_info_wrapper p-0" v-bind:class="{'is-hidden':showCreateCust}">
          <div class="is-flex pl-0 pr-0 pt-1 pb-1 is-flex-direction-row is-justfy-content-stretch pb-0.5 cust_focus_info_title has-background-warning">
            <div class="column has-text-left p-0 pl-2 is-flex-grow-1" style="width:100%">
              <p> <strong>CUSTOMER_INFO</strong></p>
            </div>
            <div class="column has-text-right pt-0 pb-0">
            </div>
          </div>

          <div class="field mb-0 mt-2">

            <div class="control">
              <i class="fa-solid fa-xmark custinfo_cust_phone-remove autocomplete" v-bind:class="{'is-hidden':!hasValueCustInfo}" @click="removeCurrentCustInfoValue"></i>
              <input 
                class="input autocomplete" type="text" placeholder="customer phone" id='custinfo_cust_phone' autocomplete="off" 
                @change="formPhoneChange" 
                v-model="autocompleteValue"
                @input="custInfoInput"
                @focusin="custInfoInput"
                v-on:keydown="custInfoKeyDown"
                >
                <!-- @focusout="custInfoFocusOut" -->
              
              <ul
                v-show="isOpenAutocomplete"
                class="autocomplete-results-holder autocomplete"
              >
              <!-- v-show="isOpenAutocomplete" -->
                <li
                  v-for="(result, i) in autocompleteSuggestionsShow"
                  class="autocomplete-result pl-2 pt-2 is-size-5 autocomplete"
                  @click="clickAutocomplete"
                  :class="[(i === arrowCounter) ? 'is-active' : '' , i+'-autocomplete-stt']"
                >
                {{ result }}
                </li>
              </ul>
                <!-- @update:search="(modelValue) => testChange(modelValue)" -->
            </div>
          </div>
          <div class="column group_main_info" style="height: 27vh;">
            
            <div class="column p-0">
              <span> <strong> Name: </strong>  </span> 
              <span class="group_main_info_name" v-bind:innerHTML="this.custName"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Main Address: </strong>  </span> 
              <span class="group_main_info_address" v-bind:innerHTML="this.custMainAddress"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Main Phone: </strong>  </span>
              <span class="group_main_info_phone" v-bind:innerHTML="this.custMainPhone"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Diet: </strong>  </span>
              <span class="group_main_info_phone" v-bind:innerHTML="this.diets"> 
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Traits: </strong>  </span>
              <span class="group_main_info_phone" v-bind:innerHTML="this.traits"> 
              </span>
            </div>
          </div>
          <!-- start of other ADDRESS -->
          <div class="column is-flex p-0 group_address_title has-background-warning">
            <div class="column has-text-left pt-1 pl-0 pr-0 pb-1 pl-2 is-flex is-flex-direction-row">
              <strong class="is-flex-grow-3">OTHER ADDRESS</strong>
              
              <i class="is-clickable pr-2"
              v-bind:class="[!(this.addressShowAll) ? 'is-hidden' : '']"
              @click="this.addressShowAll = false"
              > showing all</i>

              <i class="is-clickable pr-2"
              v-bind:class="[(this.addressShowAll) ? 'is-hidden' : '']"
              @click="this.addressShowAll = true"
              > showing active</i>

            </div>
          </div>
          <div class="column pl-0 pr-0 pt-0 pb-0 group_address table-address-height" >
            <v-card class="table-container pb-0" >
              <v-data-table-virtual
                id="table-group-address"
                class="table-address-height"
                :headers="headersAddress"
                :items="group_address_filtered"
                fixed-header
                sortable: false
                items-per-page="1000"
                
              >
              <template  #item="{ item }">
                <tr >
                  <td class="hide-add-item" v-bind:class="[
                    (item.is_main) ? 'is-hidden' : '',
                    (item.is_active) ? 'ia-active' : '',
                    (this.addressShowAll) ? 'show-active' : ''
                  ]">
                    <span class=""> {{item.name}}  </span>   
                    <i class="fa-solid pl-2 fa-eject custAdd-control makemain pt-1" 
                      v-bind:id="[item.id+'-cust-address-item']" 
                      v-bind:class="[(!(item.is_main) && isPlaningCS && item.is_active) ? 'is-clickable' :'is-disabled' ]" 
                      @click="custAddressEdit"></i>

                    <i class="fa-regular fa-rectangle-xmark pl-2 custAdd-control deactive pt-1" 
                      v-bind:class="[!(item.is_active) ? 'is-disabled' : 'is-clickable']" 
                      v-bind:id="[item.id+'-cust-address-item']" 
                      @click="custAddressEdit"></i>

                    <i class="fa-regular fa-square-check pl-2 custAdd-control activate pt-1" 
                      v-bind:class="[(item.is_active) ? 'is-disabled' : 'is-clickable']" 
                      v-bind:id="[item.id+'-cust-address-item']" 
                      @click="custAddressEdit"></i> 
                    
                    
                    <br>
                    <span class=""> {{item.phone_number_readable}}  </span> <br>
                     {{ item.address_detail }} 
                 
                  </td>
                </tr>
              </template >

              </v-data-table-virtual>

            </v-card>

          </div>
          <!-- end of customer address -->
        
        </div>
      </div>
      <div class="is-three-fifths is-flex column is-flex-grow-1" >
        <div class="is-flex is-flex-direction-column is-flex-grow-1" style="max-width: 100%; ">
          <div class="history-holder is-flex-grow-2 mb-1" style="max-height: 38vh; min-height: 38vh; height: 38vh !important">
              <div class="week-holder template-week is-hidden" style="max-height: 100%;">
                  <div class="week-holder-title is-size-5 has-text-centered is-clickable is-flex is-flex-direction-row" style="display: inline-block;"> Week: <strong class="weekStr"> Template </strong>
                  </div>
                  <div class="week-holder-inner" style="max-height: 100%;">
                    <div class="orderpacks-holder is-flex-grow-1 template-package">
                      <div class="orderpack-detail">
                        <div class="orderpack-detail-title is-clickable" >
                          <div class="orderpack-detail-title-line">
                            <strong class="pack-id"> 3334 </strong>
                            <i>/</i>
                            <strong class="pack-alias"> FIT1 </strong>
                            <i>/</i>
                             <strong class="totalMeals"> </strong><strong>meals</strong>
                            <i>/</i>
                            <strong class="shipLoc"> Quận 8 </strong>
                          </div>
                        </div>
                        <div class="orderpack-detail-contents is-flex is-flex-direction-row is-clickable" >
                          <div class="mon" >
                            <i>mon<br></i>
                            <i>SA:<strong class="SA"></strong> <br></i>
                            <i>TR:<strong class="TR"></strong> <br></i>
                            <i>TO:<strong class="TO"></strong> <br></i>
                            <i>KH:<strong class="O"></strong> <br></i>
                          </div>
                          <div class="tue">
                            <i>tue<br></i>
                            <i>SA:<strong class="SA"></strong> <br></i>
                            <i>TR:<strong class="TR"></strong> <br></i>
                            <i>TO:<strong class="TO"></strong> <br></i>
                            <i>KH:<strong class="O"></strong> <br></i>
                          </div>
                          <div class="wed">
                            <i>wed<br></i>
                            <i>SA:<strong class="SA"></strong> <br></i>
                            <i>TR:<strong class="TR"></strong> <br></i>
                            <i>TO:<strong class="TO"></strong> <br></i>
                            <i>KH:<strong class="O"></strong> <br></i>
                          </div>
                          <div class="thu">
                            <i>thurs<br></i>
                            <i>SA:<strong class="SA"></strong> <br></i>
                            <i>TR:<strong class="TR"></strong> <br></i>
                            <i>TO:<strong class="TO"></strong> <br></i>
                            <i>KH:<strong class="O"></strong> <br></i>
                          </div>
                          <div class="fri">
                            <i>fri<br></i>
                            <i>SA:<strong class="SA"></strong> <br></i>
                            <i>TR:<strong class="TR"></strong> <br></i>
                            <i>TO:<strong class="TO"></strong> <br></i>
                            <i>KH:<strong class="O"></strong> <br></i>
                          </div>
                        </div>
                        <div class="orderpack-detail-title-after is-clickable" >
                          <div class="orderpack-detail-title-after-line-1">
                            <i class="noteDBArrText is-lowercase">KO CÀ CHUA BI</i>
                          </div>
                          <div class="orderpack-detail-title-after-line has-text-right">
                            <i class="idStr"> 17657-19578|MQBHP0 </i>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="week-holder time-holder is-hidden" style="max-height: 100%;">
                  <div class="week-holder-title is-size-5 has-text-centered is-flex is-flex-direction-row" style="display: inline-block;"> <i> <strong class="numweek"> </strong>  weeks </i>
                  </div>
              </div>
          </div>
          <div class="is-flex is-flex-direction-row is-flex-grow-5" style="height: 53vh;">
            <div class="detail-holder-info is-align-items-stretch is-flex-grow-4" style="max-height: 100%; width: 100%;">
              <div class="detail-holder-info-controller is-flex is-flex-direction-row">
                <div class="pr-3 pl-2 control-item is-clickable is-active" @click="showControl" id="orderList"> Order List  </div>
                <div class="pr-3  pl-2 control-item is-clickable " @click="showControl" id="info"> Info  </div>
                <div class=" pr-2 control-item is-clickable pl-2" @click="showControl" id="details"> Details  </div>
                <div class="pr-3 pl-2 control-item is-clickable " @click="showControl" id="customerBalance"> Customer Balance  </div>
              </div>
              <div class="has-background-light detail-holder-order-list is-flex is-flex-direction-row is-flex-grow-1 is-align-items-stretch orderList-control-class is-active" style="width: 100%;max-height: 50vh;">
                <div class="detail-holder-order-list-part-1 pl-2 pt-2 is-flex-grow-1 is-flex is-flex-direction-column pb-3" style="max-width: 100%; max-height: 50vh;">
                  <div class="is-flex is-flex-direction-row is-justify-content-space-between is-flex-grow-1 pb-1 " style="width:100%;">
                    <div class="is-flex is-flex-direction-row" style="overflow-x: hidden; max-width: 19vw;">
                      <strong class="is-size-5">Danh sách order của khách</strong>
                    </div>
                    <div class="is-flex-grow-1 pl-3" style="overflow-x: hidden; max-width: 19vw;">
                      <div>
                        <button class="button" v-bind:disabled="!(this.selectedforInBill.length>0)" @click="clearSelectedInBill"> clear </button>
                        <button class="button ml-2" v-bind:disabled="!(this.selectedforInBill.length>0)" @click="sendInBill"> Bill Chi Tiết </button>
                      </div>
                    </div>
                    <div class="field has-addons" style="overflow-x: hidden; max-width: 19vw;">
                      <div class="control ">
                        <a class="button is-static">
                          <i class="fa-solid fa-reply-all is-clickable" aria-hidden="true" @click="deFilterOrderList"></i>
                        </a>
                      </div> 
                      <div class="control">
                        <input class="input de-filter-order-list" type="text" placeholder="Search complete input" v-model="search">
                      </div>
                      <div class="control">
                        <a class="button is-static">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                      </div>
                      
                    </div>
                  </div>
                  <div class="is-flex is-flex-direction-row is-justify-content-space-between is-flex-grow-1 pr-2 " style="width:100%">
                    <v-card class="table-container is-flex-grow-1 table-main-order-list-height"  >
                      <v-data-table-virtual
                        id="table-main-order-list"
                        class="table-main-order-list-height"
                        :headers="headers"
                        :items="orders"
                        :search="search"
                        :custom-filter="customSearch"
                        fixed-header
                        sortable: false
                        items-per-page="1000"
                      >
                        <template   #item="{ item }" >
                          
                          <tr v-bind:id="[item.id+'-order-list']" class="row-order-list "  
                          v-bind:class="[ (item.id === this.selectedPackId) ? 'is-active' : '']">
                            <td> 
                              <input type="checkbox" v-model="selectedforInBill" :value="item.id" @change="testMulti">
                            </td>
                            <td class="is-clickable" @click="clickRowOrderList"> {{ item.week_str }}</td>
                            <td class="is-clickable" @click="clickRowOrderList"> {{ item.packageLocalId }}</td>
                            <td class="is-clickable" @click="clickRowOrderList"> {{ 
                            (item.ycdb2 === 'Thêm phần ăn sáng dành cho gói MEAT LOVER') ? item.packAlias.replace('MEAT', 'MEAT-S') : item.packAlias }}</td>
                            <td class="is-clickable" @click="clickRowOrderList"> {{ (item.shipLoc.indexOf('(') >-1) ? item.shipLoc.slice(0,item.shipLoc.indexOf('(')) : item.shipLoc}}</td>
                            <td class="has-text-right is-clickable" @click="clickRowOrderList"> {{ this.formatPriceRound( item.finalAmt ) }}</td>
                            <td class="is-clickable" @click="clickRowOrderList"> {{ item.customerNote }}</td>
                          </tr>
                        </template >

                      </v-data-table-virtual>

                    </v-card>
                  </div>
                  
                </div>
              </div>
              <div class="has-background-light detail-holder-info-order-info is-flex is-flex-direction-row info-control-class" style="max-height: 50vh;" >
                <div class="order-info-part-1 pl-2 pt-2 is-flex-grow-5 is-flex is-flex-direction-column pb-3" style="max-width: 60%; max-height: 50vh;">
                  <div class="mb-1"> <strong> Thông tin gói ăn</strong>  </div>
                  <div class="is-flex is-flex-direction-column"> 
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText"> Pack: </div> <div class="custText"><strong class="packAlias-pack-info to-reset-inner-html"></strong> 
                        / <strong class="week_str-pack-info to-reset-inner-html"></strong>
                        : <strong class="packageLocalId-pack-info to-reset-inner-html"></strong>  
                        / <strong class="totalMeals-pack-info to-reset-inner-html"></strong> <strong>meals</strong>  </div>
                    </div>
                  </div>
                  <div>
                    <div class="mb-1"> <strong> Yêu cầu món ăn</strong>  </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText"> ycdb: </div> <div class="custText"><i class="ycdb-pack-info to-reset-inner-html"></i></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText"> ycdb2: </div> <div class="custText"><i class="ycdb2-pack-info to-reset-inner-html"></i></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText is-flex is-flex-direction-row nowrap nowrap-toogle" > Ghi Chú Phần Ăn: </div> <div class="custText"><i class="customerNote-pack-info to-reset-inner-html"></i></div>
                    </div>
                  </div>
                  <div>
                    <div class="mb-1"> <strong> Yêu cầu ship </strong>  </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText"> shipLoc: </div> <div class="custText"><i class="shipLoc-pack-info to-reset-inner-html"></i></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText"> Address: </div> <div class="custText"><i class="address-pack-info to-reset-inner-html"></i></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText nowrap nowrap-toogle" > Note 2 hàng tuần: </div> <div class="custText"><i class="noteShipThisWeek-pack-info to-reset-inner-html"></i></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custText-preText nowrap nowrap-toogle" > Note 1 cố định: </div> <div class="custText"><i class="noteShipping-pack-info to-reset-inner-html"></i></div>
                    </div>
                  </div>
                </div>
                <div class="order-info-invoice pl-2 pt-2 is-flex-grow-1">
                  <div class="mb-1"> <strong> Chi Tiết Thành Tiền </strong>  </div>
                  <div class="is-flex is-flex-direction-column price-holder">
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custPrice-preText name-cust-price"> 
                        <div class="pr-1">Khách hàng:</div> <strong class="customerName-pack-price to-reset-inner-html pr-1"> </strong> 
                        (tuần <strong class="week_str-pack-price to-reset-inner-html"> </strong>)
                      </div> 
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custPrice-preText"> 
                        <strong class="packAlias-pack-price pr-1"></strong> ( <div class="totalMeals-pack-price to-reset-inner-html"></div>meals) 
                      </div> 
                      <div class="custPrice"><div class="packAmt-pack-price to-reset-inner-html"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row specialAmt-adj is-hidden">
                      <div class="custPrice-preText"> <p> Phí dịch vụ ycdb :</p>   </div> <div class="custPrice"><div class="specialAmt-pack-price to-reset-inner-html"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row otherAmt-adj is-hidden">
                      <div class="custPrice-preText"> <div class="otherDesc-pack-price" disabled>  </div> </div> <div class="custPrice"><div class="otherAmt-pack-price"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row side-order-details is-hidden">
                      <div class="custPrice-preText"> <div class="side-order-name" disabled> SIDE </div> 
                        ( <div class="side-order-quant" disabled> </div>  x) 
                      </div> 
                      <div class="custPrice"><div class="side-order-price"></div>
                      </div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row side-order-details-adj is-hidden">
                      <div class="custPrice-preText"> <div class="side-order-name" disabled> Điều chỉnh giá sản phẩm phụ </div> </div> <div class="custPrice"><div class="side-order-price"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row ship-price-details-adj is-hidden">
                      <div class="custPrice-preText"> <div class="shipLoc-ship-item" disabled> Điều chỉnh giá ship </div> </div> <div class="custPrice"><div class="ship-price-ship-item"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row ship-details-this-order is-hidden">
                      <div class="custPrice-preText"> <div disabled class="pr-1"> Ship </div>  <div class="shipLoc-ship-item pr-1" disabled> </div> (<div class="shipLoc-quant-ship-item" disabled> </div> days)  </div> 
                      <div class="custPrice"><div class="ship-price-ship-item"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row ship-details-not-this-order is-hidden">
                      <div class="custPrice-preText"> <div disabled class="pr-1"> Ship </div>  <div class="shipLoc-ship-item pr-1" disabled> </div> (<div class="shipLoc-quant-ship-item" disabled> </div> days) (theo ID: <div class="packageLocalId-ship-item" disabled> </div>)  </div> 
                      <div class="custPrice"><div class="ship-price-ship-item"></div></div>
                    </div>
                    <div class="line-break mb-1">
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custPrice-preText"> <p> Tổng giá:</p>   </div> <div class="custPrice"><div class="totalBeforeDiscount-pack-price to-reset-inner-html"></div></div>
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custPrice-preText"> <p class="pr-1"> Discount:</p>  <div class="discountCode-pack-price"></div> </div> <div class="custPrice"><div class="discountAmt-pack-price to-reset-inner-html"></div></div>
                    </div>
                    <div class="line-break-2 mb-1">
                    </div>
                    <div class="order-info-item-line is-flex is-flex-direction-row">
                      <div class="custPrice-preText"> <p> Thành tiền:</p>   </div> <div class="custPrice"><div class="finalAmt-pack-price to-reset-inner-html"></div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="has-background-light detail-holder-with-ship is-flex is-flex-direction-row is-flex-grow-1 is-align-items-stretch details-control-class" style="width: 100%;max-height: 50vh;">
                <div class="is-flex is-flex-direction-column is-align-items-stretch p-2 is-flex-grow-1" >
                  <div class="has-background-light is-flex is-flex-direction-row"> 
                    <div class="is-size-5">
                      <strong>Thông tin chi tiết các package trong tuần</strong> <strong v-bind:innerHTML="quickWeekStr"> </strong>
                    </div>
                  </div>
                  <div class="details-with-ship-holder is-flex is-flex-direction-row is-align-items-stretch" style="height:50vh">
                    <div class="pack-table-holder is-flex is-flex-direction-column is-align-items-stretch is-flex-grow-1">
                      <div  class="pack-table-tbody template-package-table is-flex is-flex-direction-row is-align-items-stretch is-hidden">
                        <div class="pack-table-title is-flex is-flex-direction-column is-align-items-stretch">
                          <div class="is-flex-grow-2">
                          </div>
                          <div class="is-flex-grow-1 id-pack-table pl-2 has-text-weight-bold"> 
                          </div>
                          <div class="is-flex-grow-1 packageLocalId-pack-table pl-2 has-text-weight-bold"> 
                          </div>
                          <div class="is-flex-grow-2">
                          </div>
                        </div>
                        <div class="pack-table-details" >
                          <div class="pack-table-details-title is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1" >
                            <div class="pack-table-cell"></div>
                            <div class="pack-table-cell-2"><strong>mon</strong></div>
                            <div class="pack-table-cell-2"><strong>tue</strong></div>
                            <div class="pack-table-cell-2"><strong>wed</strong></div>
                            <div class="pack-table-cell-2"><strong>thurs</strong></div>
                            <div class="pack-table-cell-2"><strong>fri</strong></div>
                          </div>
                          <div class="pack-table-SA is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">SA</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-TR is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">TR</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-TO is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">TO</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-O is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">KH</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-shipDis is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">shipLoc</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-deliverTimeslot is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">time</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-shipper is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">shipper</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                          <div class="pack-table-address is-flex is-flex-direction-row is-align-items-stretch is-flex-grow-1">
                            <div class="pack-table-cell">address</div>
                            <div class="day-2 pack-table-cell-2 day-2"></div>
                            <div class="day-3 pack-table-cell-2 day-3"></div>
                            <div class="day-4 pack-table-cell-2 day-4"></div>
                            <div class="day-5 pack-table-cell-2 day-5"></div>
                            <div class="day-6 pack-table-cell-2 day-6"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="has-background-light detail-holder-customer-balance is-flex is-flex-direction-row is-flex-grow-1 is-align-items-stretch customerBalance-control-class" style="width: 100%;max-height: 50vh;">
                <div class="detail-holder-transaction-part-1 pl-2 pt-2 is-flex-grow-1 is-flex is-flex-direction-column  pb-3" style="max-width: 100%; max-height: 50vh;">
                  <div class="is-flex is-flex-direction-row is-justify-content-space-between is-flex-grow-1 " style="width:100%">
                    <div>
                      <strong class="is-size-5">Số dư khách hàng trong 1 năm qua</strong> 
                    </div>
                    
                    <div class="field has-addons">
                      <div class="control ">
                        <a class="button is-static">
                          <i class="fa-solid fa-reply-all is-clickable" aria-hidden="true" @click="deFilterSearchTransaction"></i>
                        </a>
                      </div> 
                      <div class="control">
                        <input class="input" type="text" placeholder="Search transaction" v-model="searchTransactions">
                      </div>
                      <div class="control">
                        <a class="button is-static">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                      </div>
                      
                    </div>
                  </div>
                  <div class="is-flex is-flex-direction-row is-justify-content-space-between is-flex-grow-1 pr-2" style="width:100%">
                    <v-card class="table-container is-flex-grow-1 table-transaction-height">
                      <v-data-table-virtual
                        id="table-transactions"
                        class="table-transaction-height"
                        :headers="headersTransactions"
                        :items="transactions"
                        :search="searchTransactions"
                        :custom-filter="customSearchTransaction"
                        fixed-header
                        density="compact"
                        sortable: false
                        items-per-page="1000"
                        width="100%"
                      >
                        <template   #item="{ item }" style="height: 40vh;">
                          <tr 
                          v-bind:class="[ (item.order_package_id === this.selectedPackId) ? 'is-active' : '', (item.order_package_id != 'na') ? 'is-clickable' : '']"
                          v-bind:id="[item.order_package_id+'-transaction-list']" class="row-transactions-list"
                          
                          @click="clickRowTransactionList" 
                          >
                            <td> {{ item.number }}</td>
                            <td> {{ item.transaction_date }}</td>
                            <td> {{ this.getWeekStr(item.week_id) }}</td>
                            <td class="has-text-right"> {{ this.formatPriceRound(item.amount_dr) }}</td>
                            <td class="has-text-right"> {{ this.formatPriceRound(item.amount_cr) }}</td>
                            <td  class="has-text-right"> {{ this.formatPriceRound(item.balance) }}</td>
                            <td> {{ item.description }}</td>
                          </tr>
                        </template >

                      </v-data-table-virtual>

                    </v-card>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="is-one-fifth is-flex-grow-1 has-background-light is-flex is-flex-direction-column is-justify-content-start note-holder" style="max-width: 20%; height: 93vh !important; width: 20%;">
        <div class="is-flex is-flex-direction-column p-2">
          <div class="is-flex-grow-1 is-flex is-flex-direction-column">
            <div class="top-addon pl-2 pr-2 pb-1">
              <div class=" is-flex is-flex-direction-row pt-2 form-icon-line" >
                <div class="pl-2 pb-0" style="width: 17%;">
                  Week
                </div>
                <div class="pl-2 is-flex is-flex-direction-row is-size-5 form-input-type-holder is-align-items-center" style="width: 50%;">
                  
                  <i class="fa-solid fa-basket-shopping has-text-centered is-active form-input form-input-type is-clickable order" @click="formInputIcon" style="width: 31%;"></i>
                  <i class="fa-solid fa-credit-card  has-text-centered  form-input form-input-type is-clickable payment" @click="formInputIcon" style="width: 31%;"></i>
                  <i class=" has-text-centered " style="width: 31%;"></i>
                  
                </div>
                <div class="is-flex is-flex-direction-row pl-2 form-input-channel-holder is-size-5 is-align-items-center" style="width: 32%;">
                  <i class="fa-solid fa-square-phone-flip  has-text-centered form-input is-active form-input-channel is-clickable phone" @click="formInputIcon" style="width: 50%;"></i>
                </div>
              </div>
              <div class="is-flex is-flex-direction-row pb-0 form-icon-line" >
                <div class="pl-2 pt-0 pb-0" style="width: 17%;">
                  <strong class="week_id-note-week_str pb-0 pt-0"> </strong>
                </div>
                <div  class="is-flex is-flex-direction-row pl-2  is-size-5 form-input-type-holder is-align-items-center" style="width: 50%;">
                  <i class="fa-solid fa-motorcycle  has-text-centered  form-input form-input-type is-clickable ship" @click="formInputIcon" style="width: 31%;"></i>
                  <i class="fa-solid fa-ban  has-text-centered  form-input form-input-type is-clickable cancel" @click="formInputIcon" style="width: 31%;"></i>
                  <i class="fa-solid fa-circle-question  has-text-centered  form-input form-input-type is-clickable other" @click="formInputIcon" style="width: 31%;"></i>
                  
                </div>
                <div style="width: 32%;" class="is-flex is-flex-direction-row pl-2 form-input-channel-holder is-size-5 is-align-items-center">
                  <i class="fa-brands fa-square-facebook  has-text-centered form-input form-input-channel is-clickable facebook" @click="formInputIcon" style="width: 50%;"></i>
                  <i class="fa-solid  fa-square-minus  has-text-centered form-input form-input-channel is-clickable other" @click="formInputIcon" style="width: 50%;"></i>
                </div>
              </div>
            </div>
            <textarea class="textarea has-fixed-size textarea-note" placeholder="note description" @input="evaluateCreateNote"></textarea>
            <div class="bottom-addon is-flex is-flex-direction-row is-justify-content-center p-2 is-clickable disabled" id="button-create-note" @click="submitCreateNote" >
              CREATE <i class="week-chonsen-check-create-note pl-2"> </i>
            </div>
          </div>
        </div>
        <div class="pl-2 is-flex is-flex-direction-row pb-1" style="height: 5vh;">
          <div>
            <input type="checkbox" v-model="autoInsertWeek" class="ml-1 mr-1" @click="setAutoInsertWeek">
          </div>
          
          <div class="field has-addons is-justify-content-end pr-0" style="width:90%">
            <div class="control ">
              <a class="button is-static">
                <i class="fa-solid fa-reply-all is-clickable" aria-hidden="true" @click="deFilterNotes"></i>
              </a>
            </div> 
            <div class="control">
              <input class="input" type="text" id="note-search-input" placeholder="Search Note" v-model="searchNote">
            </div>
            <div class="control">
              <a class="button is-static">
                <i class="fa fa-search" aria-hidden="true"></i>
              </a>
            </div> 
          
          </div>  
        </div>
        
        <div class="is-flex-grow-1  p-2" style="height: 65vh;">
          <v-card class="table-container note-holder-scroll table-note-height" >
            <v-data-table-virtual
              id="table-main-note"
              class="table-note-height"
              :headers="headersNote"
              :items="notes"
              :search="searchNote"
              :custom-filter="customSearchNote"
              fixed-header
              sortable: false
              items-per-page="1000"
              
            >
            <template  #item="{ item }">
              <tr >
                
                <td v-bind:class="[(item.is_completed==1) ? 'completed-note' : 'pending-note',
                  item.week_id+'-note-week_id',
                  (this.weeks[2]['id'] === item.week_id) ? 'current-week-notes' : ''
                  
                ]">  
                  <strong>{{ item.weekStr +' '}} </strong>
                  {{ item.description }}
                  <i> ({{ item.created_by_id +' | '  }} {{ item.created.slice(5,10).replace('-','') }}:{{ item.created.slice(11,16).replace(':','') }})</i>
                  <strong> ({{  item.is_completed_string}})</strong>
                </td>
              </tr>
            </template >

            </v-data-table-virtual>

          </v-card>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import axios from 'axios'

import { VDataTable,VCard,VTextField,VDataTableVirtual,VFooter,VAutocomplete,VCheckbox} from 'vuetify/components'
export default {
  name: 'OrderHistView',
  components: {
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
    VAutocomplete,
    VCheckbox
    
  },

  data() {
    return {
      selectedforInBill: [],
      urlPassedWeek: 0,
      custName: '',
      custMainAddress: '',
      custMainPhone: '',
      traits: '',
      diets: '',
      custId : null,
      weeks : [],
      orders : [],
      notes : [],
      load3month: true,
      group_address : [],
      selectedWeek_id : 0,
      currentUser : 'UNS',
      selectedPackId : '',
      selectedPackIdLocal : '',
      createCustFormErrors: [],
      showCreateCust : false,
      transactions : [],
      addressShowAll : false,
      mapDay: {
        2: 'mon',
        3: 'tue',
        4: 'wed',
        5: 'thu',
        6: 'fri',
      },
      search : '',
      searchNote : '',
      autoInsertWeek : true,
      searchTransactions : '',
      autocompleteSuggestions : [],
      autocompleteSuggestionsShow : [],
      autocompleteValue : '',
      arrowCounter: -1,
      threeMonthsAgoWeekId : 0,
      isOpenAutocomplete : false,
      headersNote : [
        {
          align: 'start',
          key: 'description',
          title: 'description',
          sortable: false,
        },
      ],
      headers : [
            {
            align: 'start',
            key: 'id',
            title: '',
            sortable: false,
          },
          {
            align: 'start',
            key: 'week_str',
            title: 'weekStr',
            sortable: false,
          },
          {
            align: 'start',
            key: 'packageLocalId',
            title: 'id(Week)',
            sortable: false,
          },
          {
            align: 'start',
            key: 'packAlias',
            title: 'pack',
            sortable: false,
          },
          {
            align: 'start',
            key: 'shipLoc',
            title: 'shipLoc',
            sortable: false,
          },
          {
            align: 'start',
            key: 'finalAmt',
            title: 'Total',
            sortable: false,
          },
          {
            align: 'start',
            key: 'customerNote',
            title: 'Ghi Chú Phần Ăn',
            sortable: false,
          },
          
        ],
      headersTransactions : [
        {
          align: 'start',
          key: 'number',
          title: 'STT',
          sortable: true,
        },
        {
          align: 'start',
          key: 'transaction_date',
          title: 'date',
          sortable: false,
        },
        {
          align: 'start',
          key: 'week_id',
          title: 'week',
          sortable: false,
        },
        {
          align: 'start',
          key: 'amount_dr',
          title: 'Tiền_ăn',
          sortable: false,
        },
        {
          align: 'start',
          key: 'amount_cr',
          title: 'Tiền_trả',
          sortable: false,
        },
        {
          align: 'start',
          key: 'balance',
          title: 'Thiếu_(dư)',
          sortable: false,
        },
        {
          align: 'start',
          key: 'description',
          title: 'Description',
          sortable: false,
        },
        
      ],
      headersAddress : [
          {
            align: 'start',
            key: 'address_detail',
            title: '',
            sortable: false,
          },
      ]
    }
  },

  methods: {
    sendInBill () {
      const routeData = this.$router.resolve( {name: 'InBill', query: {
                                                                              customer_id: this.custId ,
                                                                              packs: this.selectedforInBill.join(';')
                                                                              }}
                                                        );
      window.open(routeData.href, '_blank');

      this.clearSelectedInBill()

    },
    clearSelectedInBill () {
      this.selectedforInBill = []
    },
    testMulti (event) {
      // console.log(this.selectedforInBill)
      1==1
    },
    
    removeCurrentCustInfoValue (event) {
      this.autocompleteValue = ''
      this.filterAutocompleteResults();
      this.isOpenAutocomplete = true;
      // 
      // console.log(this.autocompleteSuggestionsShow)
      // console.log(this.isOpenAutocomplete)
      document.getElementById('custinfo_cust_phone').focus()
      this.resetCustInfo()

      // document.getElementById('custinfo_cust_phone').dispatchEvent(new Event('input', { bubbles: true }))
    },

    clickAutocomplete (event) {
      // console.log('clickAutocomplete')
      // console.log(event.target)

      this.formPhoneChange( {srcElement: {value: event.target.innerText}} , true)
      
    },

    handleClick (event) {
      // console.log(event.target.id)
      try {
        if (!(event.target.classList.contains('autocomplete'))) {
          
          if (event.target.id === 'custinfo_cust_phone') {
            1==1
          } else {
            this.arrowCounter = -1
            document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({block: "nearest"});
            this.isOpenAutocomplete = false
                
            
          }
        } 


      } catch (error) {
       1==1
      }
      
    },
    
    custInfoKeyDown (event) {
      // console.log('custInfoFocusOut')
      // console.log(event)
      if ((event.key === 'Tab') && (this.isOpenAutocomplete)) {

        // document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({block: "nearest"});
        this.isOpenAutocomplete = false
        this.arrowCounter = -1;
      }

      if ((event.key === 'Tab')) {
        document.getElementsByClassName('de-filter-order-list')[0].focus()
      }


      if (event.key === 'ArrowDown') {
        this.isOpenAutocomplete = true;
        this.filterAutocompleteResults();
        // document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
        this.arrowCounter = this.arrowCounter + 1
        
        if (this.arrowCounter >= this.autocompleteSuggestionsShow.length) {
          this.arrowCounter = 0
          
        }
        document.getElementsByClassName(this.arrowCounter+'-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
      }

      if (event.key === 'ArrowUp') {
        this.arrowCounter = this.arrowCounter - 1
        if (this.arrowCounter < 0) {
          this.arrowCounter = this.autocompleteSuggestionsShow.length - 1
        }
        document.getElementsByClassName(this.arrowCounter+'-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
      }

      if (event.key === 'Enter') {
        if (this.arrowCounter > -1) {
          this.autocompleteValue = this.autocompleteSuggestionsShow[this.arrowCounter]
          this.formPhoneChange( {srcElement: {value: this.autocompleteValue}}, true)
        }
      }
      
      
    },

    custInfoInput (event) {
      this.filterAutocompleteResults();
      this.isOpenAutocomplete = true;
      this.arrowCounter = -1;
      // document.getElementsByClassName('0-autocomplete-stt')[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    },

    filterAutocompleteResults() {
      this.autocompleteSuggestionsShow = this.autocompleteSuggestions.filter(item => 
        item.replaceAll('-','').toLowerCase().indexOf(this.autocompleteValue.replaceAll('-','').toLowerCase()) > -1
        );
    },
    
    setAutoComplete (stringPassed) {
      
      if (stringPassed === 'init') {
        try {
          // console.log('init')
          // console.log(localStorage.getItem('searchHist'))
          // console.log(localStorage.getItem('searchHist').split('|||'))
          // console.log(this.autocompleteSuggestions.concat(localStorage.getItem('searchHist').split('|||')))
          this.autocompleteSuggestions = localStorage.getItem('searchHist').split('|||').concat(this.autocompleteSuggestions)
          this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
        } catch (error) {
          1==1
        }
        return
      }
      // localStorage.removeItem('searchHist')
      // console.log(stringPassed)
      let localSearchHist = ''
      try {
        localSearchHist = localStorage.getItem('searchHist')
        // console.log('localSearchHist')
        // console.log(localStorage)
        // console.log(localSearchHist)
        
      } catch (error) {
        localSearchHist = ''
      }
      
      // first time set
      if ((localSearchHist === '') || (localSearchHist === null)){
        // console.log('localSearchHist is empty')
        localStorage.setItem('searchHist', stringPassed)
        
        this.autocompleteSuggestions = [stringPassed].concat(this.autocompleteSuggestions)

        this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
        return
      } 


      let curResult = stringPassed.split(':')
      // console.log('curResult')
      // console.log(curResult)
      // existed return
      if (localSearchHist.indexOf(stringPassed)>-1) {
        localSearchHist = localSearchHist.replaceAll(stringPassed+'|||', '')
        let newSearchHist = stringPassed + '|||' + localSearchHist 
        localStorage.setItem('searchHist', newSearchHist)
        this.autocompleteSuggestions = localStorage.getItem('searchHist').split('|||').concat(this.autocompleteSuggestions)
        this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
        return
      } 

      let newSearchHist = stringPassed + '|||' + localSearchHist 
      localStorage.setItem('searchHist', newSearchHist)
      let arraySuggest = newSearchHist.split('|||')

      

      if (arraySuggest.length > 100) {
        arraySuggest = arraySuggest.slice(0,99)
      }
      localStorage.setItem('searchHist', arraySuggest.join('|||'))

      this.autocompleteSuggestions = newSearchHist.split('|||').concat(this.autocompleteSuggestions)
      this.autocompleteSuggestions = this.autocompleteSuggestions.filter((v, i, a) => a.indexOf(v) === i)
      // console.log('arraySuggest')
      
      
      // console.log('arraySuggestions')
      // console.log(arraySuggest)
      // console.log(arraySuggest[0])
      // console.log(arraySuggest[0].indexOf('|'))
      // console.log(arraySuggest.join('|||').replaceAll('|||', ''))

      if (arraySuggest.join('|||').replaceAll('|||', '').indexOf('|')>-1) {

        localStorage.removeItem('searchHist')
        // this.autocompleteSuggestions = []

      }
    },

    formPhoneChangeCreateForm (event) {
      
      let myString = event.srcElement.value
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      document.getElementById('createCust_cust_phone').value = myString
    },
    
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    custInfoControl (event) {

      if (event.srcElement.id === 'custinfo') {
        this.showCreateCust = false
      } else {
        this.showCreateCust = true
      }
      
    },
    
    resetCustCreateForm () {
      document.getElementById('createCust_cust_name').value = ''
      document.getElementById('createCust_cust_phone').value = ''
      document.getElementById('createCust_cust_address').value = ''
      document.getElementById('createCust_cust_email').value = ''

      this.createCustFormErrors = []
      // console.log(this.createCustFormErrors)
      // this.createCustFormErrors.push('TID')
      this.showCreateCust = false
    },

    async submitFormCreateCust () {
      this.$store.commit('setIsLoading', true)
      this.createCustFormErrors = []

      if (document.getElementById('createCust_cust_name').value === '') {
                this.createCustFormErrors.push('The customer Name is missing')
            }
      if (document.getElementById('createCust_cust_phone').value === '') {
                this.createCustFormErrors.push('The customer Phone is missing')
            }
      if (document.getElementById('createCust_cust_address').value === '') {
                this.createCustFormErrors.push('The customer Address is missing')
            }
      if (document.getElementById('createCust_cust_email').value === '') {
                this.createCustFormErrors.push('The customer Email is missing')
            }
      if (document.getElementById('createCust_cust_phone').value.length != 10) {
                this.createCustFormErrors.push('The customer Phone must have 10 digits')
            }
      if (document.getElementById('createCust_cust_address').value.length < 10) {
                this.createCustFormErrors.push('The customer Address Address must have at least 10 characters')
            }
      
      if (!this.validateEmail(document.getElementById('createCust_cust_email').value)) {
                this.createCustFormErrors.push('The customer Email is not valid')
            }

      // console.log(this.createCustFormErrors)
      
      if (this.createCustFormErrors.length) {
        return
      } else {
        const formData = {
                name: document.getElementById('createCust_cust_name').value,
                main_address: document.getElementById('createCust_cust_address').value,
                main_phone: document.getElementById('createCust_cust_phone').value,
                email:document.getElementById('createCust_cust_email').value,
                last_edited_by_id: this.currentUser,
                }
        // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
        await axios
          .post('/api/v1/customer/', formData)
          .then(response => {
            // console.log(response.data)
            // document.getElementById('customer_phone').value = response.data['main_phone']
            
            this.createCustFormErrors = []
            // let fake_event = {
            //   srcElement: document.getElementById('customer_phone')
            // }
            // this.phoneChanged(fake_event)

            this.resetCustCreateForm()
            this.autocompleteValue = response.data['main_phone']
            document.getElementById('custinfo_cust_phone').value = response.data['main_phone']
            this.phoneChanged({srcElement: {value: response.data['main_phone']}})
            this.$store.commit('setIsLoading', false)

          })
          .catch(error => {
            this.createCustFormErrors.push(error.response.data)
            // console.log(error.response.data)
            this.$store.commit('setIsLoading', false)
          })
      }
    },

    deFilterOrderList (event) {
      
      this.search = ''
    },

    deFilterSearchTransaction (event) {
      
      this.searchTransactions = ''
    },

    deFilterNotes (event) {
      document.getElementById('note-search-input').value = ''
      this.searchNote = ''
    },

    evaluateCreateNote (event) {
      let noteButton = document.getElementById('button-create-note')
      let weekStr = document.getElementsByClassName('week_id-note-week_str')[0].innerText
      let filledWeekStr = !(weekStr==='')
      let filledNote = !(document.getElementsByClassName('textarea-note')[0].value==='')
      let selectedWeek_id = this.selectedWeek_id === 0 ? false : true
      let custId = this.custId === null ? false : true
      let name = this.custName === '' ? false : true
      let main_phone = this.custMainPhone === '' ? false : true
      // console.log(this.weeks[6].id)
      // console.log(this.weeks[6].id)
      let week5limit = this.selectedWeek_id > this.weeks[5].id

      if (week5limit) {
        document.getElementsByClassName('week-chonsen-check-create-note')[0].innerText = ''
      } else {
        document.getElementsByClassName('week-chonsen-check-create-note')[0].innerText = '(Note within 5 weeks)'
      }

      if (filledWeekStr && filledNote && selectedWeek_id && custId && name && main_phone && week5limit) {
        noteButton.classList.remove('disabled')
      } else {
        noteButton.classList.add('disabled')
      }
    },

    formInputIcon (event) {
      if (event.srcElement.classList.contains('form-input-type')) {
        let allActive = document.getElementsByClassName('form-input-type')
        Array.from(allActive).forEach(element => {
          element.classList.remove('is-active')
        });
        event.srcElement.classList.add('is-active')
      } else if (event.srcElement.classList.contains('form-input-channel')) {
        let allActive = document.getElementsByClassName('form-input-channel')
        Array.from(allActive).forEach(element => {
          element.classList.remove('is-active')
        });
        event.srcElement.classList.add('is-active')
      }
    },

    getWeekStr (week_id) {
      let start_date_id = this.weeks.find(week => week.id === week_id)
      if (start_date_id === undefined) {
        return week_id.toString()
      } else {
        start_date_id = start_date_id.start_date_id.toString()
        return start_date_id.slice(4,6) + '.' + start_date_id.slice(6,8)
      }
   
    },
    
    async clickRowOrderList (event) {
      // console.log('clickRowOrderList')
      // console.log(event.srcElement.id)
      // try {document.querySelector('.row-order-list.is-active').classList.remove('is-active')} catch (error) {1==1}

      let parent = event.srcElement
        while (!parent.classList.contains('row-order-list')) {
          if (parent.parentElement === null) {
            break
          }
          parent = parent.parentElement
        }
      // handle by v-table bind
      // parent.classList.add('is-active')

      // orderpacks-holder
      let breakPoint = parent.id.indexOf('-order-list')
      let id = parent.id.slice(0,breakPoint)
      // console.log(id)
      await document.getElementById(id).click()
      
      
      
      // scroll to order for history
      // console.log('here')
      // try { 
        
      //   let id_week = id.slice(15,19)
      //   console.log(id_week)
      //   document.getElementById(id_week).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })

      //   document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' }) 
        

        
      //   // document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' }) 
      
      // } catch (error) {
      //   console.log(error)
      // }     
      
    },

    async clickRowTransactionList (event) {
      // console.log('clickRowOrderList')
      // console.log(event.srcElement.id)
      // try {document.querySelector('.row-order-list.is-active').classList.remove('is-active')} catch (error) {1==1}

      let parent = event.srcElement
        while (!parent.classList.contains('row-transactions-list')) {
          if (parent.parentElement === null) {
            break
          }
          parent = parent.parentElement
        }
      // handle by v-table bind
      // parent.classList.add('is-active')

      // orderpacks-holder
      if (parent.id === 'na-transaction-list') {
            return
      }
      // console.log(parent)
      let breakPoint = parent.id.indexOf('-transaction-list')
      let id = parent.id.slice(0,breakPoint)
      // console.log(parent)
      // console.log(id)
      await document.getElementById(id).click()
      
      
      
      // scroll to order for history
      try { document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' }) } catch (error) {1==1}     
      
    },

    customSearch (value, search, item) {
      // console.log('customSearch')
      // console.log(value)
      
      search = search.toString()
      for (let [key, value] of Object.entries(item['raw'])) {
        try { if (value.toString().toLowerCase().includes(search.toString().toLowerCase())) { return true} } catch (error) {1==1}
      }
      return false
    },

    customSearchTransaction (value, search, item) {
      for (const [key, value] of Object.entries(item['raw'])) {
        try { if (value.toString().toLowerCase().includes(search.toString().toLowerCase())) { return true} } catch (error) {1==1}
      }
      return false
    },

    customSearchNote (value, search, item) {
      for (const [key, value] of Object.entries(item['raw'])) {
        try { if (value.toString().toLowerCase().includes(search.toString().toLowerCase())) { return true} } catch (error) {1==1}
      }
      return false
    },

    showControl (event) {

      ['orderList','customerBalance','info','details'].forEach(target => {
        // console.log(event.srcElement.id+'-control-class')
        if (event.srcElement.id === target) {
          event.srcElement.classList.add('is-active')
          // console.log('-control-class')
          // console.log(document.getElementsByClassName(target+'-control-class'))
          document.getElementsByClassName(target+'-control-class')[0].classList.add('is-active')
          
          // try { document.querySelector('.pack-table-tbody.is-active').scrollIntoView() } catch (error) {1==1}     
          if (target === 'details') {
            // console.log('scrollIntoView HELLO')
            try { document.querySelector('div.pack-table-tbody.is-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) } catch (error) {
              // console.log(error)
            }     
          }

          if (target === 'customerBalance') {
            // console.log('scrollIntoView HELLO')
            try { document.querySelector('.row-transactions-list.is-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) } catch (error) {
              // console.log(error)
            }     
          }

          if (target === 'orderList') {
            // console.log('scrollIntoView HELLO')
            try { document.querySelector('.row-order-list.is-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) } catch (error) {
              // console.log(error)
            }     
          }


        } else {
          
          document.getElementById(target).classList.remove('is-active')
          // console.log(document.getElementsByClassName(target+'-control-class'))
          document.getElementsByClassName(target+'-control-class')[0].classList.remove('is-active')
        }
      });

    },

    formatPriceRound (price) {
      return Math.round(price).toLocaleString('en-US')
    },

    resetPackInfo () {
      let toBeDestroyed = document.getElementsByClassName('price-holder')[0].getElementsByClassName('to-destroy-price')
      Array.from(toBeDestroyed).forEach(element => {
        element.remove()
      });

      let toBeDestroyedPackTable = document.getElementsByClassName('to-destroy-pack-table')
      Array.from(toBeDestroyedPackTable).forEach(element => {
        element.remove()
      });

      let toReset = document.getElementsByClassName('detail-holder-info-order-info')[0].getElementsByClassName('to-reset-inner-html')
      Array.from(toReset).forEach(element => {
        element.innerHTML = ''
      });

      let toWrapToogle = document.getElementsByClassName('nowrap-toogle')
      Array.from(toWrapToogle).forEach(element => {
        element.classList.add('nowrap')
      });


      let rowOrderList = document.getElementsByClassName('row-order-list')
      Array.from(rowOrderList).forEach(element => {
        element.classList.remove('is-active')
      });

      document.getElementById('note-search-input').value = ''

    },

    async selectPack (event) {
      // console.log('selectPack')
      // console.log(event)
      // console.log('selectPack')
      // console.log(event)
      

      let allSelected = document.getElementsByClassName('selected')
      await Array.from(allSelected).forEach(element => {
        element.classList.remove('selected')
        this.selectedPackId = ''
        this.selectedPackIdLocal = ''
        this.selectedWeek_id = 0
      });

      // for orderpacks-holder
      let currElement = event.srcElement

      if (currElement.classList.contains('orderpacks-holder')) {
        currElement.classList.add('selected')
      } else {
        let parent = currElement
        while (!parent.classList.contains('orderpacks-holder')) {
          if (parent.parentElement === null) {
            break
          }
          parent = parent.parentElement
        }
        parent.classList.add('selected')
      }
      try {
        this.selectedPackId = document.querySelector('.orderpacks-holder.selected').id
        this.selectedPackIdLocal = document.querySelector('.orderpacks-holder.selected').getElementsByClassName('pack-id')[0].innerText
        // console.log(this.selectedPackId)
      } catch (error) {1==1}
      
      // for week-holder
      if (currElement.classList.contains('week-holder')) {
        currElement.classList.add('selected')
      } else {
        let parent = currElement
        while (!parent.classList.contains('week-holder')) {
          if (parent.parentElement === null) {
            break
          }
          parent = parent.parentElement
        }
        await parent.classList.add('selected')
        // await parent.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
      // console.log(parent)
      // 
      this.selectedWeek_id = parseInt(document.querySelector('.week-holder.selected').id)
      // destroy price append elements
      
      await this.resetPackInfo()

      // let toResetInfo = document.getElementsByClassName('order-info-part-1')[0].getElementsByClassName('to-reset-inner-html')

      
      // for week-selected
      let selectedWeekElement = document.querySelector('.week-holder.selected')
      // console.log(selectedWeekElement)
      let allSlectedPack = this.orders.filter(order => order.week_id === parseInt(selectedWeekElement.id))
      let packTableHolder = document.getElementsByClassName('pack-table-holder')[0]
      allSlectedPack.forEach(pack1 => {
        // template-package-table
        // console.log(pack1)
        let packTable = document.querySelector('.template-package-table').cloneNode(true)
        packTable.id = pack1['id'] + '-pack-table'
        let packAlias = pack1['packAlias']
        if (pack1['ycdb2'] === 'Thêm phần ăn sáng dành cho gói MEAT LOVER') {
          packAlias = packAlias.replace('MEAT', 'MEAT-S')
        }
        
        packTable.getElementsByClassName('id-pack-table')[0].innerHTML = packAlias
        
        packTable.getElementsByClassName('packageLocalId-pack-table')[0].innerHTML = '(id:' + pack1['packageLocalId'] + ')'
        packTable.classList.remove('template-package-table')
        packTable.classList.remove('is-hidden')
        packTable.classList.add('to-destroy-pack-table')
        // console.log(packTable)
        // pack-table-tbody
        let fullStrDb = pack1['fullStrDB']
        if ((fullStrDb === null) || (fullStrDb === undefined) || (fullStrDb === '')) {
          fullStrDb = {}
        } else {
          fullStrDb = JSON.parse(fullStrDb)
          Object.keys(fullStrDb).forEach(day => {
            let buoiStr = fullStrDb[day]
            Object.keys(buoiStr).forEach(buoi => {
              // console.log('resetPackInfo')
              // console.log('pack-table-'+buoi)
              let buoiElement = packTable.getElementsByClassName('pack-table-'+buoi)[0].getElementsByClassName('day-'+day)[0]
              let valueinput = buoiStr[buoi]
              
              if (['SA','TR','TO'].includes(buoi)) {
                let start = valueinput.indexOf('(')
                let end = valueinput.lastIndexOf(')')
                let tLength = valueinput.length
                if (start != -1) {
                  let strDB = valueinput.slice(start+1,end)
                  let normalStr = valueinput.slice(0,start) + valueinput.slice(end+1,tLength)
                  valueinput = '<strong>' + normalStr + '</strong>' + '<br><i>' + strDB.toLowerCase() + '</i>'
                } else {
                  valueinput = '<strong>' + valueinput + '</strong>'
                }
                
                buoiElement.innerHTML = valueinput
              } else {
                buoiElement.innerHTML = '<strong>' + buoiStr[buoi] + '</strong>'
              }
              
            });
          });
        }
        
        

        let shipStr = pack1['shipStr']
        if ((shipStr === null) || (shipStr === undefined) || (shipStr === '')) {
          shipStr = {}
        } else {
          shipStr = JSON.parse(shipStr)
          Object.keys(shipStr).forEach(day => {
            let oneDayShip = shipStr[day]['details']
            Object.keys(oneDayShip).forEach(key => {
              try {
                let shipElement = packTable.getElementsByClassName('pack-table-'+key)[0].getElementsByClassName('day-'+day)[0]
                shipElement.innerHTML = oneDayShip[key]
              } catch (error) {
                // console.log(error)
                1==1
              }
              
            });
          });
        }

        
        //  only create if have products
        let fullStr = pack1['fullStr']
        if ((fullStr === null) || (fullStr === undefined) || (fullStr === '')) {
          1==1
        } else {
          packTableHolder.appendChild(packTable)
        }
        

      });
      // end of week-selected
      
      // for packTable selected
      try {
        await document.getElementsByClassName('pack-table-holder')[0].childNodes.forEach(element => {
          if (element.id == (document.querySelector('.orderpacks-holder.selected').id+'-pack-table')){
            element.classList.add('is-active')
          }
        });
      } catch (error) {
        1==1
        // console.log(error)
      }
      
      // end of packTable selected


      // for pack-selected
      
      let selectedPack = document.querySelectorAll('.orderpacks-holder.selected')
      if (selectedPack.length > 0) {
        
        let selectedOrder = this.orders.find(order => order.id === selectedPack[0].id)
        let priceHolder = document.getElementsByClassName('price-holder')[0]
        let lineBreak = document.getElementsByClassName('line-break')[0]
        // console.log(selectedOrder)
        let infoArr = Object.keys(selectedOrder)
        // console.log(infoArr)
        infoArr.forEach(info => {
          try {
            if ((info === 'packAlias')&&(selectedOrder['ycdb2'] === 'Thêm phần ăn sáng dành cho gói MEAT LOVER')) {
              document.getElementsByClassName('packAlias-pack-info')[0].innerHTML = selectedOrder[info].replace('MEAT', 'MEAT-S')
            } else {
              let infoElement = document.getElementsByClassName(info+'-pack-info')[0]
              infoElement.innerHTML = selectedOrder[info]
            }
            
          } catch (error) {
            // console.log(error)
            1==1
          }

          try {
            let infoElement = document.getElementsByClassName(info+'-pack-price')[0]
            if (info.includes('Amt')||info.includes('totalBeforeDis')) {
              infoElement.innerHTML = this.formatPriceRound(selectedOrder[info])
            } else if ((info === 'packAlias')&&(selectedOrder['ycdb2'] === 'Thêm phần ăn sáng dành cho gói MEAT LOVER'))  {
              infoElement.innerHTML = selectedOrder[info].replace('MEAT', 'MEAT-S')
            } else {
              infoElement.innerHTML = selectedOrder[info]
            }
            
          } catch (error) {
            1==1
            // console.log(error)
          }
        });

        

        // ship 
        let shipStrPrice = selectedOrder['shipStrPrice']
        // console.log(shipStrPrice)
        if ((shipStrPrice === undefined) || (shipStrPrice === null)) {
          1==1
        } else {
          
          shipStrPrice = JSON.parse(shipStrPrice)
          Object.keys(shipStrPrice).forEach(shipPackId => {
            let shipItem = shipStrPrice[shipPackId]
            // console.log(shipItem)
            Object.keys(shipItem).forEach(shipLoc => {
              let shipElement = document.getElementsByClassName('ship-details-this-order')[0].cloneNode(true)
              if (shipPackId != selectedOrder.id){
                shipElement = document.getElementsByClassName('ship-details-not-this-order')[0].cloneNode(true)
                shipElement.getElementsByClassName('packageLocalId-ship-item')[0].innerHTML = shipItem[shipLoc]['packageLocalId']
              }
              shipElement.classList.remove('is-hidden')
              shipElement.classList.add('to-destroy-price')
              shipElement.getElementsByClassName('shipLoc-ship-item')[0].innerHTML = shipLoc
              shipElement.getElementsByClassName('shipLoc-quant-ship-item')[0].innerHTML = shipItem[shipLoc]['count']
              shipElement.getElementsByClassName('ship-price-ship-item')[0].innerHTML = this.formatPriceRound(shipItem[shipLoc]['price'])
              
              
              // shipElement.getElementsByClassName('shipPrice_sys-pack-price')[0].innerHTML = shipItem['shipPrice_sys']
              priceHolder.insertBefore(shipElement,lineBreak)  

            });
          });

        }
        
        // điều chỉnh giá ship
        if (selectedOrder['shipPriceAdjAmt']!=0) {
            let sideOrderElement = document.getElementsByClassName('ship-price-details-adj')[0].cloneNode(true)
            sideOrderElement.classList.remove('is-hidden')
            sideOrderElement.classList.add('to-destroy-price')
            sideOrderElement.getElementsByClassName('ship-price-ship-item')[0].innerHTML = this.formatPriceRound(selectedOrder['shipPriceAdjAmt'])
            priceHolder.insertBefore(sideOrderElement,lineBreak)  

          }
        // end of ship

        // side order
        let sideStrFull = selectedOrder['sideStrFull']
        // console.log(sideStrFull)
        if ((sideStrFull === undefined)|| (sideStrFull === null)){
          1==1
        } else {
          sideStrFull = JSON.parse(sideStrFull)
          // console.log(sideStrFull)
          
          Object.keys(sideStrFull).forEach(sideProductName => {
            let sideOrder = sideStrFull[sideProductName]
            let attElement = document.getElementsByClassName('side-order-details')[0].cloneNode(true)
            attElement.classList.remove('is-hidden')
            attElement.classList.add('to-destroy-price')
            attElement.getElementsByClassName('side-order-name')[0].innerHTML = sideProductName 
            attElement.getElementsByClassName('side-order-quant')[0].innerHTML = sideOrder['quant']
            attElement.getElementsByClassName('side-order-price')[0].innerHTML = this.formatPriceRound(sideOrder['total'])

            // console.log(sideOrder)
            priceHolder.insertBefore(attElement,lineBreak)
          });
          // console.log("selectedOrder['sidePriceAdjAmt']")
          // console.log(selectedOrder['sidePriceAdjAmt'])
          
        }
        // console.log('valueinput')
        // điều chỉnh giá side nếu có
        if (selectedOrder['sidePriceAdjAmt']!=0) {
            let attElement = document.getElementsByClassName('side-order-details-adj')[0].cloneNode(true)
            attElement.classList.remove('is-hidden')
            attElement.classList.add('to-destroy-price')
            attElement.getElementsByClassName('side-order-price')[0].innerHTML = this.formatPriceRound(selectedOrder['sidePriceAdjAmt'])
            priceHolder.insertBefore(attElement,lineBreak)
          }

        // end of side order

        // other Amt
        
        if (selectedOrder['specialAmt']!=0) {
            let attElement = document.getElementsByClassName('specialAmt-adj')[0].cloneNode(true)
            attElement.classList.remove('is-hidden')
            attElement.classList.add('to-destroy-price')
            // -pack-price already added innerHTML
            priceHolder.insertBefore(attElement,lineBreak)
          }

        // other End of other Amt


        // other Amt
        if (selectedOrder['otherAmt']!=0) {
            let attElement = document.getElementsByClassName('otherAmt-adj')[0].cloneNode(true)
            attElement.classList.remove('is-hidden')
            attElement.classList.add('to-destroy-price')
            // -pack-price already added innerHTML
            priceHolder.insertBefore(attElement,lineBreak)
          }

        // other End of other Amt


        // cancle nowrap 
        let toWrapToogle = document.getElementsByClassName('nowrap-toogle')
        Array.from(toWrapToogle).forEach(element => {
          // console.log('element.height')
          let beforeH = element.offsetHeight
          element.classList.remove('nowrap')
          let afterH = element.offsetHeight
          if (beforeH < afterH) {
            element.classList.add('nowrap')
          }
        });
      };

      // end of pack-selected

      // change active order-list 
      try {
        
        let active_order_list = document.getElementsByClassName('row-order-list')
        Array.from(active_order_list).forEach(element => {
          // console.log(element.id.slice(0,-('-order-list'.length)))
          // console.log(selectedPack[0].id)
          if (element.id.slice(0,-('-order-list'.length)) === selectedPack[0].id.toString()) {
            element.classList.add('is-active')
          } else {
            element.classList.remove('is-active')
          }

        });
    
      } catch (error) {1==1}
        
      // end of change active order-list


      
      
      // try { document.querySelector('div.orderpacks-holder.selected').scrollIntoView() } catch (error) {console.log(error)}     
      try { 
      document.querySelector('div.week-holder.selected').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) 
      // document.querySelector('div.week-holder.selected').scrollTo({  inline: 'nearest' })
      } 
      catch (error) {
        1==1
      }     

      try { document.querySelector('.row-order-list.is-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }) } 
      catch (error) {
        1==1
        // console.log(error)
      }     
      
      document.getElementById('note-search-input').value = document.querySelector('.week-holder.selected strong').innerHTML
      if (this.autoInsertWeek) {
        this.searchNote = document.querySelector('.week-holder.selected strong').innerHTML
      }
      
      document.getElementsByClassName('week_id-note-week_str')[0].innerHTML = document.querySelector('.week-holder.selected strong').innerHTML


      this.evaluateCreateNote(1000)

      // scroll to view 
      try { 
        let active_info_tab = document.querySelector('.control-item.is-active')
        if (active_info_tab.id==='details') {
          document.querySelector('div.pack-table-tbody.is-active').scrollIntoView({ block: 'nearest' })   
        }

        if (active_info_tab.id==='orderList') {
          document.querySelector('.row-order-list.is-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })   
        }

        if (active_info_tab.id==='customerBalance') {
          // console.log('scrollIntoView customerBalance')
          document.querySelector('.row-transactions-list.is-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })   
          // let eml = document.getElementById(this.selectedPackId+'-transaction-list')
          // // console.log(eml)
          // document.getElementById(this.selectedPackId+'-transaction-list').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start'})   
        }

      } catch (error) {
        // console.log(error)
      }   

    },

    resetCustInfo() {
      this.custName = ''
      this.custMainAddress = ''
      this.custMainPhone = ''
      this.traits = ''
      this.diets = ''
      this.custId = null
      this.notes = []
      this.orders = []
      this.transactions = []
      this.selectedforInBill = []
      this.group_address = []

    },

    async formPhoneChange (event,byPass=false) {
      // console.log('formPhoneChange')
      if (event.srcElement.value === null) {
        // console.log('formPhoneChange null out')
        await this.makeHist([])
        try {this.scrollEnd()} catch (error) {1==1}
        return
      }
      if (!byPass) {
        if (this.autocompleteValue === '')  {
          // console.log('formPhoneChange no string out')
          await this.makeHist([])
          try {this.scrollEnd()} catch (error) {1==1}
          return
        }
      }
      

      let myString = event.srcElement.value
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      this.autocompleteValue = myString
      document.getElementById('custinfo_cust_phone').value = myString

      if (myString.length < 10) {
        this.resetCustInfo()
        this.resetPackInfo()
        await this.makeHist([])
        try {this.scrollEnd()} catch (error) {1==1}
        return
      }
      
      this.phoneChanged(event)
    },

    async phoneChanged (event) {
      this.$store.commit('setIsLoading', true)
      // console.log('phoneChanged')
      let myString = event.srcElement.value
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      
      this.autocompleteValue = myString
      event.srcElement.value = myString

      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      let min_week_id = 0
      if (this.load3month) {
        min_week_id = this.threeMonthsAgoWeekId
      }
      await axios
      .get('/api/v1/customerHist/?main_phone='+ myString + '&min_week_id=' + min_week_id  )
      .then(response => {
        this.resetCustInfo()
        
        let result = response.data
        this.setAutoComplete(result.phone_number_readable + ':' + result.name)
        this.custName = result.name
        this.custMainAddress = result.main_address
        this.custMainPhone = result.phone_number_readable
        this.custId = result.id
        this.transactions = result.transactions

        for (let i = 0; i < this.transactions.length; i++) {
          try {
            this.transactions[i]['weekStr'] = this.getWeekStr(this.transactions[i].week_id )
          } catch (error) {
            this.transactions[i]['weekStr'] = ''
          }
        }

        let traitCount = 0
        Object.keys(result.traits).forEach(key => {
          if (traitCount >10) {
            1==1          
            }
          else {
            this.traits = this.traits + '(<strong>' + Math.min(result.traits[key],99) + 'x</strong>_' + key.toLowerCase() +'); '
          }  
          
            traitCount = traitCount + 1
            
        });
        Object.keys(result.diets).forEach(key => {
            this.diets = this.diets + '(<strong>' + Math.min(result.diets[key],99) + 'x</strong>_' + key.toLowerCase() +'); '
        });
        
        this.group_address = result.customer_addresses
        
        this.notes = result.notes
        for (let i = 0; i < this.notes.length; i++) {
            // console.log(this.notes[i])
            this.notes[i]['is_completed_string'] = this.notes[i].is_completed == 1 ? 'done' : 'pending'
            // console.log(this.notes[i].week_id)
            this.notes[i]['weekStr'] = this.getWeekStr(this.notes[i].week_id)
          }
        // console.log('API SUCCESS END')
        // console.log(result.orders)
        this.notes.sort((a, b) => (a.id > b.id) ? -1 : 1)
        let ordersNotNull = result.orders.filter(order => (order.fullStr !== null)||(order.finalAmt !== 0))
        this.orders = ordersNotNull
        // console.log(this.orders)
        this.makeHist(result.orders)
        
        try {this.scrollEnd()} catch (error) {1==1}

        this.resetPackInfo()
        
        try {
          if (this.urlPassedWeek>0) {
            document.getElementById(this.urlPassedWeek).firstChild.click()
            this.urlPassedWeek = 0
          } else {
            document.getElementById(this.weeks[2].id).firstChild.click()
          }
        } catch (error) {
          document.getElementById(this.weeks[2].id).firstChild.click()
        }
        
        this.$store.commit('setIsLoading', false)
        this.search = ''
      })
      .catch(error => {
        
        this.resetCustInfo()
        this.resetPackInfo()
        this.makeHist([])
        this.custName = '<strong class="non-exist"> Không tồn tại </strong>'
        document.getElementById(this.weeks[2].id).firstChild.click()
        this.$store.commit('setIsLoading', false)
      })

    },

    prepareWeek (week_id) {
      // console.log('prepareWeek')
      // console.log(week_id)
      let weekObj = this.weeks.find(week => week.id === week_id)
      let history_holder = document.getElementsByClassName('history-holder')[0]
      let week_holder = document.getElementsByClassName('template-week')[0].cloneNode(true)
      week_holder.classList.remove('is-hidden')
      week_holder.classList.remove('template-week')
      week_holder.classList.add('to-destroy-week')

      let week_item = this.weeks.find(week => week.id === week_id)
      if (week_item.is_holiday === 0) {
        week_holder.getElementsByClassName('week-holder-title')[0].addEventListener('click', this.selectPack)  
      } else {
        week_holder.getElementsByClassName('week-holder-title')[0].classList.remove('is-clickable')
        week_holder.classList.add('is-holiday')
      }
      
      
      week_holder.id = weekObj.id
      let weekStr = week_holder.getElementsByClassName('weekStr')[0]
      // let weekstr_ = weekObj.start_date_id.toString().substring(4)
      weekStr.innerHTML = this.getWeekStr(weekObj.id)

      let order_template = week_holder.getElementsByClassName('template-package')[0]
      order_template.remove()
      history_holder.insertBefore(week_holder,history_holder.firstChild)
      // console.log('ENDprepareWeek')
    },

    async getWeeks () {
      await axios
      .get('/api/v1/last-year-till-now/')
      .then(response => {
        let result = response.data
        this.weeks = result  
// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        // this.formPhoneChange({srcElement: {value: '0703618167'}});
        })
      .catch(error => {
        if (error.response.status === 401) {
            // console.log('error.response.status')
            // console.log(error.response.status)
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
      })
    },

    scrollEnd () {
      document.getElementsByClassName('history-holder')[0].scrollLeft = 10000
    },

    makeHist (allOrders) {
      // console.log('makeHist')
      let allToDestroy = document.getElementsByClassName('to-destroy-week')
      Array.from(allToDestroy).forEach(element => {
        element.remove()
      });
      // console.log('allOrders')
      // console.log(this.weeks )
      this.prepareWeek(this.weeks[0].id)
      this.prepareWeek(this.weeks[1].id)
      this.prepareWeek(this.weeks[2].id)
      this.prepareWeek(this.weeks[3].id)
      this.prepareWeek(this.weeks[4].id)
      // console.log('allOrders2')
      
      allOrders.forEach(order => {
        // console.log('order')
        // console.log(order)
        let fullStr = {}
        try {
          fullStr = JSON.parse(order.fullStr)
        } catch (error) {
          fullStr = null
        }
        
        if ((fullStr === null)&&(order.finalAmt === 0)) {
          1 == 1
        } else {

          let week_holder = document.getElementById(order.week_id)

          if ((week_holder === null)||(week_holder === undefined)) {
            this.prepareWeek(order.week_id)
          }

          week_holder = document.getElementById(order.week_id)
          let week_packages_holder = week_holder.getElementsByClassName('week-holder-inner')[0]

        
          let order_template = document.getElementsByClassName('template-package')[0].cloneNode(true)
          order_template.classList.remove('template-package')
          order_template.getElementsByClassName('pack-id')[0].innerHTML = order.packageLocalId
          let packAlias = order.packAlias
          if (order.ycdb2 === 'Thêm phần ăn sáng dành cho gói MEAT LOVER') {
            packAlias = packAlias.replace('MEAT','MEAT-S')
          }
          order_template.getElementsByClassName('pack-alias')[0].innerHTML = packAlias
          order_template.getElementsByClassName('shipLoc')[0].innerHTML = order.shipLoc
          order_template.getElementsByClassName('idStr')[0].innerHTML = order.idStr
          order_template.getElementsByClassName('totalMeals')[0].innerHTML = order.totalMeals
          order_template.getElementsByClassName('noteDBArrText')[0].innerHTML = order.noteDBArrText
          order_template.id = order.id
          order_template.addEventListener('click', this.selectPack)
            
          if ((fullStr !== null)) {
            Object.keys(fullStr).forEach(day => {
            let pack_day_obj = fullStr[day]
            Object.keys(pack_day_obj).forEach(buoi => {
                if (['SA','TR','TO'].includes(buoi) === true) {
                  order_template.getElementsByClassName(this.mapDay[day])[0].getElementsByClassName(buoi)[0].innerHTML = pack_day_obj[buoi]
                } else {
                  order_template.getElementsByClassName(this.mapDay[day])[0].getElementsByClassName(buoi)[0].innerHTML = pack_day_obj[buoi].substring(0,2)
                }
              })
            });
          }
        
    

          ['SA','TR','TO','O'].forEach(day => {
            let allElements = order_template.getElementsByClassName(day)
            Array.from(allElements).forEach(element => {
              if (element.innerHTML === '') {
                element.parentElement.remove()
              }
            });
          })
        
          week_packages_holder.appendChild(order_template)



          
        }
      });
     

      let allWeeks = document.getElementsByClassName('week-holder')
      let holder = {}
      let lastWeekId = 0
      // console.log('allWeeks')
      Array.from(allWeeks).forEach(week => {
        // console.log(this.weeks.filter(week_ => week_.id > lastWeekId).filter(week_ => week_.id < week.id).length)
        holder[week.id] = this.weeks.filter(week_ => week_.id > lastWeekId).filter(week_ => week_.id < week.id).length
        lastWeekId = week.id
      });
      // console.log(holder)
      for (const [key, value] of Object.entries(holder)) {
        // console.log(key, value);
        if (value > 0) {
          let week_holder = document.getElementById(key)
          let history_holder = document.getElementsByClassName('history-holder')[0]
          let time_holder = document.getElementsByClassName('time-holder')[0].cloneNode(true)
          time_holder.classList.remove('is-hidden')
          time_holder.classList.add('to-destroy-week')
          // console.log('time_holder')
          time_holder.getElementsByClassName('numweek')[0].innerHTML = value
          history_holder.insertBefore(time_holder,week_holder)
        }
      }
    },

    async submitCreateNote () {
      
      if (document.getElementById('button-create-note').classList.contains('disabled')) {
        return
      }

      let channel = ''
      let channelActive = document.querySelector('.form-input-channel.is-active')
      Array.from(['phone','facebook','other']).forEach(element => {
        if (channelActive.classList.contains(element)) {
          channel = element
        }
      });
      // console.log(channel)

      let typeStr = ''
      let typeActive = document.querySelector('.form-input-type.is-active')
      Array.from(['order','payment','ship','cancel','other']).forEach(element => {
        if (typeActive.classList.contains(element)) {
          typeStr = element
        }
      });
      
      const formData = {
        customer_phone: this.custMainPhone.replaceAll('-',''),
        customer_id: this.custId,
        name: this.custName,
        channel_id: channel,
        description: document.getElementsByClassName('textarea-note')[0].value,
        type: typeStr,
        is_month_order: 0,
        last_edited_by_id: this.currentUser,
        week_id: this.selectedWeek_id,
        orderpackage_id :  ((this.selectedPackId === '') ? '' : (this.selectedPackId)) + ((this.selectedPackIdLocal === '') ? '' : ('-' + this.selectedPackIdLocal))
      }
      this.$store.commit('setIsLoading', true)
      await axios
        .post('/api/v1/create-note-and-get-by-cust/', formData)
        .then(response => {
          // console.log(response.data)
          this.notes = response.data
          for (let i = 0; i < this.notes.length; i++) {
            this.notes[i]['is_completed_string'] = this.notes[i].is_completed == 1 ? 'done' : 'pending'
            this.notes[i]['weekStr'] = this.getWeekStr(this.notes[i].week_id)
          }
          this.notes.sort((a, b) => (a.id > b.id) ? -1 : 1)
          this.$store.commit('setIsLoading', false)
          document.getElementsByClassName('textarea-note')[0].value = ''
          document.querySelector('.form-input-channel.phone').click()
          document.querySelector('.form-input-type.order').click()
          this.evaluateCreateNote(1000)
        })
        .catch(error => {
          // console.log(error)
          this.$store.commit('setIsLoading', false)
          this.evaluateCreateNote(1000)
        })
    },

    async custAddressEdit (event) {
      // console.log('custAddressEdit')
      if (event.target.classList.contains('is-disabled')) {
        return
      }
      let custAddId = event.target.id.replace('-cust-address-item','')
      // console.log(event.target.id)

      // ['makemain','deactive','activate']
      let is_main = 0
      if (event.target.classList.contains('makemain')) {
        is_main = 1
      }
      let active = 1
      if (event.target.classList.contains('deactive')) {
        active = 0
      }

      if (event.target.classList.contains('activate')) {
        active = 1
      }
      
      let formData = {
        id: custAddId,
        is_main: is_main,
        is_active: active
      }
      await axios
        .post('/api/v1/customer-address-edit/', formData)      
        .then(response => {
          // console.log(response.data)
          // this.formPhoneChange({srcElement: {value: this.custMainPhone}},true)
          this.group_address = response.data
          let main_address = this.group_address.find(address => address.is_main === 1)
          this.custMainAddress = main_address.address_detail
          this.custName = main_address.name
          this.custMainPhone = main_address.phone_number_readable

        })
    }, 

    setLocalLoad3month (event) {
      this.load3month = event.target.checked
      localStorage.setItem('load3month', event.target.checked)
    }, 

    setAutoInsertWeek (event) {
      this.autoInsertWeek = event.target.checked
      localStorage.setItem('autoInsertWeek', event.target.checked)
    },

    set3MonthsAgo () {
      var d = new Date();
      d.setMonth(d.getMonth() - 3);
      // console.log(d.getWeekNumber())
      this.threeMonthsAgoWeekId = d.getUTCFullYear()*100+ (d.getUTCMonth())*4
      // console.log('this.threeMonthsAgoWeekId')
      // console.log(this.threeMonthsAgoWeekId)
    }

  },
  
  async beforeMount() {
    try { this.load3month = JSON.parse(localStorage.getItem('load3month'))  
    } catch (error) {
      this.load3month = false
    }
    try { this.autoInsertWeek = JSON.parse(localStorage.getItem('autoInsertWeek'))  
    } catch (error) {
      this.autoInsertWeek = false
    }
    
    this.set3MonthsAgo()
    
  },

  async mounted () {
    document.title = 'Order History'
    this.currentUser = localStorage.getItem('user')
    let params = (new URL(document.location)).searchParams;

    await this.getWeeks();
    
    


    try {
      let name = params.get("q");
      let week_chonsen = params.get("week_id");
      // console.log(name)
      if (name === null) {
        name = ''
      }
      this.urlPassedWeek = week_chonsen
    
      this.autocompleteValue = name
      this.formPhoneChange({srcElement: {value: name }});
      // console.log('week_chonsen')  
    } catch (error) {
      1==1
      // this.formPhoneChange({srcElement: {value: 0}});
    }


    
    document.addEventListener('click', this.handleClick)
    
    await axios
    .get('/api/v1/cache-cust/')
    .then(response => {
      this.autocompleteSuggestions = response.data
      // console.log('this.autocompleteSuggestions')
      // console.log(this.autocompleteSuggestions)
    })
    .catch(error => {
      // console.log(error)
    });

    this.setAutoComplete('init')
    
    
    
  },

  computed: {
    quickWeekStr () {
      return this.getWeekStr(this.selectedWeek_id)
    },

    hasValueCustInfo () {
      let resulte = ((this.autocompleteValue !== '') && (this.autocompleteValue !== null))
      return resulte
    },
    group_address_filtered () {
      if (this.addressShowAll) {
        // return this.group_address.filter(address => address.is_main !== 1)
        return this.group_address
      } else {
        return this.group_address.filter(address => address.is_active === 1)
        // return this.group_address.filter(address => address.is_active === 1).filter(address => address.is_main !== 1)
      }
      
    },
    isPlaningCS : function(){ 
        return this.$store.getters.isPlaning
      }
      , 

  },
  
  
  updated () {
    console.log(this.weeks[2]['id'])
  },
}

</script>

<style scoped>
.cust_focus_info_wrapper{
  overflow-y: hidden;
}
tr:nth-child(even) {
  background-color: #f0f0f0;
}

.mon {
  /* border-bottom-left-radius: 1em; */
  border-left: 1px solid black;
}

.mon , .tue, .wed, .thu, .fri {
  /* border-bottom: 1px solid black; */
  border-right: 1px solid black;
  font-size: large;
  flex-grow: 1;
  background-color: white;
}

.orderpacks-holder.selected >.orderpack-detail > .orderpack-detail-contents > .mon {
  border-left: 3px solid black;
}

.orderpacks-holder.selected >.orderpack-detail > .orderpack-detail-contents > .fri {
  border-right: 3px solid black;
}

.orderpack-detail {
  margin-top: 0.5em;
}

.orderpack-detail-contents {
  padding-right: 0.5em;
  padding-left: 0.5em;
  
  
}

.orderpack-detail-title {
  padding-left: 0.5em;
  padding-right: 0.5em;

}


.orderpack-detail-title-line {
  /* padding: 0.5em; */
  background-color: #f18973;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-top-right-radius: 1em;
  border: 1px solid black;
  border-bottom: 0px !important; 
}

.orderpacks-holder.selected >.orderpack-detail > div.orderpack-detail-title > div.orderpack-detail-title-line {
  background-color: hsl(44, 100%, 77%) !important;
  border: 3px solid black;
}

.orderpack-detail-title-after {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.orderpack-detail-title-after-line {
  /* padding: 0.5em; */
  background-color: #f7cac9;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-bottom-left-radius: 1em;
  border: 1px solid black;
  border-top: 0px !important; 
}

.orderpacks-holder.selected >.orderpack-detail > .orderpack-detail-title-after > .orderpack-detail-title-after-line {
  border: 3px solid black;
}


.orderpack-detail-title-after-line-1 {
  /* padding: 0.5em; */
  
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: white;
  border: 1px solid black;
  border-top: 0px !important; 
  border-bottom: 0px !important; 
}

.orderpacks-holder.selected >.orderpack-detail > .orderpack-detail-title-after > .orderpack-detail-title-after-line-1 {
  border: 3px solid black;
}



.orderpack-detail-contents div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding: 0.5em;
}




.history-holder {
  display:flex;
  width:100%;
  max-width: 100%;
  overflow-x:auto;
  overflow-y: hidden;
  /* justify-content: right; */
}

.history-holder::before {
  content: "";
  flex: 0 0 20vw;
  visibility: hidden;
}

.history-holder::-webkit-scrollbar{
  height: 1.25em;
}

.week-holder-inner{
  max-height: 95%;
  height:32vh !important;
  overflow-y: auto;
  padding-bottom: 1em;
  overflow-x: hidden;
}

.week-holder {
  margin-right: 0.5em;
  border: 1px solid black;
  /* margin: 0.5em; */
  border-bottom-left-radius: 1vw;
  border-top-right-radius: 1vw;
  /* overflow-y: hidden; */
  
}

.week-holder.time-holder {
  background-color: #cccccc;
}

.week-holder.selected {
  border: 2px solid black;
  background-color: #ebbfbf;
}



.week-holder-title{
  /* border-bottom-left-radius: 1em; */
  border-top-right-radius: 1vw;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.custText-preText {
  border: 1px solid rgb(138, 138, 138);
  border-right: 0px !important;
  /* background-color: #8dbb9d;  */
  padding: 0.2em;
  padding-left: 0.3em !important;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.custPrice-preText {
  /* border: 1px solid rgb(138, 138, 138); */
  border-right: 0px !important;
  /* background-color: #8dbb9d;  */
  padding: 0.1em;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  display: flex;
  flex-grow: 2;
  min-width: 75%;
  justify-content: center;
}



.order-info-item-line{
  
  margin-bottom: 0.2em;
}

.custText {
  padding: 0.2em;
  padding-right: 1em;
  padding-left: 0.5em;
  background-color: #c4cebb;
  /* border: 1px solid black; */
  border-left: 0px !important;
  width: 100%;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.custPrice {
  padding: 0.1em;
  padding-right: 1em;
  padding-left: 0.5em;
  background-color: #c4cebb;
  /* border: 1px solid black; */
  border-left: 0px !important;
  width: 100%;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  flex-grow: 1;
  text-align: right;
}

.order-info-part-1 {
  padding-right: 1em;
  overflow-y: auto;
  height: 100%;
  
}

.order-info-invoice {
  padding-right: 1em;
}

.line-break{
  border-bottom: 2px solid black;
}

.line-break-2{
  border-bottom: 2px solid black;
}

.detail-holder-info {
  max-width: 100%;
}

.side-order-name{
  padding-right: 2px
}

.price-holder{
  height: 50vh;
  overflow-y: auto;
}

.name-cust-price{
  border-bottom: 2px solid black;
  border-radius: 0 !important;
}

.control-item {
  background-color: hsl(44, 24%, 66%);
  border: 1px solid rgb(134, 134, 134);
  /* border-radius: 0.5em; */
}

.control-item.is-active{
  background-color: hsl(44, 100%, 77%) !important;
  border: 1px solid black;
  border-bottom: 0px !important;
  /* border-top-left-radius: 0.5em; */
  /* bold: 1px; */
  font-weight: bold;
  border-top-right-radius: 0.5em;
}

#customerBalance {
  border-top-right-radius: 0.5em;
}

.nowrap {
  white-space: nowrap;
}

.pack-table-title{
  /* flex-grow: 1; */
  width: 8%;
}

.pack-table-details {
  /* flex-grow: 9; */
  width: 92%;
}

.pack-table-cell{
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 0.2em;
}

.pack-table-cell-2{
  flex-grow: 2;
  flex-basis: 0;
  text-align: right;
  padding-right: 0.5em;
}



.details-with-ship-holder{
  overflow-y: auto;
}

.pack-table-tbody{
  border: 2px solid black;
  border-collapse: collapse;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
  
}

.pack-table-details-title{
  background-color: #aaaaaa;
  border-bottom: 2px solid black;
}

.pack-table-title {
  border-right: 2px solid black;
}

.pack-table-TR >.pack-table-cell-2 , .pack-table-O>.pack-table-cell-2, .pack-table-deliverTimeslot>.pack-table-cell-2, .pack-table-address>.pack-table-cell-2 {
  background-color: #d8d8d8;
}

.pack-table-SA, .pack-table-TR, .pack-table-TO, .pack-table-shipDis, .pack-table-shipper, .pack-table-deliverTimeslot {
  border-bottom: 1px solid black;
}

.pack-table-O {
  border-bottom: 2px solid black;
}

div.pack-table-tbody.is-active> div>.pack-table-details-title {
  background-color: hsl(44, 100%, 77%) !important;
}

.orderList-control-class, .customerBalance-control-class, .info-control-class, .details-control-class {
  display: none !important;
}
.orderList-control-class.is-active, .customerBalance-control-class.is-active, .info-control-class.is-active, .details-control-class.is-active {
  display: flex !important;
}

tr.is-active {
  background-color: hsl(44, 100%, 77%);
}

#wrapper {
  overflow-y: hidden;
  height: 100vh!important;
  /* scroll-behavior: unset; */
  
}

.note-holder{
  /* background-color: azure; */
  margin-left: 0.5em;
}

.textarea-note {
  border-radius: 0;
  border-right: 1px solid rgb(138, 138, 138);
  border-left: 1px solid rgb(138, 138, 138);
}

.top-addon {
  border: 1px solid rgb(138, 138, 138);
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom: 0px !important;
}
.bottom-addon {
  border-top: 0px !important;
  border: 1px solid rgb(138, 138, 138);
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  font-weight: bold;
}

.bottom-addon.disabled {
  color: rgb(161, 161, 161);
}

.form-input.is-active{
  color: #f18973;
}

.note-holder-scroll {
  overflow-y: auto;
}

td,th {
  font-size: 1rem;
}

#button-create-note.disabled{
  color: rgb(161, 161, 161);
  
}

#button-create-note.disabled:hover{
  cursor: no-drop !important;
}
.form-input-channel{
  font-size: 1.05em;
}

.non-exist{
  color: red;
}

#wrapper {
  overflow-y: hidden;
  height: 100vh!important;
  /* scroll-behavior: unset; */
  
}
#app {
  overflow-y: hidden;
  height: 100vh!important;
}

#shadowLL {
  overflow-y: hidden;
  height: 100vh!important;
}

.section {
  overflow-y: hidden;
  height: 95vh!important;
}

.no-flow-y {
  overflow-y: hidden;
  height: 100%!important;
}

.container {
  overflow-y: hidden;
  height: 100%!important;
}



.totalBeforeDiscount-pack-price, .finalAmt-pack-price{
  font-weight: bold;
}

.week_str-pack-price {
  padding-left: 2px;
}

.transactions-list.is-active {
  background-color: hsl(44, 100%, 77%) !important;
}

.autocomplete-results-holder {
  position: absolute;
  max-height: 25vh !important;
  background-color: white;
  width: 100%;
  overflow-y: auto;
  border: 1px solid rgb(179, 179, 179);
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}


.autocomplete-result {
  list-style: none;
  text-align: left;
  
  cursor: pointer;
  overflow-x: wrap;
  padding-bottom: 0.5em;
}

.autocomplete-result:hover {
  background-color: hsl(44, 88%, 91%) ;
}

.autocomplete-result.is-active {
  background-color: hsl(44, 88%, 91%) ;
}

.custinfo_cust_phone-remove {
  position: absolute;
  z-index: 1000;
  padding-left: 1em;
  padding-top: 0.8em;
  cursor: pointer;
  color: red;
}

#custinfo_cust_phone{
  padding-left: 2.5em;
}

.custAdd-control.is-clickable:hover {
  color: red;
}

.custAdd-control.is-disabled {
  color: rgb(161, 161, 161);
}


.is-holiday{
  background-color: #cccccc;
}

#button-create-note.disabled {
  background-color: hsl(44, 24%, 66%)!important;
  color: rgb(53, 53, 53);
}

#button-create-note {
  background-color: hsl(44, 100%, 77%)!important;
}

.form-input-type-holder {
  border-left: #aaaaaa 1px solid;
  border-right: #aaaaaa 1px solid;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.25); /* IE */
  -moz-transform: scale(1.25); /* FF */
  -webkit-transform: scale(1.25); /* Safari and Chrome */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
}

.group_main_info {
  overflow-y: auto;
  padding-left: 0px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding-right: 0px;
  padding-top: 0px;
}

.table-transaction-height {
  height: 42vh;
  /* overflow-y: auto; */
}

.table-main-order-list-height {
  height: 42vh;
  /* overflow-y: auto; */
}

.table-address-height {
  height: 45.2vh;
  /* overflow-y: auto; */
}

.table-note-height {
  height: 60.8vh;
  /* overflow-y: auto; */

}

#table-main-note td {
  font-size: 1.1rem;
}

.current-week-notes {
    background-color: #f7cac9 !important;
  }


@media (-webkit-min-device-pixel-ratio: 1.15) {

  /* .textarea-note {
    background-color: #f7cac9;
    height: 5vh !important;
    max-height: 5vh !important;
  } */

  .form-input-type-holder.is-size-5 {
    font-size: 1em !important;
  }

  .form-input-type-holder {
    border : 0px solid black !important;
  }

  .form-input-channel-holder.is-size-5 {
    font-size: 1em !important;
  }
  .week_id-note-week_str {
    font-size: 1em !important;
  }

  .table-transaction-height {
    height: 38vh !important;
  /* overflow-y: auto; */
  }

  .table-main-order-list-height{
    height: 38vh !important;
  }

  .table-address-height {
    height: 34.8vh !important;
  }

  .table-note-height{
    height: 48vh !important;
  }

  #table-main-order-list td {
    font-size: 1rem !important;
  }

  #table-transactions td {
    font-size: 1rem !important;
  }

  #table-main-note td {
    font-size: 1rem !important;
  }

  .current-week-notes {
    background-color: #f7cac9 !important;
  }


}

</style>

<style>

#table-main-note thead{
  display: none !important;
}

#table-group-address thead {
  display: none;
}

#table-group-address td {
  font-size: 1.1rem;

}

#table-group-address tr {
  height: auto !important;
}

#table-main-order-list td {
  font-size: 1.1rem;
}

#table-main-order-list thead>tr>th:nth-child(6)>div {
  text-align: right;
  width: 100%;
  flex-direction: row;
  align-content: end;
  flex-wrap: wrap;
}

#table-main-order-list thead>tr>th:nth-child(6)>div>span{
  width: 100%;
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 0.5em;
}
#table-transactions td {
  font-size: 1.1rem;
}

#table-transactions thead>tr>th:nth-child(5)>div {
  text-align: right;
  width: 100%;
  flex-direction: row;
  align-content: end;
  flex-wrap: wrap;
}

#table-transactions thead>tr>th:nth-child(5)>div>span{
  width: 100%;
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 0.5em;
}

#table-transactions thead>tr>th:nth-child(4)>div>span {
  width: 100%;
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 0.5em;
}

#table-transactions thead>tr>th:nth-child(6)>div>span {
  width: 100%;
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 0.5em;
}

table thead {
  font-size: 1rem;
}

</style>