import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CSNoteView from '../views/CSNoteView.vue'
import OrderHistView from '../views/OrderHistView.vue'
import LoginView from '../views/LoginView.vue'
import CustOperationView from '../views/CustOperationView.vue'
import DataLoad from '../views/DataLoad.vue'
import ControlPage from '../views/ControlPage.vue'
import InBill from '../views/InBill.vue'
import InShip from '../views/InShip.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/csnote',
    name: 'CSNoteView',
    component: CSNoteView,
    meta: { requiresAuth: true }
  },
  {
    path: '/custoperation',
    name: 'CustOperationView',
    component: CustOperationView,
    meta: { requiresAuth: true ,
      requiresPlaning: true
    }
  }
  ,
  {
    path: '/orderhistory',
    name: 'OrderHistView',
    component: OrderHistView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dataload',
    name: 'DataLoad',
    component: DataLoad,
    meta: { 
      requiresAuth: true ,
      requiresPlaning: true
    
    }
  }
  ,
  {
    path: '/controlpage',
    name: 'ControlPage',
    component: ControlPage,
    meta: { 
      requiresAuth: true ,
      requiresPlaning: true
    
    }
  },
  {
    path: '/inbill',
    name: 'InBill',
    component: InBill,
    meta: { 
      requiresAuth: true ,
    }
  },
  {
    path: '/inship',
    name: 'InShip',
    component: InShip,
    meta: { 
      requiresAuth: true ,
    }
  }
]

const router = createRouter({
  currentURL: '',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach')
  // console.log(store)
  
  if ((to.matched.some(record => record.meta.requiresAuth)) && !(store.getters.isAuthenticated) ) {
    next({name: 'login', query: { to: to.path }})
    
  } else {
    next()
    
  }

})

export default router
