import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  
  state: {
    isAuthenticated: false,
    isLoading: false,
    isPlaning: false,
    isCashAccountant: false,
    token: null,
    user: null,
    errorLogin: null,
    activeURL: '',
    // userGroups: [],
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated, 
    StateUser: state => state.user,
    isPlaning: state => state.isPlaning,
    isCashAccountant: state => state.isCashAccountant,
    
  },

  mutations: {
    
    initializeStore(state) {
      console.log('initializeStore')
      // console.log(this)

      if (localStorage.getItem('lastLogin')) {
        if (Date.now() - localStorage.getItem('lastLogin') > (1000 * 60 * 60 * 48 * 1)) {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('lastLogin')
          localStorage.removeItem('userGroups')
        }  
      } else {
        console.log('lastLogin not found')
      }

      if (localStorage.getItem('token')) {

        axios.defaults.headers.common['Authorization'] = "Token " + localStorage.getItem('token')
        this.commit('setToken', localStorage.getItem('token'))
        this.commit('setUser', localStorage.getItem('user'))
        localStorage.getItem('userGroups').split(',').includes('1') ? this.commit('setIsPlaning', true) : this.commit('setIsPlaning', false)
        localStorage.getItem('userGroups').split(',').includes('3') ? this.commit('setIsCashAccountant', true) : this.commit('setIsCashAccountant', false)
        

      } else {
        this.commit('removeToken')
        this.commit('setUser', null)
        this.commit('setIsPlaning', false)
        this.commit('setIsCashAccountant', false)
        axios.defaults.headers.common['Authorization'] = ""
      }

    },

    setIsLoading(state,status) {
      state.isLoading = status
    },

    setIsPlaning(state,status) {
      state.isPlaning = status
    },

    setIsCashAccountant(state,status) {
      state.isCashAccountant = status
    },

    setErrorLogin(state, error) {
      state.errorLogin = error
    },

    setUser(state, username){
      state.user = username
    },

    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },  

    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    }, 


  },

  actions: {
    
    async LogIn (context, usercredentials) {
      const formData = {
        username: usercredentials.username,
        password: usercredentials.password
      }

      await axios
        .post('/api/v1/token/login', formData, {method: 'POST'})
        .then(response => {      
          // console.log(response.data)    
          context.commit('setToken', response.data.auth_token)
          context.commit('setUser', usercredentials.username)
          axios.defaults.headers.common["Authorization"] = "Token " + response.data.auth_token
          localStorage.setItem('token', response.data.auth_token)
          localStorage.setItem('user', usercredentials.username)
          localStorage.setItem('lastLogin', Date.now())

          let returnData =  {success: true}
          context.commit('setErrorLogin', returnData)

        })
        .catch(error => {
          if (error.response) {
            let returnData = error.response.data
            context.commit('setErrorLogin', returnData)
          }
          else {
            let returnData =  {success: false, message: error.message}
            context.commit('setErrorLogin', returnData)
          }
        })

        await axios
        .get('/api/v1/user/?username=' + usercredentials.username)
        .then(response => {
          localStorage.setItem('userGroups', response.data['groups'])
          if (response.data['groups'].includes(1)) {  context.commit('setIsPlaning', true) } else {  context.commit('setIsPlaning', false) }
          if (response.data['groups'].includes(3)) {  context.commit('setIsCashAccountant', true) } else {  context.commit('setIsCashAccountant', false) }

        }).catch(error => {
          
          console.log(error)
        })
      

    },


    
    async LogOut(context){
      
      context.commit('removeToken')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('lastLogin')
      localStorage.removeItem('userGroups')
      axios.defaults.headers.common['Authorization'] = ""
      context.commit('setErrorLogin', {success: 'logout'})

    }

  },
  
  modules: {
  }

})
