<template>
  
    <div class="is-flex-grow-1 is-flex pl-2 pt-2  mb-3 is-flex-direction-row is-justify-content-center is-align-content-center"  style="width: 100vw !important;">
      <div>
        <div>
          <h1 class="title is-1">In Ship</h1>
        </div>
        <div class="is-flex is-flex-direction-row mt-2">
          <div class="title is-1">  Tuần   </div> 

        </div>
        <div class="is-flex is-flex-direction-row">
          <div class="is-1 mt-2 select is-link field"> 
            <div class="control">  
              <select id="week_chosen_id" > 
                <option v-bind:value="this.weeks[1].id">{{this.weeks[1].start_date_id}} </option> 
                <option selected v-bind:value="this.weeks[2].id">{{this.weeks[2].start_date_id}} </option> 
                <option v-bind:value="this.weeks[3].id">{{this.weeks[3].start_date_id}} </option> 
              </select> 
            </div>
          </div>
        </div>
        <div class="is-flex mb-2 mt-2 is-flex-direction-row">
          <div class="select is-link field"> 
            <div class="control"> 
              <select id="day_in_ship" > 
                <option v-bind:value="this.daySelect[0]">{{this.daySelect[0]}} </option> 
                <option v-bind:value="this.daySelect[1]">{{this.daySelect[1]}} </option> 
                <option v-bind:value="this.daySelect[2]">{{this.daySelect[2]}} </option> 
                <option v-bind:value="this.daySelect[3]">{{this.daySelect[3]}} </option> 
                <option v-bind:value="this.daySelect[4]">{{this.daySelect[4]}} </option> 
              </select> 
            </div> 
          </div>
        </div>
        <div class="is-flex is-flex-direction-row mb-2 mt-2">
          <div class="select is-link field"> 
            <div class="control"> 
              <select id="template_id" > 
                <option value="1">Mẫu 1 </option> 
                <option value="2">Mẫu 2 </option> 
              </select> 
            </div> 
          </div>
        </div>
        <div class="is-flex is-flex-direction-column mb-2 mt-2">
          <div class="p-0">
            <input class="input mb-2" type="text" id="arg1" placeholder="Tên_viết_tắt:tên nước">
            <input class="input mb-2" type="text" id="arg3" placeholder="Tên_viết_tắt:tên nước">
            <input class="input mb-2" type="text" id="arg5" placeholder="Tên_viết_tắt:tên nước">
            
          </div>
          <div class="p-0">
            <input class="input mb-2" type="text" id="arg2" placeholder="F:Lời nhắn cho toàn bộ shipper">
            <input class="input mb-2" type="text" id="arg4" placeholder="C:Lời nhắn cho toàn bộ shipper CONO">
          </div>
          <div class="ml-2 is-flex-grow-1 column is-one-third p-0">
            
          </div>
          
        </div>
        <div class="mt-3">
          <div class="buttons">
            <button class="button is-info" @click="InShip">In</button>
          </div>
        </div>
      </div>
      
    </div>

  
  
</template>

<script>
import axios from 'axios'
import { VSelect} from 'vuetify/components'
export default {
  name: 'In Ship',
  components: {
    VSelect
  },
  data () {
    return {
      weeks: [ [],[],[],[],[],[],[] ],
      daymap: {
        'Thứ 2':2,
        'Thứ 3':3,
        'Thứ 4':4,
        'Thứ 5':5,
        'Thứ 6':6,
      },
      daySelect: ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6'],
      day: 0,
      
    }
  },

  computed: {
    
  },

  async mounted () {
    document.title = 'In Ship'
    const d = new Date();
    let defArray = {
      0: 2,
      1: 3,
      2: 4,
      3: 5,
      4: 6,
      5: 2,
      6: 2
    }
    this.day = defArray[d.getDay()] ;
    await this.get7weeks()
    
    
    

    let all_options = document.getElementById('day_in_ship').options
    all_options[this.day-2].selected = true
  }, 

  methods : {

    getWeekStr (week_id_chosen) {
      week_id_chosen = parseInt(week_id_chosen)
      return this.weeks.find(week => week.id === week_id_chosen).start_date_id.toString().slice(4,8)
    },

    async get7weeks() {
      // console.log('get7weeks')
      // console.log(axios.defaults)
      
      await axios
        .get('/api/v1/last-4-week/')
        .then(response => {
          const result = response.data
          var i = 0
          result.forEach(element => {
            element.stt = 'week' + i.toString(),
            i++
          });
          this.weeks = result
          return result
        }
        )
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
        })
    },


    postSlacks(message) {
      console.log(message)
      let form = new FormData()
      form.append('text', message)
      axios
        .post('https://slacks.fitfood.io.vn/hooks/zjoycrxstt8o7g16mygzj3wnhy', form)
        .then((response) => {
          // console.log(response)
        })
        .catch((error) => {
          // console.log(error)
        })
    },

    async InShip (event) {
      // console.log('InShip')
      
      // console.log(this.day)
      let week_id_chosen = document.getElementById('week_chosen_id').value
      let chosen_day_ = document.getElementById('day_in_ship').value
      let url = '/api/v1/in-ship/' + '?week_id=' + week_id_chosen+'&dayLabel=' + this.daymap[chosen_day_]+'&template='+ document.getElementById('template_id').value +'&user=' + localStorage.getItem('user')
      let holder = {}
      Array.from(['arg1','arg2','arg3','arg4','arg5']).forEach(id => {
        let input = document.getElementById(id)
        if (input.value) {
          try { holder[input.value.split(':')[0]] = input.value.split(':')[1] } catch (error) {
            // console.log(error)
          } 
        }
      })
      // console.log(holder)
      Object.keys(holder).forEach(key => {
        url += '&' + key + '=' + holder[key]
      })
      // console.log(url)
      this.$store.commit('setIsLoading', true)
      await axios
        .get(url, {responseType: 'blob'})
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.classList.add('is-hidden')
          // link.setAttribute('download', item.file.slice(-4)+'_'+week_id+'_'+Date.now().toString()+'.xlsx')
          link.setAttribute('download', 'In_ship_'+this.getWeekStr(week_id_chosen)+'_Thu_'+this.daymap[chosen_day_]+'_mau_'+document.getElementById('template_id').value+'_'+Date.now().toString()+'.xlsx')
          document.body.appendChild(link)
          this.$store.commit('setIsLoading', false)
          link.click()
          this.postSlacks( 'User ' + localStorage.getItem('user') + ' đã in ship tuần ' + this.getWeekStr(week_id_chosen) + ' ngày ' + chosen_day_ + ' mẫu ' + document.getElementById('template_id').value + ' thành công')
        })
        .catch(error => {
          try {
            window.alert(error.response.data)
          } catch (error) {
            window.alert('Lỗi không xác định')
          }
          
          this.$store.commit('setIsLoading', false)
          // console.log(error)
        })
    }

  },

    
  components: {
  }
}
</script>

<style scoped>


</style>
