<template>
  
    <div class="is-flex-grow-1 is-flex pl-2 pt-2 inbill-scroll mb-3"  style="width: 100vw !important;">
      <div class="about is-flex is-flex-direction-column bill-holder"   style="width: 80mm !important; overflow-y: auto;">
        <div class="p-2 is-flex is-flex-direction-row is-justify-content-center is-align-content-center is-align-items-center">
          <img class="logo" src="@/assets/logo500.png" alt="" >
          <!-- <img class="logo" src="https://fitfood.vn/images/logo-fitfood-mobile.png" alt="" > -->
          <div class="is-flex">
            <div>
              <strong class="brand pl-2">Fitfood VN</strong>
            </div> 
          </div>
        </div>
        <div class="pl-2 pr-2 pt-0 pb-0 is-flex is-flex-direction-row is-justify-content-center is-align-content-center is-align-items-center">
          <div class="is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center">
            <div>
              <strong class="title-invoice"> Details of purchases </strong> 
            </div>
            <div>
              <i class="note-no-payment"> (Not equivalent to invoice or proof of payment) </i>
            </div>
          </div>
        </div>
        <div class="pl-2 pr-2 pt-2 pb-0">
          <div> Name: <i class="no-italic has-text-weight-bold" v-bind:innerHTML="custName">  </i>
          </div>
          <div> Phone:  <i class="no-italic has-text-weight-bold" v-bind:innerHTML="custPhone">  </i></div>
        </div>
        <div id="all-invoices-items-holder">
          <div class="pl-2 pr-2 pt-2 pb-0 week-holder week-holder-template is-hidden">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between"> 
              <div class="week-name"> Tuần: 2024.01.29 </div>
              <div class="is-flex-frow-0 week-total-price"> </div>
            </div>
            <div class="pl-2 week-item-price-holder">
              <div class="is-flex is-flex-direction-row is-justify-content-space-between item-line-template is-hidden "> 
                <div class="is-flex-frow-1 item-price-description item-desc"> Gói FIT 15 meals </div>
                <div class="is-flex-frow-0 item-price-value item-price"> 200,000 </div>
              </div>
            </div>
          </div>
        </div>
        <div class="total-holder mb-2">
          <div class="pl-2 pr-2 pt-2 pb-0 week-holder week-holder-template">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between"> 
              <div class="has-text-weight-bold"> TOTAL </div>
              <div class="is-flex-frow-0 has-text-weight-bold" v-bind:innerHTML="formatedTotalDue">  </div>
            </div>
          </div>
        </div>
        <div class="thank-you-message is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center pb-2 pl-2 pr-2">
            <div class="has-text-centered">
              <i class="" v-bind:innerHTML="thankYou">  </i> 
            </div>
            <div>
              <i class="note-no-payment"  v-bind:innerHTML="thankYouBy">  </i>
            </div>
            <div>
              <i v-bind:class="[(totalBill===totalDue) ? 'is-hidden' : 'has-text-danger']"> BILL NÀY BỊ SAI  </i>
            </div>
        </div>
      </div>
    </div>

  
  
</template>

<script>
import axios from 'axios'

export default {
  name: 'InBill',
  data () {
    return {
      info: null,
      weeks: [],
      packs: [],
      ships: [],
      products: [],
      custPhone : '',
      custName : '',
      totalBill : 0,
      totalDue : 0,
      thankYou : 'Enjoy your meals!',
      thankYouBy : 'Fitfood VN Team',

    }
  },

  computed: {
    formatedTotalDue () {
      return this.formatPriceRound(this.totalDue)
    }
  },

  mounted () {
    document.title = 'Fitfood VN - Purchase History'
    document.getElementById('app')
    document.getElementsByTagName('html')[0].classList.remove('main-layout')
    document.getElementById('navbar-menu').parentNode.style.display = 'none'
    document.getElementsByTagName('body')[0].classList.remove('main-layout')
    document.getElementsByTagName('body')[0].classList.add('inbill-scroll')
    document.getElementById('app').classList.remove('main-layout')
    document.getElementById('wrapper').classList.remove('main-layout')
    document.getElementsByTagName('section')[0].classList.remove('main-layout')
    document.getElementsByClassName('container')[0].classList.remove('main-layout')
    try {
      let params = (new URL(document.location)).searchParams;
      let customer_id = params.get("customer_id");
      let packs = params.get("packs");
      // console.log(name)
      let url = '?customer_id=' + customer_id + '&packs=' + packs
      // console.log(url)
      this.getData(url)
    } catch (error) {
      window.alert('Không tìm thấy dữ liệu')
    }

    
    

    this.quotesDay()
    
  }, 
  methods : {
    formatPriceRound (price) {
      return Math.round(price).toLocaleString('en-US')
    },

    async quotesDay () {
      await axios
        .get('https://api.quotable.io/random?maxLength=40')
        .then(response => {
          // let todayInt = Math.floor(Math.random() * 10)
          this.thankYou = response.data.content
          this.thankYouBy = response.data.author
        })
        .catch(error => {
          1==1
          // console.log('error quote')
        })
    },

    upperFirst (string) {
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
    },

    // async getData (url) {
    //   await axios
    //     .get('/api/v1/in-bill-querry/' + url)
    //     .then(response => {
    //       this.weeks = response.data.weeks
    //       this.packs = response.data.packs
    //       this.custPhone = response.data.phone_number_readable
    //       this.custName = response.data.name

    //       let all_invoices_items_holder = document.getElementById('all-invoices-items-holder')
          
    //       this.weeks.forEach(week => {
    //         let weekItemHolder = []
    //         let allShipStrPrice = {}
    //         let allShipStrCount = {}  
    //         let shipPriceAdj = 0
    //         let discountHolder = {}

    //         let allPacks_in_week = this.packs.filter(pack => pack.week_id === week.id)
    //         allPacks_in_week.forEach(pack => {

    //           let packAlias = pack.packAlias
    //           if (pack.ycdb2 === 'Thêm phần ăn sáng dành cho gói MEAT LOVER') {
    //             packAlias = packAlias.replace('MEAT', 'MEAT-S')
    //           }
    //           //  stt , description , price , passable: will not render if price is 0
    //           weekItemHolder.push( [ 0  , 'Pack ' + packAlias + ' (' + pack.totalMeals +' meals)' , pack.packPrice_sys        , (pack.totalMeals>0) ? 1 : 0] )
    //           weekItemHolder.push( [ 1  , 'Pack Adjust ' + pack.packAlias                             , pack.packPriceAdjAmt      , 1] )
    //           weekItemHolder.push( [ 3  , 'Side Product Adjust ' + pack.packAlias                , pack.sidePriceAdjAmt      , 1] )
    //           weekItemHolder.push( [ 94 , (pack.otherDesc===null) ? '' :pack.otherDesc              , pack.otherAmt             , 1] )
    //           weekItemHolder.push( [ 95 , 'Special Request Fee (' + pack.packAlias + ')'           , pack.specialAmt           , 1] )

    //           shipPriceAdj += pack.shipPriceAdjAmt

    //           if (pack.discountAmt != 0) {
    //             if (Object.keys(discountHolder).includes(pack.discountCode)) {
    //               discountHolder[pack.discountCode] += pack.discountAmt
    //             } else {
    //             discountHolder[pack.discountCode] = pack.discountAmt
    //             }
    //           }

    //           let shipStrPrice = pack.shipStrPrice
    //           if (!((shipStrPrice === undefined) || (shipStrPrice === null))) {
    //             shipStrPrice = JSON.parse(pack.shipStrPrice)
    //             Object.keys(shipStrPrice).forEach(shipPackId => {
    //               let shipItem = shipStrPrice[shipPackId]
    //               Object.keys(shipItem).forEach(shipLoc => {
    //                 let shipPrice = shipItem[shipLoc]['price']
    //                 if (shipPrice > 0) {
    //                   if (Object.keys(allShipStrPrice).includes(shipLoc)) {
    //                     allShipStrCount[shipLoc] += shipItem[shipLoc]['count']
    //                     allShipStrPrice[shipLoc] += shipPrice
    //                   } else {
    //                     allShipStrPrice[shipLoc] = shipPrice
    //                     allShipStrCount[shipLoc] = shipItem[shipLoc]['count']
    //                   }
    //                 }
    //               });
    //             });
    //           } 

    //           let sideStrFull = pack['sideStrFull']
    //           if (!((sideStrFull === undefined) || (sideStrFull === null))) {
    //             sideStrFull = JSON.parse(pack['sideStrFull'])
    //             Object.keys(sideStrFull).forEach(sideName => {
    //               weekItemHolder.push( [ 2 ,  this.upperFirst( sideName ) + ' (' + sideStrFull[sideName]['quant'] + 'x)', sideStrFull[sideName]['total'] ,0] )
    //             });
    //           }
            
    //         })
            
    //         Object.keys(allShipStrPrice).forEach(shipLoc => {
    //           weekItemHolder.push( [ 4 , 'Shipping ' + shipLoc + ' (' + allShipStrCount[shipLoc] + 'x)', allShipStrPrice[shipLoc] ,0] )
    //         });

    //         Object.keys(discountHolder).forEach(discountCode => {
    //           weekItemHolder.push( [ 6 , 'Discount ' + discountCode , discountHolder[discountCode] ,1] )
    //         });

    //         weekItemHolder.push( [5 , 'Shipping Adjust' , shipPriceAdj , 1] )
    //         weekItemHolder = weekItemHolder.sort(function(a, b) { return a[0] - b[0]; });

    //         // render
    //         let weekHolder = document.getElementsByClassName('week-holder-template')[0].cloneNode(true)
    //         weekHolder.classList.remove('is-hidden')
            
    //         let week_total = 0

    //         weekItemHolder.forEach(item => {
    //           if ((item[3] == 1)&&(item[2] == 0)) {
    //             return
    //           }
    //           let priceLine = weekHolder.getElementsByClassName('item-line-template')[0].cloneNode(true)
    //           priceLine.classList.remove('is-hidden')
    //           priceLine.getElementsByClassName('item-desc')[0].innerHTML = item[1]
    //           priceLine.getElementsByClassName('item-price')[0].innerHTML = this.formatPriceRound(item[2])
    //           weekHolder.getElementsByClassName('week-item-price-holder')[0].appendChild(priceLine)
    //           week_total += item[2]
    //         })
    //         weekHolder.getElementsByClassName('week-name')[0].innerHTML = 'Week: ' + week.start_date_id.toString().substring(0,4) + '.' + week.start_date_id.toString().substring(4,6) + '.' + week.start_date_id.toString().substring(6,8)
    //         weekHolder.getElementsByClassName('week-total-price')[0].innerHTML = this.formatPriceRound(week_total)
    //         // console.log(weekItemHolder)

    //         all_invoices_items_holder.appendChild(weekHolder)
    //         this.totalDue += week_total
    //         // console.log(this.totalDue)
    //       })
    //     })
    //     .catch(error => {
    //       window.alert('kHÔNG TÌM ĐƯỢC DỮ LIỆU')
    //     })

    // }
    capitalizeEachWord(string){
        // console.log(string)
        const str_arr = string.split(' ')
        var i
        for(i = 0; i < str_arr.length; i++){
            str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1).toLowerCase()
        }
        return str_arr.join(' ')
    },

    async getData (url) {
        await axios
        .get('/api/v1/in-bill-querry/' + url)
        .then(response => {
          this.weeks = response.data.weeks
          this.totalBill = response.data.total_bill
          this.packs = response.data.packs
          this.ships = response.data.ships
          this.products = response.data.products
          this.custPhone = response.data.phone_number_readable
          this.custName = response.data.name
          // console.log(response.data)
          
          let all_invoices_items_holder = document.getElementById('all-invoices-items-holder')
          // this.weeks.reverse()
          this.weeks.forEach(week => {
            let weekItemHolder = []
            let all_packs_adj_in_week = this.packs.filter(pack => pack.week_id === week.id)
            all_packs_adj_in_week.forEach(pack => {
              weekItemHolder.push( [ 1   , 'Chỉnh giá Gói'    , pack['packPriceAdjAmt']   ,1] )
              weekItemHolder.push( [ 3   , 'Chỉnh giá SP phụ' , pack['sidePriceAdjAmt']   ,1] )
              weekItemHolder.push( [ 91  , 'Chỉnh giá ship'   , pack['shipPriceAdjAmt']   ,1] )
              weekItemHolder.push( [ 95  , 'Giảm giá'         , pack['discountAmt']       ,1] )
            })

            let all_products_in_week = this.products.filter(product => product.week_id === week.id)
            let refArray = {
              'pack' : 0,
              'sides' : 2,
              'other' : 92
            }

            all_products_in_week.forEach(product => {
              var productName 
              try {
                productName = this.capitalizeEachWord(product['productName']) 
              } catch (error) {
                productName = product['productName']
              }
              weekItemHolder.push( [ refArray[product['productType']], 
                                      productName+ ((product['productType']==='other') ? '' : ' (' + product['quant'] + 'x)') , 
                                      product['total'] , 
                                      ((product['total']!=0)|| (product['quant'] !=0)) ? 0 : 1
                                    ] )
            })

            let all_ships_in_week = this.ships.filter(ship => ship.week_id === week.id)
            all_ships_in_week.forEach(ship => {
              weekItemHolder.push( [  90 , 
                                      'Shipping ' + ship['shipDis'] + ' (' + ship['count'] + 'x)', 
                                      ship['price'] ,
                                      ((ship['price']!=0) || (ship['count'] !=0)) ? 0 : 1
                                    ] )
            });
            
            weekItemHolder = weekItemHolder.sort(function(a, b) { return a[0] - b[0]; });
            
            // render
            let week_total = 0
            let weekHolder = document.getElementsByClassName('week-holder-template')[0].cloneNode(true)
            weekHolder.classList.remove('is-hidden')

            weekItemHolder.forEach(item => {
              if ((item[3] == 1)&&(item[2] == 0)) {
                return
              }
              let priceLine = weekHolder.getElementsByClassName('item-line-template')[0].cloneNode(true)
              priceLine.classList.remove('is-hidden')
              priceLine.getElementsByClassName('item-desc')[0].innerHTML = item[1]
              priceLine.getElementsByClassName('item-price')[0].innerHTML = this.formatPriceRound(item[2])
              weekHolder.getElementsByClassName('week-item-price-holder')[0].appendChild(priceLine)
              week_total += item[2]
            })
            weekHolder.getElementsByClassName('week-name')[0].innerHTML = 'Week: ' + week.start_date_id.toString().substring(0,4) + '.' + week.start_date_id.toString().substring(4,6) + '.' + week.start_date_id.toString().substring(6,8)
            weekHolder.getElementsByClassName('week-total-price')[0].innerHTML = this.formatPriceRound(week_total)
            // console.log(weekItemHolder)
            all_invoices_items_holder.appendChild(weekHolder)
            this.totalDue += week_total
            // console.log(weekItemHolder)
          })
        })
    }
    
  },
  components: {
  }
}
</script>

<style scoped>

.item-desc{
  font-size: 4mm;
  color: black;
  font-style: italic;
}

.item-price{
  font-size: 4mm;
  color: black;
  font-style: italic;
}

.week-name{
  font-size: 4mm;
  color: black;
  font-weight: bold;
}

.week-total-price{
  font-size: 4mm;
  color: black;
  font-weight: bold;
}
.no-italic {
  font-style: normal;
}

.note-no-payment {
  font-size: 3.5mm;
  color: black;
}
.bill-holder {
  border: 1px solid black;
}
.title-invoice{
  font-size: 5mm;
  color: black;
  font-weight: bold;
}

@media print {
  body {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.brand {
  font-size: 7mm;
  color: black;
  font-weight: bold;
}

img.logo {
  width: 50px;
  height: 50px;
}
</style>
