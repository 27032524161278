<template>
  <div class="is-overlay has-background-dark has-text-centered is-flex is-justify-content-center is-justify-items-center pt-15" style="width: 100vw; height: 100%; z-index: 999; opacity: 0.4; justify-content: center; align-items: center;" v-bind:class="{'is-hidden':!$store.state.isLoading}"> 
    <div style="width:50vw" >
      <h1 class="title has-text-white">Loading...</h1>
      <progress class="progress is-small is-primary " max="100" style="max-width:50vw">25%</progress>
    </div>
    
  </div>
  <div id="wrapper" class="is-flex is-flex-direction-column is-align-items-stretch main-layout" >
    <nav class="navbar is-dark">

      <div class="navbar-menu" id="navbar-menu" >
        <div class="column is-flex navbar-end pt-0 pb-0">
          <!-- <router-link to="/CS" class="navbar-item">CS</router-link> -->
          <!-- <router-link to="/users" class="navbar-item">Users</router-link> -->
          <div class="navbar-start">
            <!-- theo thứ tự tạo bên file route.js -->
            <div class="is-flex is-justify-content-center is-align-items-center">
              <img class="logo" src="@/assets/logo500.png" alt="" >
            </div>
            
            
            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn">
              <router-link to="/" style="border-radius:15px" class="button is-link is-outlined"  > Home </router-link>  
            </div>
            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn">
              <router-link to="/csnote" style="border-radius:15px" class="button is-link  is-outlined"  v-if="isLoggedIn" >CS Notes </router-link>
            </div>
            
            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn">
              <router-link to="/orderhistory" style="border-radius:15px" class="button is-link   is-outlined"  v-if="isLoggedIn">Order Hist </router-link>
            </div>

            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn   &&  ((!(isCashAccountant||localIsCashAccountant))||(isCashAccountant&&isPlaning))">
              <router-link to="/inship" style="border-radius:15px" class="button is-link   is-outlined"  v-if="isLoggedIn">In Ship </router-link>
            </div>

            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn && (isPlaning||localIsPlanning)">
              <router-link to="/custoperation" style="border-radius:15px" class="button is-link   is-outlined"  v-if="isLoggedIn" >Cust Op </router-link>
            </div>

            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn && ((isPlaning||localIsPlanning)||(isCashAccountant||localIsCashAccountant))">
              <router-link to="/dataload" style="border-radius:15px" class="button is-link   is-outlined"  v-if="isLoggedIn" >Data Load </router-link>
            </div>
            <div class="navbar-item p-0 mr-1" v-if="isLoggedIn && ((isPlaning||localIsPlanning)||(isCashAccountant||localIsCashAccountant))">
              <router-link to="/controlpage" style="border-radius:15px" class="button is-link   is-outlined"  v-if="isLoggedIn" >Control Page </router-link>
            </div>
            
            <!-- {{ this.$store.state.token  }} -->
          </div>
          
          <div class="is-flex is-flex-direction-row is-align-items-center">
            <div>
                
            </div>
            <div class="mr-3 " v-if="isLoggedIn">
              <div class="has-text-weight-bold">
                {{ this.$store.state.user }}
              </div>
            </div>
            <div class="" v-if="isLoggedIn">
              <a class="button is-light is-clickable" @click="logout">
                Log Out
              </a>  
            </div>
            <div class="" v-else>
              <a href="/login" class="button is-light is-clickable">
                Log in
              </a>  
            </div>
            
          </div>
        </div>
      </div>
    </nav>
    <section class="section m-0	p-0 is-flex is-flex-direction-column main-layout" >
      <div class="container is-fluid m-0 p-0 is-flex main-layout"  >
        <router-view>

        </router-view>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

import { ref } from 'vue'

export default {
  
  data() {
    return {
      localIsPlanning: false,
      localIsCashAccountant: false,
      localIsAuthen: false,
      
    }
  },

  async beforeCreate() {

    await this.$store.commit('initializeStore')

    if (this.$store.getters.isAuthenticated) {
      // this is to check token current validity
      await axios
        .get('/api/v1/user/?username=' + localStorage.getItem('user'))
        .then(response => {
          localStorage.setItem('userGroups', response.data['groups'])
          response.data['groups'].includes(1) ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
          response.data['groups'].includes(3) ? this.$store.commit('setIsCashAccountant', true) : this.$store.commit('setIsCashAccountant', false)
        })
        .catch(error => {          
          if (error.response.status == 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
        })  

    } else {
      
    }

    
    // var width = document.documentElement.clientWidth * window.devicePixelRatio;
    // let viewport = document.querySelector("meta[name=viewport]");
    // viewport.setAttribute('content', 'width=' + width + ', minimum-scale= 1');
    // document.documentElement.style.transform = `scale(${1 / window.devicePixelRatio})`;
    // document.documentElement.style.transformOrigin = 'top left';


  },

  async beforeMount() {
    // console.log('beforeMount APP')
    try {
      localStorage.getItem('userGroups').split(',').includes('1') ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
      localStorage.getItem('userGroups').split(',').includes('3') ? this.$store.commit('setIsCashAccountant', true) : this.$store.commit('setIsCashAccountant', false)

      localStorage.getItem('userGroups').split(',').includes('1') ? this.localIsPlanning = true : this.localIsPlanning = false
      localStorage.getItem('userGroups').split(',').includes('3') ? this.localIsCashAccountant = true : this.localIsCashAccountant = false

    } catch (error) {  1 == 1 }
    
    try { localStorage.getItem('token') ? (this.localIsAuthen=true) : (1==1) } catch (error) { 1 == 1 }
    
  },

  mounted() {

    // if (this.localIsAuthen) {
    //   axios
    //     .get('/api/v1/user/?username=' + localStorage.getItem('user'))
    //     .then(response => {
    //       localStorage.setItem('userGroups', response.data['groups'])
    //       response.data['groups'].includes(1) ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
    //       response.data['groups'].includes(3) ? this.$store.commit('setIsCashAccountant', true) : this.$store.commit('setIsCashAccountant', false)
          

    //       // localStorage.getItem('userGroups').split(',').includes('1') ? this.localIsPlanning = true : this.localIsPlanning = false
    //       // localStorage.getItem('userGroups').split(',').includes('3') ? this.localIsCashAccountant = true : this.localIsCashAccountant = false

    //     })
    //     .catch(error => {          
    //       if (error.response.status == 401) {
    //         this.$store.dispatch('LogOut')
    //         this.$router.push('/login')
    //       }
    //     })  

    // } else {
      
    // }
    

  },

  updated() {
  },

  

  computed : {
      isLoggedIn : function(){ 
      
        return this.$store.getters.isAuthenticated
      },
      
      isPlaning : function(){ 
       
        return this.$store.getters.isPlaning
      },

      isCashAccountant : function(){ 
       
       return this.$store.getters.isCashAccountant
     },

      // testComputed: function() {
      //   return this.$store.getters.activeURL
      // }
      
  },

  methods: {

    async logout () {
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
      window.location.reload()
    }

    

  }
}

</script>

<style lang="scss">
@import '../node_modules/bulma/' 


</style>
<style lang="scss">



#wrapper.main-layout {
  overflow-y: hidden; 
  height: 100vh; 
  align-items: stretch;
}
.navbar{
  height: 5vh;
}


.router-link-active {
  background-color: hsl(44, 100%, 77%) !important;
  font-weight: bold;
  color: rgb(63, 63, 63)!important;
  border-radius: 15px;
}


a:not(.router-link-active).is-link.is-outlined {
  color: rgb(185, 185, 185) !important;
  border-color: rgb(150, 150, 150) !important;
  font-weight: bold !important;;
}

section.main-layout {
  flex-grow: 1;
  max-height: 95vh;
  overflow-y: hidden;
}

.container.main-layout {
  flex-grow: 1; 
  max-height: 95vh; 
  overflow-y: hidden;
}

.navbar-item:hover {
  color: rgb(63, 63, 63) !important;
  border-color: rgb(63, 63, 63) !important;
}

a:not(.router-link-active).is-link.is-outlined:hover  {
  background-color: hsl(44, 24%, 66%) !important;
  color: rgb(63, 63, 63) !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

td {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  font-size:1.1rem;
  height: auto !important;;
  // text-align: center;
}

thead:not(.thead-special-format) th {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  font-size:1.1rem;
  height: auto !important;;
  // text-align: center;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

</style>

<style scoped>
.logo {
  height: 4vh;
  margin-right: 5px;
}





</style>
