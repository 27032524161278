<template>
  <div class="column" >
    <div class="column">
      <div class="column p-0 is-flex is-justify-content-center">
        <div class="column pt-0 is-flex is-four-fifths" >
          
          <div class="field has-addons pr-2">
            <p class="control">
              <a class="button is-static">
                Process
              </a>
            </p>
            <p class="control">
              <!-- <input class="input" type="text" placeholder="Paste Only" @change="preventInput" @paste="processPaste"> -->
              <input class="input" type="text" placeholder="Paste Only" @change="preventInput" @paste="querryInfoMass">
            </p>
          </div> 

          
            <div class="field has-addons pr-2">
              <p class="control">
                <a class="button is-static">
                  Update
                </a>
              </p>
              <p class="control">
                <input class="input" type="text" placeholder="Paste Only" @change="preventInput" @paste="processUpdate" >
              </p>
              <p class="control">
                <a class="button is-info" @click="processSubmit">
                  Submit
                </a>
              </p>
            </div> 

        </div>
      </div>
    </div>
    <div class="table-holder is-flex is-justify-content-center">
      <v-card class="table-container" style="height: 83vh; width:80vw">
          <v-data-table-virtual
            density="compact"
            id="table-main"
            :headers="headers"
            :items="items"          
            fixed-header
            sortable: false
            items-per-page="1000"
            height="83vh"
          >
          <template  #item="{ item }">
            <tr>
              <td > {{ item.phone }}</td>
              <td > {{ item.name }}</td>
              <td > {{ item.address }}</td>
              <td > {{ item.email }}</td>
              <td > {{ item.is_main }}</td>
              <td > {{ item.target }}</td>
              
            </tr>
          </template >

          </v-data-table-virtual>

      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VDataTable,VCard,VTextField,VDataTableVirtual,VFooter,
} from 'vuetify/components'
export default {
  components: {
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
  },

  data() {
    return {
      tableIsLoading: false,
      items: [],
      firstClick: false,
      headers : [{
            align: 'start',
            key: 'phone',
            title: 'Phone',
            sortable: false,
          },
          {
            align: 'start',
            key: 'name',
            title: 'Name',
            sortable: false,
          },
          {
            align: 'start',
            key: 'address',
            title: 'Address',
            sortable: false,
          },
          {
            align: 'start',
            key: 'email',
            title: 'Email',
            sortable: false,
          },
          {
            align: 'start',
            key: 'is_main',
            title: 'is main?',
            sortable: false,
          },
          {
            align: 'start',
            key: 'target',
            title: 'Target',
            sortable: false,
          }
          
        ],
    }
  },

  methods: {   
    preventInput (event) {
      event.srcElement.value = ''
    },
    
    processPaste (event) {

      let data = []
      let clipboardData = event.clipboardData.getData('Text') || window.clipboardData.getData('Text');
      event.preventDefault()
      let newline_reg = new RegExp('\r')
      clipboardData = clipboardData.replace(/[`~!#$%^&*()_|+=?;:'"<>\{\}\[\]]/g,'')
      clipboardData = clipboardData.split(newline_reg)

      if (clipboardData.length>1100) {
        window.alert('Lỗi: chỉ hổ trợ 1100 dòng')
        data = []
        event.preventDefault()
        return false
      }

      let tab_regex = new RegExp('\t')
      let any_word = new RegExp('[aoeuiy]')

      for (let i=0;i<clipboardData.length;i++) {
        // không xử lý dòng cuối cùng vì chỉ chứa "/n"
        // console.log(i)
        // console.log(clipboardData[i])
        // chỉ sử lý dòng có chứa dữ liệu
        if (clipboardData[i].search(any_word)>-1) {
          
          // check xem 1 dòng có đủ 4 cột dữ liệu không
          let this_line = clipboardData[i].split(tab_regex)
          if (this_line.length!=4) {
            window.alert('Lỗi: chỉ chấp nhận 4 cột dữ liệu tại dòng ' + (i+1))
            return false
          }

          // check xem cột phone có đúng 10 số không
          this_line[0] = this_line[0].replace(/[^0-9]/g,'')
          if (this_line[0].length<10) {
            window.alert('Lỗi: số điện thoại 10 số đến 12 số - dữ liệu tại dòng ' + (i+1))
            return false
          }
          data.push(this_line)
          

        }
      }
      return data
      
    },

    async querryInfoMass(event) {
      // you must call setIsLoading inside this asynce function
      this.$store.commit('setIsLoading', true)

      let data = this.processPaste(event)
      if (data==false) {
        this.$store.commit('setIsLoading', false)
        return false
      }
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
        .post('/api/v1/mass-cust-querry-state/', {cust_list_to_querry: data}, {responseType: 'blob'})
        .then((response) => {
          this.items = []
          // console.log(Date.now().toString())
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.classList.add('is-hidden')
          link.setAttribute('download', 'querry_result_'+Date.now().toString()+'.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          // console.log(error)
          // this.$store.commit('setLoading', false)
        })

      this.$store.commit('setIsLoading', false)
    },
      
    processUpdate (event) {
      let clipboardData = event.clipboardData.getData('Text') || window.clipboardData.getData('Text');
      let newline_reg = new RegExp('\r')
      clipboardData = clipboardData.replace(/[`~!#$%^&*()_|+=?;:'"<>\{\}\[\]]/g,'')
      clipboardData = clipboardData.split(newline_reg)
      if (clipboardData.length>1100) {
        window.alert('Lỗi: chỉ hổ trợ 1100 dòng')
        event.preventDefault()
        return
      }
      let tab_regex = new RegExp('\t')
      let any_word = new RegExp('[aoeuiy]')
      let data = []
      let keys = {
        0: 'phone',
        1: 'name',
        2: 'address',
        3: 'email',
        4: 'is_main',
        5: 'target',
      }

      for (let i=0;i<clipboardData.length;i++) { 
        if (clipboardData[i].search(any_word)>-1) {
          let this_line = clipboardData[i].split(tab_regex)
          if (this_line.length!=6) {
            window.alert('Lỗi: chỉ chấp nhận 6 cột dữ liệu tại dòng ' + (i+1))
            event.preventDefault()
            return
          }
          this_line[0] = this_line[0].replace(/[^0-9]/g,'')
          this_line[5] = this_line[5].replace(/[^0-9]/g,'')
          if (this_line[0].length<10) {
            window.alert('Lỗi: số điện thoại 10 số đến 12 số - dữ liệu tại dòng ' + (i+1))
            data1 = []
            e.preventDefault()
            return
          }
          if (this_line[5].length<10) {
            window.alert('Lỗi: số điện thoại 10 số đến 12 số - dữ liệu tại dòng ' + (i+1))
            data1 = []
            e.preventDefault()
            return
          }
          let item = {}
          let k = 0
          this_line.forEach(element => {
            item[keys[k]] = element
            k = k + 1
          })

          data.push(item)
        }
      }


      // console.log(data)
      this.items = data
      // this.$store.commit('setIsLoading', true)
    },

    async processSubmit () {
      this.$store.commit('setIsLoading', true)
      const formData = {
        'cust_list_to_update': this.items,
        'last_edited_by_id': this.$store.state.user,
      }
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
        .post('/api/v1/mass-cust-create/', {formData})
        .then((response) => {
          window.alert('Success')
          this.items = []
          // console.log(response)
          // this.items = []
          // console.log(Date.now().toString())
          // const url = window.URL.createObjectURL(new Blob([response.data]))
          // const link = document.createElement('a')
          // link.href = url
          // link.classList.add('is-hidden')
          // link.setAttribute('download', 'querry_result_'+Date.now().toString()+'.xlsx')
          // document.body.appendChild(link)
          // link.click()
        })
        .catch(error => {
          let error_data = ''
            for (let key in error.response.data) {
              error_data += key + ': ' + error.response.data[key] + '\n'
            }
            window.alert(error_data)
          // this.$store.commit('setLoading', false)
        })

      this.$store.commit('setIsLoading', false)
    },

  },
  
  beforeMount() {
    try {  if ( !(
                  (this.isPlaning)
                  ) ) { 
      this.$router.push('/') } } catch (error) {  1 == 1 }

    
    axios
      .get('/api/v1/user/?username=' + localStorage.getItem('user'))
      .then(response => {
        localStorage.setItem('userGroups', response.data['groups'])
        response.data['groups'].includes(1) ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
        response.data['groups'].includes(3) ? this.$store.commit('setIsCashAccountant', true) : this.$store.commit('setIsCashAccountant', false)
        
        if ( !((response.data['groups'].includes(1) || response.data['groups'].includes(3))) 
          ) { 
            this.$router.push('/')
            // console.log('redirect')
            window.location.reload()
            // console.log('reload')
        
        }
      })
      .catch(error => {       
        if (error.response.status == 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }   
          this.$router.push('/')
          // console.log('redirect')
          window.location.reload()
          // console.log('reload')
      })  

    
  },

  async mounted () {

    document.title = 'Customer Operations'
    
    
  },

  computed: {
    isPlaning : function(){ 
       
       return this.$store.getters.isPlaning
     },
  },
  
  
  updated () {
    // console.log('updated')
  

  },
}

</script>

<style>
::-webkit-input-placeholder { /* WebKit browsers */
  color: #FF0000;
  opacity: 1 !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #FF0000;
  opacity: 1 !important;
}

::-moz-placeholder {  /* Firefox 19+ */
  color: #FF0000;
  opacity: 1 !important;
}

:-ms-input-placeholder {  
  color: #FF0000;
  opacity: 1 !important;
}
</style>