import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VDataTable,VCard,VTextField, VDataTableVirtual, VFooter ,VSwitch} from 'vuetify/components'
// import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
    VSwitch
  })


axios.defaults.baseURL = 'https://api.fitfood.io.vn'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/'
// axios.defaults.baseURL = 'http://192.168.3.29:8000/'
// axios.defaults.baseURL = 'http://209.97.168.205:8000/'

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // console.log('interceptor error')
  // console.log(error)

  try  {
    if (error.response.status === 401) {
      store.dispatch('LogOut')
      router.push('/login')
    }
  } catch {

  }
  return Promise.reject(error)
})

createApp(App).use(store).use(router, axios).use(vuetify).mount('#app')
