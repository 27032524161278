<template>
  <div class="login column pt-10">
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <h1 class="title"> Log in </h1>
        <form @submit.prevent="submitForm">
          <div class="field">
            <label class="label">UserName</label>
            <div class="control">
              <input class="input" type="username" placeholder="username" autocomplete="off" v-model="username">
            </div>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <input class="input" type="password" readonly placeholder="Password" onfocus="this.removeAttribute('readonly');" autocomplete="off" v-model="password">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="column" v-if="errors.length">
                <div class="notification is-danger" v-for="error in errors" :key="error">
                  {{ error }}
                </div>
              </div>
              <button class="button is-primary" @click="submitFormLogin">Login</button>
            </div>
            
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'LoginView',
  data () {
    return {
      username: '',
      password: '',
      errors: []
    }
  },
  beforeCreate () {
   
    const token = this.$store.state.token

    if (token) {
      this.$router.push('/')
    } 
  },
  mounted () {
    document.title = 'Login'
  },

  methods: {
    async submitFormLogin () {
      // console.log('submitFormLogin')
      this.$store.commit('setIsLoading', true)
      axios.defaults.headers.common['Authorization'] = ""
      // localStorage.removeItem('token')

      const formData = {
        username: this.username,
        password: this.password
      }

      try {
        await this.$store.dispatch('LogIn', formData)
        let toPath = this.$route.query.to || '/'
        this.$router.push(toPath)
        // window.location.reload()

      } catch (e) {
        // console.log(e)
        this.errors.push(e)
      }

      this.errors = []
      
      for (const property in this.$store.state.errorLogin) {
        this.errors.push(`${property}: ${this.$store.state.errorLogin[property]}`)
      }

      // console.log('submitFormLoginEnd')
      this.$store.commit('setIsLoading', false)
      
    }
  }
}

</script>
