<template>
  <div class="column is-flex" >
      <div class="column is-one-fifth has-background-light cust_focus_info_wrapper" style="max-height: 100%;" v-bind:class="{'is-hidden':!showCustInfor}">
        <div class="column is-flex pl-0 pr-0 pt-0.5 pb-0.5 cust_focus_info_title has-background-warning">
          <div class="column has-text-left p-0">
            <p> <strong>CUSTOMER INFO</strong></p>
          </div>
          <div class="column has-text-right pt-0 pb-0">
            <i class="fa-regular fa-x is-clickable p-0" @click="showCustInfor=!showCustInfor"></i>
          </div>
        </div>
        <div class="column pl-0 pr-0 group_main_info" style="border-bottom: 3px solid gray;">
          <div class="column p-0">
            <span> <strong> Name: </strong>  </span>
            <span class="group_main_info_name">
            </span>
          </div>
          <div class="column p-0">
            <span> <strong> Main Address: </strong>  </span>
            <span class="group_main_info_address">
            </span>
          </div>
          <div class="column p-0">
            <span> <strong> Main Phone: </strong>  </span>
            <span class="group_main_info_phone">
            </span>
          </div>
        </div>
        <!-- start of other ADDRESS -->
        <div class="column is-flex p-0 group_address_title has-background-warning">
          <div class="column has-text-left pt-1 pl-0 pr-0 pb-1">
            <p> <strong>OTHER ADDRESS</strong></p>
          </div>
        </div>
        <div class="column pl-0 pr-0 pt-0 pb-0 group_address" style="border-bottom: 3px solid gray; max-height: 25vh; overflow: hidden; overflow-y:auto">
          <div class="column pl-0 pr-0 pt-1 group_address_item is-hidden" style="border-top: 1px solid black;">
            <div class="column p-0">
              <span> <strong> Name: </strong>  </span>
              <span class="group_address_item_name">
                
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Address: </strong>  </span>
              <span class="group_address_item_address">
                
              </span>
            </div>
            <div class="column p-0">
              <span> <strong> Phone: </strong>  </span>
              <span class="group_address_item_phone">
              </span>
            </div>
          </div>
          

          

        </div>

        
        <!-- end of customer address -->

        <!-- end of customer notes -->
        
        <div class="column is-flex p-0 group_notes_title has-background-warning" >
          <div class="column has-text-left pt-1 pl-0 pr-0 pb-1" >
            <p> <strong>NOTES HISTORY</strong></p>
          </div>
        </div>
        <div class="column pl-0 pr-0 pt-0 pb-0 group_notes" style="border-bottom: 3px solid gray; max-height:40vh; overflow: hidden; overflow-y:auto">

          <div class="column pl-0 pr-0 pt-1 group_note_item is-hidden" style="border-top: 1px solid black;">
            <div class="column p-0">
              
              <span> Week:  </span><strong> 
              <span class="week_id"> </span></strong> 
              <div>
                <span class="note_content"> </span>
              </div>
              
            </div>
          </div>
          

          

          

        </div>
        
        
      </div>

      <!-- end of customer info -->
      <div class="column is-one-fifth has-background-light" v-bind:class="{'is-hidden':!showCreateCust}">
        <div class="column is-flex pl-0 pr-0 pt-0.5 pb-0.5 cust_focus_info_title ">
          <div class="column has-text-left p-0">
            <p> <strong>CUSTOMER CREATE</strong></p>
          </div>
          <div class="column has-text-right pt-0 pb-0">
            <i class="fa-regular fa-x is-clickable" @click="resetCustCreateForm"></i>
          </div>
        </div>
        <!-- <div class="has-text-right" >
          <i class="fa-regular fa-x is-clickable" @click="showCreateCust = !showCreateCust"></i>
        </div> -->
        <!-- <form @submit.prevent="submitForm"> -->
        <form @submit.prevent="submitForm">
          <div class="field">
            <label class="label">Customer Phone</label>
            <div class="control">
              <input class="input" type="text" placeholder="customer_phone" id='createCust_cust_phone' autocomplete="nofill"   @change="formPhoneChange" >
            </div>
          </div>
          <div class="field">
            <label class="label">Customer Name</label>
            <div class="control">
              <textarea class="input" type="text" placeholder="customer_name"  id='createCust_cust_name' autocomplete="nofill" style="resize: none;"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <textarea class="input" type="text"  placeholder="email" id="createCust_cust_email" autocomplete="nofill"  style="resize: none;"></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <textarea class="input" style="height: 4rem;" type="text" id='createCust_cust_address' placeholder="customer_address"  > </textarea>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="column" v-if="createCustFormErrors.length">
                <div class="notification is-danger"  >
                  <p v-for="error in createCustFormErrors" v-bind:key="createCustFormErrors"> {{ error }}</p>
                </div>
              </div>
              <!-- <button class="button is-primary" @click="submitFormLogin">Login</button> -->
              <div class="is-flex is-justify-content-end">
                <button class="button is-warning" @click="submitFormCreateCust"> CREATE CUSTOMER </button>
              </div>
              
            </div>
          </div>
        </form>
      </div>
      <div class="column is-one-fifth has-background-light" v-bind:class="{'is-hidden':!massCloseWindow}">
        
        <div class="column is-flex pl-0 pr-0 pt-0.5 pb-0.5 cust_focus_info_title ">
          <div class="column has-text-left p-0 is-two-thirds">
            <p class="is-size-5"> <strong>MASS CLOSE FORM</strong></p>
          </div>
          <div class="column has-text-right pt-0 pb-0">
            <i class="fa-regular fa-x is-clickable" @click="resetMassClose"></i>
          </div>
        </div>

        <div class="has-text-left mt-1 pb-2" style="border-bottom:  1px solid grey;">
          <h1><strong> GOOD TO CLOSE </strong></h1>  
          <div class="is-flex is-flex-wrap-wrap" id="good_to_close_holder" style="min-height: 1rem; max-height: 20vh; overflow: auto;">
          </div>

        </div>
        <div class="has-text-left pb-2 mt-2" style="border-bottom:  1px solid grey">
          <h1> <strong>ALREADY TO CLOSED </strong></h1>  
          <div class="is-flex is-flex-wrap-wrap" id="bad_to_close_holder" style="min-height: 1rem;  max-height: 20vh; overflow: auto;">
            
          </div>
        </div>
        <div class="has-text-left pb-2 mt-2" style="border-bottom:  1px solid grey">
          <h1> <strong> NOT IN WEEK </strong></h1>  
          <div class="is-flex is-flex-wrap-wrap" id="not_in_week_holder" style="min-height: 1rem;  max-height: 20vh; overflow: auto;">
            
          </div>
        </div>
        <div class="is-flex is-justify-content-end mt-2">
          <button disabled id="button_for_mass_close" class="button is-warning" @click="massClosePut"> MASS CLOSE </button>
        </div>
        <div class="is-hidden" id="mass-close-item-holder"> 
          <i class="mt-1 mb-0 ml-0 mr-1 pt-0 pb-0 pl-1 pr-1" style="border: 1px solid grey; border-radius: 4px;">111111</i>  
          <input id="mass_close_holder_for_form" class="has-background-danger"/>
        </div>
      </div>
      <div class="column pt-0" id="main-ui">
        <div class="column is-flex pt-0">
          <div class="column is-flex is-one-fifth">
            <div class="column m-0 p-0">
              <a class="button mr-2" id="custinfo" @click="custInfo"> Cust Info</a>
              <a class="button" id="newcust" @click="newCust"> New Cust</a>
            </div>
          </div>
          <div class="column is-two-fifths is-flex-grow-1">
            <button v-for="item in weeks" ref="7weeksBtn" class="button is-clickable 7weeks mr-1" :key="item.id" :id="item.stt" :ref="item.stt" :value="item.id"  @click="updateVtable">
              {{ item.start_date_id.toString().substring(6, 8) + '.' +  item.start_date_id.toString().substring(4, 6)}}
            </button>
          </div>

          <div class="column is-flex is-flex-direction-row is-one-fifth">
            <div class="is-flex is-align-items-center is-justify-content-center pr-3">
              <label class="checkbox">
                <input type="checkbox" v-model="strike">
                Strike
              </label>
            </div>
            <div class="field has-addons">
              <div class="control">
                <input class="input" type="text" placeholder="Search complete input" v-model="search">
              </div>
              <div class="control">
                <a class="button is-static">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </a>
              </div>
              
            </div>
            
          </div>

          <div class="column" v-show="isPlaningCS" >
            
            <input class="input is-disabled" type="text" id="input_for_mass_close" placeholder="Mass Close: only Paste" @paste="massCloseControl" @change="massClosePreventInput" :disabled="massCloseWindow == 1">
            
          </div>
          
          <div class="column" id="export" v-show="isPlaningCS">
            <button class="button" @click="exportNotes">
              Export
            </button> 
            
          </div>
          

          
        </div>
        <v-card class="table-container table-full-note-list-height" >
          <v-data-table
            id="table-main"
            class="table-full-note-list-height"
            :headers="headers"
            :items="items"
            :search="searchTrigger"
            fixed-header
            sortable: false
            items-per-page="50"
            
          >
            <template  #item="{ item }">
              <tr v-bind:class="[(item.is_completed==1) ? 'completed-line' : '', this.striked ? 'strike' :'']" >
                <td class="note-ids " sortable=false
                
                > {{ item.id }}</td>
                <td sortable:false 
                
                > {{ item.customer_phone }} 

                <i class="fa-solid fa-external-link is-size-7" @click="gotoHistory" style="color:blue; cursor: pointer;"></i>
              
              </td>
                <td sortable:false> {{ item.customer_name }}</td>
                <td> {{ item.channel_id }}</td>
                <td> {{ item.description }}</td>
                <td> {{ item.type }}</td>
                <td> {{ item.is_month_order==1 ? 'YES' : 'NO'}} </td>
                <td>  <button class="button m-0 pt-0 pb-0 btn-trigger-put-note" @click="put_and_getnotes" style="height: 2rem;" 
                  v-bind:class="[(item.is_completed==1) ? 'has-background-success-light' :'has-background-danger-light']"
                  :disabled="!(isPlaningCS||localIsPlanning)"
                  > {{ item.is_completed_string}}</button> </td>
                <td> {{ item.created_by_id }}</td>
                
                <td> {{ item.week_id }}</td>
                <td> {{ item.created }}</td>
              </tr>
            </template >
          </v-data-table>

        </v-card>
      </div>
      

  </div>

  <!-- fake elements -->
  
  
</template>

<script>
import axios from 'axios'
import { VDataTable,VCard,VTextField,VDataTableVirtual,VFooter,} from 'vuetify/components'
export default {
  name: 'CSNoteView',
  components: {
    VDataTable,
    VCard,
    VTextField,
    VDataTableVirtual,
    VFooter,
  },

  data() {
    return {
      strike: true,
      
      templateThead: `
            <thead id="formthead-template" class="is-hidden thead-special-format">
              <tr>
                <th class="pl-0">
                  <div class="field"> 
                    <div class="control pt-2 pl-2"> 
                      <input class="input customer_id" id="customer_id" type="number" placeholder="custid" required="" disabled="" autocomplete="nofill"> </div>
                    </div>
                  </th>
                  <th class="pl-0 pr-0 pb-0  pt-2">
                    <input class="input customer_phone" id="customer_phone" type="text" placeholder="phone" required="" autocomplete="nofill">
                  </th>
                  <th>
                    <div class="field"> 
                      <div class="control"> 
                        <textarea class="input p-1 name is-size-6" id="name" type="text" style="height:4rem; min-width:8vw; overflow-wrap: break-word; overflow-x: auto; resize: none;" placeholder="name" disabled=""> </textarea> 
                      </div> 
                    </div>
                  </th>
                  <th class="pl-0 pr-0 pb-0  pt-2">
                    <div class="select is-link field"> 
                      <div class="control"> 
                        <select class="channel_id" id="channel_id"> 
                          <option value="phone"> phone </option> 
                          <option value="facebook">facebook</option> 
                          <option value="other">other</option> 
                        </select> 
                      </div> 
                    </div>
                  </th>
                  <th>
                    <div class="field"> 
                      <div class="control"> 
                        <textarea class="input description" id="description" type="text" placeholder="Description" style="height:4rem;min-width:8vw; overflow-wrap: break-word; overflow-x: auto; resize: none;" value=""> </textarea> 
                      </div> 
                    </div>
                  </th>
                  <th class="pl-0 pr-0 pb-0  pt-2">
                    <div class="select is-link field"> 
                      <div class="control"> 
                        <select id="type_id" class="type_id">
                          <option value="order">order</option> 
                          <option value="payment">payment</option> 
                          <option value="ship">ship</option> 
                          <option value="cancel">cancel</option> 
                          <option value="other"> other </option>
                        </select> 
                      </div>
                    </div>
                  </th>
                  <th class="pl-2 pr-0 pb-0  pt-2">
                    <div class="field" style="height:100%"> 
                      <div class="control"> 
                        <label class="checkbox">  
                          <input id="is_month_order" class="is_month_order" type="checkbox">MO</label> 
                        </div>
                      </div>
                    </th>
                    <th>
                      <!-- intented blank -->
                    </th>
                    <th class="pl-0 pr-0 pb-0  pt-2">
                      <div class="field"> 
                        <div class="control"> 
                          <input class="input last_edited_by_id" id="last_edited_by_id" type="text" value="" placeholder="id" style="width:5vw" disabled> 
                        </div> 
                      </div>
                    </th>
                    <th class="pl-0 pr-0 pb-0  pt-2">
                      <div class="field"> 
                        <div class="control"> 
                          <input class="input week_id" type="text" id="week_id" value="" placeholder="id" style="width:5vw" disabled="">  
                        </div> 
                      </div>
                    </th>
                    <th class="pl-0 pr-0 pb-0  pt-2">
                      <div class="control"> 
                        <button id="submitCreateNewNote" class="button has-background-grey submitCreateNewNote"> Create </button> 
                      </div>
                    </th>
                  </tr>
            </thead>
      `,
      localIsPlanning: false,
      showCustInfor: false,
      showCreateCust: false,
      massCloseWindow: false,
      weeks: [],
      items: [],
      firstClick: false,
      search: '',
      createCustFormErrors: [],
      createNoteErrors: [],
      headers : [{
            align: 'start',
            key: 'id',
            title: 'id',
            sortable: false,
          },
          {
            align: 'start',
            key: 'customer_phone',
            title: 'phone',
            sortable: false,
          },
          {
            align: 'start',
            key: 'customer_name',
            title: 'Name',
            sortable: false,
          },
          {
            align: 'start',
            key: 'channel_id',
            title: 'channel',
            sortable: false,
          },
          {
            align: 'start',
            key: 'description',
            title: 'description',
            sortable: false,
          },
          {
            align: 'start',
            key: 'type',
            title: 'type',
            sortable: false,
          },
          {
            align: 'start',
            key: 'is_month_order',
            title: 'MO',
            sortable: false,
          },
          {
            align: 'start',
            key: 'is_completed_string',
            title: 'done',
            sortable: false,
          },
          {
            align: 'start',
            key: 'created_by_id',
            title: 'by',
            sortable: false,
          },
          {
            align: 'start',
            key: 'week_id',
            title: 'week_id',
            sortable: false,
          },
          {
            align: 'start',
            key: 'created',
            title: 'created',
            sortable: false,
          },
          
        ],
    }
  },

  methods: {   
    
    gotoHistory (event) {
      let phone = event.srcElement.parentElement.innerHTML.substring(0, 10)
      const routeData = this.$router.resolve( {name: 'OrderHistView', query: {
                                                                              q: phone ,
                                                                              week_id: document.getElementsByClassName('is-selected-week')[0].value
                                                                              }}
                                                        );
      window.open(routeData.href, '_blank');

      
    },

    searchChange (event) {
        this.search = event.srcElement.value
    },

    add_is_completed_str() {
      for (let i = 0; i < this.items.length; i++) {
            this.items[i]['is_completed_string'] = this.items[i].is_completed == 1 ? 'done' : 'pending'
          }
    },

    async getnotes(week_id) {
      this.$store.commit('setIsLoading', true)
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
        .get('/api/v1/notes-by-week/?week_id='+week_id)
        .then(response => {
          this.items = response.data   
          this.add_is_completed_str()
          
          // console.log(this.items)

        })
        .catch(error => {
          if (error.response.status === 401) {
            // console.log('error.response.status')
            // console.log(error.response.status)
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
        })

        this.$store.commit('setIsLoading', false)
    },

    async put_and_getnotes(event) {
      this.$store.commit('setIsLoading', true)
      
      let note_id = parseInt(event.srcElement.parentElement.parentElement.getElementsByClassName('note-ids')[0].innerHTML)

      let is_completed = 1
      if (event.srcElement.innerHTML == 'pending') {
        is_completed = 0
      }
      let last_edited_by_id = this.$store.state.user
      let formData = {
        id: note_id,
        is_completed: !is_completed,
        last_edited_by_id: last_edited_by_id,
      }

      // console.log(formData)
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
        .put('/api/v1/note-put-and-get/', formData)
        .then(response => {
          this.items = response.data
          this.add_is_completed_str()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
        })

        this.$store.commit('setIsLoading', false)
    },

    async get7weeks() {
      // console.log('get7weeks')
      // console.log(axios.defaults)
      
      await axios
        .get('/api/v1/last-4-week/')
        .then(response => {
          const result = response.data
          var i = 0
          result.forEach(element => {
            element.stt = 'week' + i.toString(),
            i++
          });
          this.weeks = result
          return result
        }
        )
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.dispatch('LogOut')
            this.$router.push('/login')
          }
        })
    },

    resetCustInfo() {
      document.getElementById('name').value = ''
      // document.getElementById('formthead')
      document.getElementById('customer_id').value = ''
      document.getElementsByClassName('group_main_info_name')[0].innerHTML = ''
      document.getElementsByClassName('group_main_info_address')[0].innerHTML = ''
      document.getElementsByClassName('group_main_info_phone')[0].innerHTML = ''
      document.getElementsByClassName('group_notes_title')[0].classList.remove('is-hidden') 
      document.getElementsByClassName('group_address_title')[0].classList.remove('is-hidden') 

      let group_address = document.getElementsByClassName('group_address_item')
      Array.from(group_address).forEach(element => {
        if (element.classList.contains('is-hidden')) {
          return
        }
        element.remove()
      });

      let group_notes = document.getElementsByClassName('group_note_item')
      Array.from(group_notes).forEach(element => {
        if (element.classList.contains('is-hidden')) {
          return
        }
        element.remove()
      });

    },

    formPhoneChange (event) {
      
      let myString = event.srcElement.value
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      event.srcElement.value = myString
    },

    descriptionChange (event) {
      let myString = event.srcElement.value
      myString = myString.trim()
      event.srcElement.value = myString
    },

    async phoneChanged (event) {
      Array.from(['customer_phone', 'customer_id', 'name', 'last_edited_by_id', 'description', 'week_id']).forEach(element => {
        document.getElementById(element).classList.remove('has-background-danger-light')
      });
      // this.$store.commit('setIsLoading', true)
      
      // console.log(event.srcElement)
      
      let myString = event.srcElement.value
      // console.log('myString0')
      // console.log(myString)
      myString = myString.replace(/[^\d]/g, "")
      myString = myString.substring(0, 10)
      
      
      event.srcElement.value = myString

      this.resetCustInfo()
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
      .get('/api/v1/customerRef/?main_phone='+myString)
      .then(response => {
        let result = response.data
        // console.log(result)
        document.getElementById('name').value = result.name
        document.getElementById('customer_id').value = result.id
        document.getElementsByClassName('group_main_info_name')[0].innerHTML = result.name
        document.getElementsByClassName('group_main_info_address')[0].innerHTML = result.main_address
        document.getElementsByClassName('group_main_info_phone')[0].innerHTML = result.main_phone

        let group_address = result.customer_addresses
        
        if (group_address.length == 1) {
          document.getElementsByClassName('group_address_title')[0].classList.add('is-hidden') 
        }

        group_address.forEach(item => {
          if (item.is_main === 1) {
            return 
          }

          let new_address = document.getElementsByClassName('group_address_item is-hidden')[0].cloneNode(true)
          new_address.classList.remove('is-hidden')
          new_address.getElementsByClassName('group_address_item_name')[0].innerHTML = item.name
          new_address.getElementsByClassName('group_address_item_address')[0].innerHTML = item.address_detail
          new_address.getElementsByClassName('group_address_item_phone')[0].innerHTML = item.phone_number
          document.getElementsByClassName('group_address')[0].appendChild(new_address)

        });


        let group_notes = result.notes
        if (group_notes.length == 0) {
          document.getElementsByClassName('group_notes_title')[0].classList.add('is-hidden') 
        }
        let last_week_id = 0
        group_notes.forEach(item => {
          
          let new_note = document.getElementsByClassName('group_note_item is-hidden')[0].cloneNode(true)

          if (item.week_id == last_week_id) {
            new_note.style.borderTop = '0px'
          } 
          
          new_note.classList.remove('is-hidden')
          new_note.getElementsByClassName('week_id')[0].innerHTML = item.week_id
          new_note.getElementsByClassName('note_content')[0].innerHTML = item.description
          document.getElementsByClassName('group_notes')[0].appendChild(new_note)
          last_week_id = item.week_id
        });

      })
      .catch(error => {
        this.resetCustInfo()
        
      })

    },

    appendForm(week_id){

      let tbl = document.getElementById('table-main').getElementsByTagName('table')[0]
      // tbl.setAttribute('style', 'border-collapse: collapse;')
      Array.from(tbl.getElementsByTagName('thead')).forEach(element => {
        if (element.id == 'formthead') {
          element.remove()
        }
      });

      let tbody = tbl.getElementsByTagName('tbody')[0]
      let newTHead = document.createElement('thead')
      newTHead.id = 'formthead'
      newTHead.classList.add('thead-special-format')
      newTHead.innerHTML = this.templateThead
      tbl.insertBefore(newTHead, tbody)

      // document.getElementById('customer_phone').value = null
      document.getElementById('description').value = null
      // document.getElementById('name').value = null

      document.getElementById('description').addEventListener('change', (event) => { this.descriptionChange(event)})
      document.getElementById('last_edited_by_id').value = this.$store.state.user
      document.getElementById('week_id').value = week_id
      document.getElementById('customer_phone').addEventListener('change', (event) => { this.phoneChanged(event)})
      document.getElementById('submitCreateNewNote').addEventListener('click', (event) => { this.submitCreateNote(event)})
      
    },  

    updateVtable(event) {
      
      event.srcElement.classList.add('has-background-warning')
      event.srcElement.classList.add('is-selected-week')
      Array.from(document.getElementsByClassName('7weeks')).forEach(element => {
        if (element.id != event.srcElement.id) {
          element.classList.remove('has-background-warning')
          element.classList.remove('is-selected-week')
        }
      });

      this.getnotes(event.srcElement.value)

      this.appendForm(event.srcElement.value)

    },

    newCust() {
      if (this.showCreateCust) {
        this.showCreateCust = false
        return
      }

      document.getElementById('main-ui').addEventListener('click', this.tempEventClickMainUI,true)

      this.showCreateCust = true
      this.showCustInfor = false
      this.massCloseWindow = false
      
    },

    custInfo() {
      if (this.showCustInfor) {
        this.showCustInfor = false
        return
      }
      this.showCreateCust = false
      this.showCustInfor = true
      this.massCloseWindow = false
      
    },
    
    resetMassClose () {
      document.getElementById('good_to_close_holder').innerHTML = ''
      document.getElementById('bad_to_close_holder').innerHTML = ''
      document.getElementById('not_in_week_holder').innerHTML = ''
      document.getElementById('mass_close_holder_for_form').value = ''
      document.getElementById('button_for_mass_close').disabled = true
      this.massCloseWindow = false
    },

    resetCustCreateForm () {
      document.getElementById('createCust_cust_name').value = ''
      document.getElementById('createCust_cust_phone').value = ''
      document.getElementById('createCust_cust_address').value = ''
      document.getElementById('createCust_cust_email').value = ''

      this.createCustFormErrors = []
      // console.log(this.createCustFormErrors)
      // this.createCustFormErrors.push('TID')
      this.showCreateCust = false
    },

    tempEventClickMainUI(event) {
      // console.log('event.target.id')
      this.massCloseWindow = false
      this.showCreateCust = false
      this.resetMassClose()
      this.resetCustCreateForm()
      document.getElementById('main-ui').removeEventListener('click', this.tempEventClickMainUI,true)
    },

    massCloseControl(event) {

      // console.log('massCloseControl')

      document.getElementById('main-ui').addEventListener('click', this.tempEventClickMainUI,true)

      let clipboardData = event.clipboardData.getData('Text') || window.clipboardData.getData('Text');
      clipboardData = clipboardData.replace(/[^0-9,\n]/g,"").trim().split("\n")
      // console.log('clipboardData');
      // console.log(clipboardData);
      let good_to_close = {}
      let bad_to_close = {}
      let not_in_items = {}
      // console.log(this.items)
      var reftable = {}
      let item
      clipboardData.forEach(element => { 
         item = this.items.find(o => o.id == element);
          if (item) {
            if (item.is_completed == 1) {
              bad_to_close[element] = item
            } else {
              good_to_close[element] = item
            }
          } else {
            not_in_items[element] = element
          }
      })
      // console.log('good_to_close.length')
      // console.log(good_to_close.length)
      if (Object.keys(good_to_close).length==0)  {
        window.alert('Error: Không có note nào thỏa điều kiện để close')
        return
      }

      this.showCreateCust = false
      this.showCustInfor = false
      this.massCloseWindow = true

      
      let good_to_close_holder = document.getElementById('good_to_close_holder')
      let bad_to_close_holder = document.getElementById('bad_to_close_holder')
      let not_in_week_holder = document.getElementById('not_in_week_holder')
      let mass_close_holder_for_form = document.getElementById('mass_close_holder_for_form')
      
      let item_template = document.getElementById('mass-close-item-holder').getElementsByTagName('i')[0].cloneNode(true)


      // console.log('noteItem LOG')
      for (let key in good_to_close) {
       let new_item = item_template.cloneNode(true)
        new_item.innerHTML = key
        good_to_close_holder.appendChild(new_item)
      }
      for (let key in bad_to_close) {
       let new_item = item_template.cloneNode(true)
        new_item.innerHTML = key
        bad_to_close_holder.appendChild(new_item)
      }
      for (let key in not_in_items) {
       if (parseInt(key)>0) {
        let new_item = item_template.cloneNode(true)
        new_item.innerHTML = key
        not_in_week_holder.appendChild(new_item)
       }
      }
      
      mass_close_holder_for_form.value = Object.keys(good_to_close).join(',')
      document.getElementById('button_for_mass_close').disabled = false
      
    },

    async massClosePut() {
      const formData = { 
        ids : document.getElementById('mass_close_holder_for_form').value,
        user : localStorage.getItem('user')
      }
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      this.$store.commit('setIsLoading', true)
      axios
        .put('/api/v1/mass-close-note-and-get/', formData)
        .then(response => {
          this.items = response.data
          this.add_is_completed_str()
          this.resetMassClose()
          this.$store.commit('setIsLoading', false)
        })
        .catch(error => {
          window.alert('Error: ' + error.response.data)
          this.$store.commit('setIsLoading', false)
        })

    },

    async exportNotes() {
      this.$store.commit('setIsLoading', true)
      let week_id = document.getElementsByClassName('is-selected-week')[0].value
      // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
      await axios
        .get('/api/v1/export-by-week/?week_id='+week_id, {responseType: 'blob'})
        .then(

          (response) => {
            // console.log(Date.now().toString())
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.classList.add('is-hidden')
            link.setAttribute('download', 'notes_'+week_id+'_'+Date.now().toString()+'.xlsx')
            document.body.appendChild(link)
            link.click()
          }
        )
        this.$store.commit('setIsLoading', false)
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    async submitFormCreateCust () {
      
      this.createCustFormErrors = []

      if (document.getElementById('createCust_cust_name').value === '') {
                this.createCustFormErrors.push('The customer Name is missing')
            }
      if (document.getElementById('createCust_cust_phone').value === '') {
                this.createCustFormErrors.push('The customer Phone is missing')
            }
      if (document.getElementById('createCust_cust_address').value === '') {
                this.createCustFormErrors.push('The customer Address is missing')
            }
      if (document.getElementById('createCust_cust_email').value === '') {
                this.createCustFormErrors.push('The customer Email is missing')
            }
      if (document.getElementById('createCust_cust_phone').value.length != 10) {
                this.createCustFormErrors.push('The customer Phone must have 10 digits')
            }
      if (document.getElementById('createCust_cust_address').value.length < 10) {
                this.createCustFormErrors.push('The customer Address Address must have at least 10 characters')
            }
      
      if (!this.validateEmail(document.getElementById('createCust_cust_email').value)) {
                this.createCustFormErrors.push('The customer Email is not valid')
            }

      // console.log(this.createCustFormErrors)
      
      if (this.createCustFormErrors.length) {
        return
      } else {
        const formData = {
                name: document.getElementById('createCust_cust_name').value,
                main_address: document.getElementById('createCust_cust_address').value,
                main_phone: document.getElementById('createCust_cust_phone').value,
                email:document.getElementById('createCust_cust_email').value,
                last_edited_by_id: this.$store.state.user,
                }
        // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
        await axios
          .post('/api/v1/customer/', formData)
          .then(response => {
            
            document.getElementById('customer_phone').value = response.data['main_phone']
            
            this.createCustFormErrors = []
            let fake_event = {
              srcElement: document.getElementById('customer_phone')
            }
            this.phoneChanged(fake_event)

            this.resetCustCreateForm()

          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.dispatch('LogOut')
              this.$router.push('/login')
            }
            this.createCustFormErrors.push(error.response.data)
            // console.log(error.response.data)
          })
      }
    },

    async submitCreateNote () {
      
      this.createNoteErrors = []

      if (document.getElementById('customer_phone').value === '') {
                this.createNoteErrors.push('customer_phone')
            }
      if (document.getElementById('customer_id').value === '') {
          this.createNoteErrors.push('customer_id')
      }
      if (document.getElementById('name').value === '') {
          this.createNoteErrors.push('name')
      }
      if (document.getElementById('last_edited_by_id').value === '') {
          this.createNoteErrors.push('last_edited_by_id')
      }

      if (document.getElementById('description').value == '') {   
          this.createNoteErrors.push('description')
      }
      if (document.getElementById('week_id').value === '') {
          this.createNoteErrors.push('week_id')
      }
      if (this.createNoteErrors.length) {
        this.createNoteErrors.forEach(element => {
          document.getElementById(element).classList.add('has-background-danger-light')
        });
        return
      } else {
        let order_month = 0
        if  (document.getElementById('is_month_order').checked) { 
          order_month = 1
        }

        const success_name = document.getElementById('name').value

        const formData = {
                customer_phone: document.getElementById('customer_phone').value,
                customer_id: document.getElementById('customer_id').value,
                name: document.getElementById('name').value,
                channel_id: document.getElementById('channel_id').value,
                description: document.getElementById('description').value,
                type: document.getElementById('type_id').value,
                is_month_order: order_month,
                last_edited_by_id: document.getElementById('last_edited_by_id').value,
                week_id: document.getElementById('week_id').value,
                orderpackage_id : 'note từ csnote view',
                }
        this.$store.commit('setIsLoading', true)
        // axios.defaults.baseURL = 'https://api.fitfood.io.vn'
        await axios
          // .post('/api/v1/note/', formData)
          .post('/api/v1/create-note-and-get/', formData)
          .then(response => {
            
            this.items = response.data
            this.add_is_completed_str()
            // console.log(this.items)
            
            // let success = response.data
            // success['customer_name'] = document.getElementById('name').value
            
            // this.items.unshift(success)



            this.appendForm(document.getElementsByClassName('is-selected-week')[0].value)
            this.resetCustInfo()
            this.$store.commit('setIsLoading', false)
            // do IsLoading nên mất focus, nếu roll liền sẽ không chạy table => phải di chuột 1 cái mới roll đc
            //  fake bằng focus bào formthead
            document.getElementById('formthead').focus()
            // console.log(Date.now())
          })
          .catch(error => {

            if (error.response.status === 401) {
              this.$store.dispatch('LogOut')
              this.$router.push('/login')
            }
            
            let error_data = ''
            for (let key in error.response.data) {
              error_data += key + ': ' + error.response.data[key] + '\n'
            }
            window.alert(error_data)

            this.$store.commit('setIsLoading', false)
            // console.log(error)
          })
      }
    }, 
    
    massClosePreventInput(event) {
      event.srcElement.value = null
    },
  },
  
  beforeMount() {

    this.get7weeks()
    
  },

  mounted () {
  
    document.title = 'CS Note'
    try {
      localStorage.getItem('userGroups').split(',').includes('1') ? this.$store.commit('setIsPlaning', true) : this.$store.commit('setIsPlaning', false)
      localStorage.getItem('userGroups').split(',').includes('1') ? (this.localIsPlanning=true) : (this.localIsPlanning=false)
    } catch (error) {
      1 == 1
    }


    
  },

  computed: {
    striked : function () {
      return this.strike
    },
    isPlaningCS : function(){ 
        return this.$store.getters.isPlaning
      }
      , 
    searchTrigger () {
      if (this.search.length >= 3) {
        return this.search
      }
    },
  },
  
  
  updated () {
    // console.log('updated')
    // this.$store.commit('setActiveURL', window.location.pathname)
    try {
      if (this.firstClick == false) {
        this.$refs['7weeksBtn'][2].click()
        this.firstClick = true
      }
    } catch (error) {
      void(0)
      // console.log(error)
    }


  },
}

</script>

<style scoped>


tr:nth-child(even) {
  background-color: #f0f0f0;
}





.fa-external-link{
  color: hsl(42, 6%, 44%) !important;
}

.completed-line.strike{
  color: rgb(126, 126, 126);
  text-decoration: line-through;
}

.table-full-note-list-height {
  height: 80vh;

}


@media (-webkit-max-device-pixel-ratio: 1.15) {

}

</style>


<style>

</style>